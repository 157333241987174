
<div class="container">
  <hr>
  <p class="h1">Coupons</p>
  <hr>
  <ul class="list-group">
    <li  *ngFor="let website of websites"  class="list-group-item d-flex justify-content-between align-items-center">
      <a routerLink="/coupons/{{website._id}}">{{website.title}}</a>
      <button type="button" class="btn btn-primary " (click)="updateCoupons(website._id)">
        Update coupons
      </button>
    </li>
  </ul>
  <hr>
  <h1>{{webId}}</h1>
  <ul class="list-group">
    <li *ngFor="let coupon of coupons" class="list-group-item d-flex justify-content-between align-items-center">
       <h2>{{coupon.code}}</h2>({{coupon.type}} - {{coupon.amount}})
       <p>{{coupon.description}}</p>
    </li>
  </ul>
</div>
<p-confirmDialog key="general"></p-confirmDialog>
