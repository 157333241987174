import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ConfirmationService, FilterMetadata, FilterService, MessageService, SelectItem} from 'primeng/api';
import {getBrandSelectItems, getBrandBackgroundColour, getBrandColour} from '../lookups/brands';
import {PostSheet} from '../models/postSheet.model';
import {PostSheetService} from './post-sheet.service';
import {PrintMailingLabelsService} from './print-mailing-labels.service';
import moment from 'moment-timezone';
import {ExcelExportService} from '../post-order/excel-export.service';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {Lead, LeadCallNote} from '../models/lead.model';
import {LeadsService} from '../leads/leads.service';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {Column} from '../models/column.model';
import {LocksSocketService} from '../sockets/locks-socket.service';
import {Table} from 'primeng/table';
import {nameMatchModeOptions, tdCodeMatchModeOptions} from '../lookups/filterOptions';
import {dateRangeFilters} from '../helpers/tableDateRangeFilters';
import {getSendReasonOptions, getWhatToSendOptions, SendReason, WhatToSend} from '../lookups/postSheet';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import {OrderLockData} from '../models/socket-io/orderLockData.model';

const MAX_PARALLEL: number = 5;

@Component({
  selector: 'app-post-sheets[sendPostSheet]',
  templateUrl: './post-sheets.component.html',
  styleUrls: ['./post-sheets.component.scss'],
  providers: [MessageService, PrintMailingLabelsService, ConfirmationService],
})
export class PostSheetsComponent implements OnInit, OnDestroy {
  @ViewChild('dt', {static: true}) private table: Table;
  todayDate: Date = moment.tz('Europe/London').startOf('day').toDate();

  constructor(
    private messageService: MessageService,
    private postSheetService: PostSheetService,
    public printMailingLabelsService: PrintMailingLabelsService,
    private title: Title,
    private excelService: ExcelExportService,
    private confirmationService: ConfirmationService,
    private leadsService: LeadsService,
    private filterService: FilterService,
    private locksSocket: LocksSocketService,
  ) { }

  // @Output() sendPostSheet: EventEmitter<PostSheet|string|undefined> = new EventEmitter<PostSheet|string|undefined>();
  canAddPostSheet: boolean = false;
  loading: boolean = false;
  cols: Column[] = [];
  postSheets: PostSheet[] = [];
  selectedSheets: PostSheet[] = [];
  postSheetOpenMode = null;
  postSheetDetail: PostSheet;
  getBrandBackgroundColour = getBrandBackgroundColour;
  getBrandColour = getBrandColour;
  selectedClassLabelOption;
  dataLoadOptions = [
    { label: 'Unposted', value: 'unposted' },
    { label: 'Posted', value: 'posted' },
  ];
  selectedDataLoadOption;
  maxDateStr: string;
  defaultStartDateStr: string;
  displayDateEntryModal: boolean = false;
  fromDate: string;
  toDate: string;
  dateLoadOptionsVisible: boolean = false;
  orderLockList: OrderLockData[];
  tdCodeMatchModeOptions: SelectItem<string>[] = tdCodeMatchModeOptions;
  nameMatchModeOptions: SelectItem<string>[] = nameMatchModeOptions;
  brands: SelectItem<String>[];
  dateAddedFilters: Date[];
  postedDatesVisible: boolean = false;
  searchableFields: string[] = [];
  whatToSendOptions: WhatToSend[] = getWhatToSendOptions();
  sendReasonOptions: SendReason[] = getSendReasonOptions();
  displayModal:boolean = true;
  selectedPostSheetColumns: Column[];
  postSheetdefaultColumns: Column[];
  filterYearRange: string;

  ngOnInit(): void {
    this.initializeVariables();
    this.title.setTitle('CRM Post');
    this.defaultStartDateStr = moment.tz('Europe/London').subtract(1, 'days').format('YYYY-MM-DD');
    this.maxDateStr = moment.tz('Europe/London').format('YYYY-MM-DD');
    this.locksSocket.on('lockList', (data: OrderLockData[]) => {
      this.orderLockList = data;
    });
    this.locksSocket.emit('getLocked');
    this.brands = getBrandSelectItems();

    this.postSheetdefaultColumns = JSON.parse(localStorage.getItem('postSheetdefaultColumns'));
    if (!this.postSheetdefaultColumns) {
      this.selectedPostSheetColumns = this.cols.filter(col => col.hide != true);
    } else {
      this.selectedPostSheetColumns = this.postSheetdefaultColumns;
    }
  }

  ngOnDestroy(): void {
    this.locksSocket.removeAllListeners();
  }

  initializeVariables() {
    this.filterYearRange = `2014:${moment.tz('Europe/London').add(1, 'year').get('year')}`;
    this.cols = [
      { field: 'addedBy', header: 'Added By' , filterMatchMode: 'contains', hide: false},
      { field: 'dateAdded', header: 'Date Added', filterMatchMode: 'equals', hide: false},
      { field: 'orderId.alarmUserDetails.tdCode', header: 'Td Code', filterMatchMode: 'equals', hide: false},
      { field: 'websiteId', header: 'Brand', hide: false},
      { field: 'postSheetType', header: 'Post Sheet Type', filterMatchMode: 'equals', hide: false},
      { field: 'name', header: 'Customer Name', filterMatchMode: 'contains', hide: false},
      { field: 'address', header: 'Address', filterMatchMode: 'contains', hide: false },
      { field: 'whatToSend', header: 'What is being sent', filterMatchMode: 'equals', hide: false},
      { field: 'reason', header: 'Why its being sent', filterMatchMode: 'equals', hide: false},
      { field: 'comments', header: 'Comments', filterMatchMode: 'contains', hide: true},
      { field: 'paidAndMethod', header: 'Paid/Vat or not/Payment Method', filterMatchMode: 'contains', hide: true},
      { field: 'paidAmount', header: 'Amount Paid', filterMatchMode: 'contains', hide: true},
      { field: 'class', header: 'Class', filterMatchMode: 'equals', hide: false},
      { field: 'postedBy', header: 'Posted By', filterMatchMode: 'equals', hide: false},
    ];
    this.searchableFields = this.cols.map(e => e.field);
    let self = this;
    this.filterService.register('dateAdded', (value: any, filter: any): boolean => {
      return dateRangeFilters(value, filter, self.dateAddedFilters);
    });
  }

  getPostedSheets(startDate: string, endDate: string) {
    this.loading = true;
    this.postSheetService.getPostedSheets(startDate, endDate).subscribe(response => {
      console.log("Response on getPostSheets :: ", response);
      this.loading = false;
      if (response.success)
        this.postSheets = response.data;
    }, (err: Error) => {
      this.loading = false;
      this.showErrorPopUp('Error', 'Something went wrong, Reload the page')
      console.error("Error on getPostSheets :: ", err);
    })
  }

  getUnpostedSheets() {
    this.loading = true;
    this.postSheetService.getUnpostedSheets().subscribe(response => {
      console.log("Response on getPostSheets :: ", response);
      this.loading = false;
      if (response.success)
        this.postSheets = response.data;
    }, (err: Error) => {
      this.loading = false;
      this.showErrorPopUp('Error', 'Something went wrong, Reload the page')
      console.error("Error on getPostSheets :: ", err);
    })
  }


  loadData(startDate?: string, endDate?: string) {
    if (this.selectedDataLoadOption == 'posted') {
      this.getPostedSheets(startDate, endDate);
    } else {
      this.getUnpostedSheets();
    }
  }

  deletePostSheet(rowData) {
    const params = {
      _id: rowData._id,
    }
    console.log("rowData :: ", rowData);
    if (rowData.postedOn) {
      params['postedOn'] = rowData.postedOn;
    }
    this.postSheetService.deletePostSheet(params).subscribe(response => {
      console.log("Response on delete post-sheet :: ", response);
      this.postSheets = this.postSheets.filter((postSheet: PostSheet) =>
        postSheet._id != rowData._id
      );
      this.showSuccess('Operation Success!');
    }, (err: Error) => {
      this.showErrorPopUp('Error', err.message);
    })
  }

  exportTrackedPostSheet(option) {
    console.log("Clicked data :: ", option);
    this.postSheetService.findPostedToday({
      'class': 'Tracked'
    }).subscribe((response: MultiRecordResponse<PostSheet>) => {
      if (!response.success) {
        this.confirmationService.confirm({
          message: 'Error retrieving the tracked items posted today',
          header: 'Error retrieving records',
          icon: 'pi pi-exclamation-triangle',
          rejectVisible: false,
          acceptLabel: 'OK',
          accept: () => {}
        });
      } else if (response.data.length == 0) {
        this.confirmationService.confirm({
          message: 'No tracked post items for today found',
          header: 'No records found',
          icon: 'pi pi-exclamation-triangle',
          rejectVisible: false,
          acceptLabel: 'OK',
          accept: () => {}
        });
      } else {
        const clickAndDropData: any[] = response.data.map((postSheet: PostSheet) => ({
          'Name': postSheet.name,
          'Address Line One': postSheet.addressLineOne,
          'Address Line Two': postSheet.addressLineTwo,
          'City': postSheet.city,
          'County': postSheet.county,
          'Post Code': postSheet.postcode,
          'What To Send': postSheet.whatToSend || ''
        }));
        this.excelService.exportAsExcelFile({ 'PostSheet': clickAndDropData }, 'PostSheet');
      }
    })
  }

  exportSelectedPostSheet(option) {
    console.log("Clicked data :: ", option);
      if (this.selectedSheets.length == 0) {
        this.confirmationService.confirm({
          message: 'Error no records were selected for export.',
          header: 'Error exporting records',
          icon: 'pi pi-exclamation-triangle',
          rejectVisible: false,
          acceptLabel: 'OK',
          accept: () => {}
      });
    } else {
      this.excelService.exportAsExcelFile({ 'PostSheet': this.selectedSheets }, 'PostSheet');
    };
  }

  promptForAccountingDates() {
    this.displayDateEntryModal = true;
  }

  closeDateEntryModal() {
    this.displayDateEntryModal = false;
  }

  exportAccountingPostSheet() {
    this.displayDateEntryModal = false;
    this.postSheetService.findAccountingSheets(this.fromDate, this.toDate).subscribe((response: MultiRecordResponse<PostSheet>) => {
        if (!response.success) {
          this.confirmationService.confirm({
            message: 'Error retrieving the tracked items posted today',
            header: 'Error retrieving records',
            icon: 'pi pi-exclamation-triangle',
            rejectVisible: false,
            acceptLabel: 'OK',
            accept: () => {}
          });
         } else {
          const accountingSheets: any[] = response.data.map((postSheet: PostSheet) => {
            let netAmount: string = '';
            if (postSheet.paidAmount) {
              const amountMatches: RegExpMatchArray = postSheet.paidAmount.match(/\d+(\.\d+)?/)
              if (!!amountMatches && (amountMatches.length > 0)) {
                netAmount = amountMatches[0];
              }
            } else {
              const amountMatches: RegExpMatchArray = postSheet.paidAndMethod.match(/\d+(\.\d+)?/);
              if (!!amountMatches && (amountMatches.length > 0)) {
                netAmount = amountMatches[0];
              }
            }
            const sapFormatDate: string = moment.tz(postSheet.postedOn, 'Europe/London').format('YYYYMMDD');
            //TODO fix fact postsheet doesn't have correct address fields for getFormattedAddress call
            const formattedAddress: string = ''; // getFormattedAddress(postSheet);

            return {
              'Brand': postSheet.websiteId? postSheet.websiteId.accountingCode: '',
              'Posted Date': moment.tz(postSheet.postedOn, 'Europe/London').format('DD/MM/YYYY HH:mm:ss'),
              'Name & Address': `${postSheet.name}\n${formattedAddress}`,
              'What To Send': postSheet.whatToSend,
              'Why it\'s being sent': postSheet.reason,
              'GL Code': '',
              'Comments': postSheet.comments || '',
              'Td Code': postSheet.orderId? postSheet.orderId.alarmUserDetails.tdCode: '',
              'Paid / VAT or not / Payment method': postSheet.paidAndMethod || `${postSheet.paidAmount}/${postSheet.vatOrNot}/${postSheet.paymentMethod}`,
              // 'Amount Paid': postSheet.paidAmount ,
              // 'VAT or Not': postSheet.vatOrNot ,
              // 'Payment Method': postSheet.paymentMethod,
              'Net amount': netAmount,
              'VAT amount': '',
              'VAT Code': '',
              'Plan': postSheet.orderId? postSheet.orderId.accountDetails.plan: '',
              'Date': sapFormatDate,
              'end of month date': sapFormatDate,
              'remarks': '',
            };
          })
          this.excelService.exportAsExcelFile({ 'PostSheet': accountingSheets }, 'PostSheet');
        };
      });

    }

  openPostSheetDetail(data, openMode) {
    this.postSheetOpenMode = openMode;
    this.postSheetDetail = data;
    this.canAddPostSheet = true;
  }

  closeAddPostSheet(event: PostSheet|string|undefined) {
    if (event) {
      if (typeof event == 'string') {
        this.showErrorPopUp('Error',  `Something went wrong, try again. Error: ${event}`);
      } else {
        if (this.postSheetOpenMode == 'add') {
          this.postSheets = this.postSheets.concat([event]);
        } else if (this.postSheetOpenMode == 'update') {
          const tmpPostSheets: PostSheet[] = [];
          this.postSheets.forEach((postSheet: PostSheet) => {
            if (postSheet._id != event._id) {
              tmpPostSheets.push(postSheet);
            } else if ((this.selectedDataLoadOption == 'posted') || !event.postedBy) {
              tmpPostSheets.push(event);
            }
          });
          this.postSheets = tmpPostSheets;
          this.loadData()
        }
        this.showSuccess(
          'Operation success!'
        );
      }
    }
    this.postSheetOpenMode = null;
    this.postSheetDetail = null;
    this.canAddPostSheet = false;
  }

  showSuccess(detail:string) {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail,
      life: 1000
    });
  }

  printSelectedSheets() {
    this.printMailingLabelsService.printLabels(this.selectedSheets);
  }

  printClassSheets(postageClass: string, displayName: string) {
    console.log("Clicked data :: ", postageClass);
    this.postSheetService.findPostedToday({
      'class': postageClass
    }).subscribe((response: MultiRecordResponse<PostSheet>) => {
      if (!response.success) {
        this.confirmationService.confirm({
          message: 'Error retrieving ' + displayName + ' for today',
          header: 'Error retrieving records',
          icon: 'pi pi-exclamation-triangle',
          rejectVisible: false,
          acceptLabel: 'OK',
          accept: () => {}
        });
      } else if (response.data.length == 0) {
        this.confirmationService.confirm({
          message: 'No ' + displayName + ' found for today',
          header: 'No records found',
          icon: 'pi pi-exclamation-triangle',
          rejectVisible: false,
          acceptLabel: 'OK',
          accept: () => {}
        });
      } else {
        this.printMailingLabelsService.printLabels(response.data);
      }
    }
  )};

  createLeadsFromPost() {
    const leads: Lead[] = this.selectedSheets.map((postSheet: PostSheet) => {
      const alarmUserDetails = {
        'firstName': '',
        'otherName': '',
        'lastName': '',
        'preferredName': '',
        'email': postSheet.email,
        'emailMarketing': '',
        'telephone': '',
        'mobile': '',
        'salesContact': '',
        'userAddress': {
          'addressOne': postSheet.addressLineOne,
          'addressTwo': postSheet.addressLineTwo,
          'city': postSheet.city,
          'county': postSheet.county,
          'postcode': postSheet.postcode,
          'dob': '',
          'validated': false,
        },
      };
      let noteContent: string = '';
      if (postSheet.name.trim()) {
        const names: string[] = postSheet.name.trim()?.split(' ');
        alarmUserDetails['lastName'] = names.pop();
        alarmUserDetails['firstName'] = names.join(' ');
      }
      if (postSheet.phone.startsWith('07')) {
        alarmUserDetails['mobile'] = postSheet.phone;
      } else if (postSheet.phone.startsWith('7')) {
        alarmUserDetails['mobile'] = '0' + postSheet.phone;
      } else {
        alarmUserDetails['telephone'] = postSheet.phone;
      }
      if (postSheet.orderedByOrderedFor) {
        noteContent = `Ordered by/for: ${postSheet.orderedByOrderedFor}`;
      }
      if (postSheet.comments) {
        noteContent = `${noteContent} \nComments: ${postSheet.comments}`;
      }

      const notes: LeadCallNote[] = [];
      if (noteContent) {
        notes.push({
          createdBy: 'Postsheet Import',
          content: noteContent,
          createdAt: moment.tz('Europe/London').toISOString()
        });
      }
      return {
        'leadType': 'Info Pack',
        'whoReferred': '',
        'brand': postSheet.websiteId.title,
        'referralDate': new Date().toISOString(),
        'alarmUserDetails': alarmUserDetails,
        'status': 'Active',
        'callNotes': notes,
        'callDate': moment.tz('09:00', 'HH:mm', 'Europe/London').add(1, 'week').toISOString(),
        'callDateShift': 'Morning',
        'ownerName': postSheet.addedBy,
        'addedBy': localStorage.getItem('userName'),
        'paymentRef': '',
      }
    });

    this.leadsService.importLeads({leads: leads}).subscribe((response: SimpleResponse) => {
      if (response.success) {
        this.showSuccess('Leads created successfully');
      } else {
        this.confirmationService.confirm({
          message: `Error creating leads. Error: ${response.message}`,
          header: 'Failed to create leads',
          icon: 'pi pi-exclamation-triangle',
          rejectVisible: false,
          acceptLabel: 'OK',
          accept: () => {}
        });
      }
    }, (err: Error) => {
      this.confirmationService.confirm({
        message: `Error creating leads. Error: ${err.message}`,
        header: 'Failed to create leads',
        icon: 'pi pi-exclamation-triangle',
        rejectVisible: false,
        acceptLabel: 'OK',
        accept: () => {}
      });
      console.error("Error creating leads: ", err);
    });
  }

  isOrderLocked(_id: string) {
    return this.orderLockList.filter(lockedOrder => lockedOrder.orderId == _id).length > 0;
  }

  orderLockedBy(_id: string) {
    return this.orderLockList.filter(lockedOrder => lockedOrder.orderId == _id)[0].user;
  }

  applyFilter($event: Event, field: string, filterType: string): void {
    this.table.filter(($event.target as HTMLInputElement).value, field, filterType);
  }

  getFilterValue(field: string): string {
    if (!this.table.filters[field]) {
      return '';
    }
    if (this.table.filters[field] instanceof Array) {
      const filterMetadataArray: FilterMetadata[] = (this.table.filters[field] as FilterMetadata[]);
      if (filterMetadataArray.length > 0){
        return filterMetadataArray[0].value;
      }
      return '';
    }
    return (this.table.filters[field] as FilterMetadata).value;
  }
  clearTestDateFilters() {
    delete this.table.filters['dateTested'];
    let filters: any = { ...this.table.filters };
    this.table.reset();
    for (let key in filters) {
      this.table.filter(filters[key].value,key,filters[key].matchMode)
    }
  }
  displayPostedDates() {
    if (this.selectedDataLoadOption == 'posted') {
      this.postedDatesVisible = true
   } else if (this.selectedDataLoadOption == 'unposted') {
      this.postedDatesVisible = false
    }
  }

  private delay(ms: number): Promise<void> {
    return new Promise((resolve): void => {
      setTimeout(resolve, ms);
    });
  }

  postUnpostedSheet(postsheet: PostSheet) {
    if (!postsheet['postedOn']) {
      postsheet['postedOn'] = moment.tz('Europe/London').toDate();
    }
    if (!postsheet['postedBy']) {
      postsheet['postedBy'] = localStorage.getItem('userName');
    }
    this.postSheetService.postPostSheet(postsheet).subscribe((response: SingleRecordResponse<PostSheet>) => {
      if (!response.success) {
        this.confirmationService.confirm({
          message: `Error posting sheet. Error: ${response.message}`,
          header: 'Failed to post sheet',
          icon: 'pi pi-exclamation-triangle',
          rejectVisible: false,
          acceptLabel: 'OK',
          accept: () => {}
        });
      } else {
        this.postSheets = this.postSheets.filter((value: PostSheet) =>
          value._id != postsheet._id,
        );
        this.showSuccess('Successfully posted a post sheet.')
      }
    }, (error: any) => {
      this.confirmationService.confirm({
        message: `Error posting sheet. Error: ${error.message}`,
        header: 'Failed to post sheet',
        icon: 'pi pi-exclamation-triangle',
        rejectVisible: false,
        acceptLabel: 'OK',
        accept: () => {}
      });
      console.error("Error posting sheet: ", error);
    });
  }

  unpostPostedSheet(postsheet: PostSheet) {
    this.postSheetService.unpostPostSheet(postsheet).subscribe((response: SingleRecordResponse<PostSheet>) => {
      if (!response.success) {
        this.confirmationService.confirm({
          message: `Error unposting sheet. Error: ${response.message}`,
          header: 'Failed to unpost sheet',
          icon: 'pi pi-exclamation-triangle',
          rejectVisible: false,
          acceptLabel: 'OK',
          accept: () => {}
        });
      } else {
        this.postSheets = this.postSheets.filter((value: PostSheet) =>
          value._id != postsheet._id,
        );
        this.showSuccess('Successfully unposted post sheet.')
      }
    }, (error: any) => {
      this.confirmationService.confirm({
        message: `Error unposting sheet. Error: ${error.message}`,
        header: 'Failed to unpost sheet',
        icon: 'pi pi-exclamation-triangle',
        rejectVisible: false,
        acceptLabel: 'OK',
        accept: () => {}
      });
      console.error("Error unposting sheet: ", error);
    });
  }

  onPostSheetColumnChange(event, _dt) {
    const isTdCodeSelected: boolean = event.value.some((col: Column) => col.field == 'orderId.alarmUserDetails.tdCode');
    const isNameSelected: boolean = event.value.some((col: Column) => col.field == 'name');

    // Have to push the current column definition, else it doesn't work correctly
    if (!isTdCodeSelected) {
      this.selectedPostSheetColumns.push(this.cols.find((defaultCfg: Column) => defaultCfg.field == 'orderId.alarmUserDetails.tdCode'));
    }
    if (!isNameSelected) {
      this.selectedPostSheetColumns.push(this.cols.find((defaultCfg: Column) => defaultCfg.field == 'name'));
    }
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp(header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
