import {Injectable} from '@angular/core';
import {GenericSocketService} from './generic-socket.service';
import {environment} from '../../environments/environment';
import {ConfirmationCallback} from '../models/socket-io/confirmationCallback';

interface NotificationServToCliEvs {
  'announcement': (announcement: string) => void;
  'updateNotificationCount': (userIds: string[]) => void;
}

interface NotificationCliToServEvs {
  'broadcastUpdateNotificationCount': (userIds: string[], fn?: ConfirmationCallback) => void;
  'getAnnouncement': () => void;
}

@Injectable()
export class NotificationsSocketService extends GenericSocketService<NotificationServToCliEvs, NotificationCliToServEvs> {
  constructor() {
    super({
      'url': environment.protocol + environment.IPAddress + '/notifications', 
      'options': {
        'closeOnBeforeunload': false,
        'transports': ['websocket'],
        'path': '/socket.io/updates/',
      }
    });
  }
}