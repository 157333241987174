import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {WebsiteService} from "../setup/websites/website.service";
import {SelectItem, ConfirmationService} from 'primeng/api';
import {ProductsService} from "../setup/products/products.service";
import {OrderService} from "../post-order/order.service";
import {Router} from "@angular/router";
import {CouponsService} from "../setup/coupons/coupons.service";
import {GravityformsService} from "./gravityforms.service";
import moment from 'moment-timezone';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {FindOrderResponse} from '../models/responses/findOrderResponse.model';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {RawProduct} from '../models/product.model';
import {OrderResponse} from '../models/responses/orderResponse.model';
import {vatStatuses} from '../lookups/vatStatuses';
import {isValidEmailAddress} from '../validators/email.validator';
import {loadStripe, PaymentIntent, PaymentMethodResult, Stripe, StripeCardElement, StripeCardElementChangeEvent, StripeElements, StripeError} from '@stripe/stripe-js';
import {WebsitesResponse} from '../models/responses/websitesResponse.model';
import {Website} from '../models/website.model';
import {RawVariation, VariationAttribute} from '../models/variation.model';
import {environment} from '../../environments/environment';
import {GravityFormResponse} from '../models/responses/gravityFormResponse.model';
import {Title} from '@angular/platform-browser';
import {Address, getFormattedAddress} from '../helpers/getUserAddress';
import {numberOnly} from '../helpers/keyboardHelpers';
import {Order, OrderTag, Vim} from '../models/order.model';
import getAddressClient, {FindFailed, FindSuccess, Result} from 'getaddress-api';
import {getLookupFromGetAddressResult, validateAddress} from '../helpers/getAddressHelper';
import {getHowHeardOptions} from '../lookups/howHeard';
import {BusinessGrowthCustomer} from '../models/businessGrowthCustomer';
import {BusinessGrowthService} from '../business-growth-customers/business-growth.service';
import {Coupon} from '../models/coupon.model';
import {DropDownChangeEvent} from '../models/primeng/dropdownChangeEvent.model';
import {isValidAnyCountryPhoneNumber, isValidAnyCountryMobile} from '../validators/phone-number.validator';
import {CseOrderNavigationData} from '../models/cseOrderNavigationData.model';
import { LocksSocketService } from '../sockets/locks-socket.service';
import { LeadLockData } from '../models/socket-io/leadLockData.model';
import { LeadsService } from '../leads/leads.service';
import {marketingOptInSelects} from '../lookups/marketingOptIn';
import {OrderType, getCseOrderTypes, CalculationMethod} from '../lookups/cseOrderTypes';
import {HardwareService} from '../setup/hardware.service';
import {HardwareSet} from '../models/hardwareSet.model';
import {Hardware} from '../models/hardware.model';
import {doPlanCodeAndTypeMatch} from '../helpers/helperFunctions';
import {PRICE_REGEX, getPriceForOrderDate, getRenewalDateDetails} from '../helpers/getPlanCodeAndPrice';
import {FutureRenewalDetails, RenewalDateDetails} from '../models/responses/functionReturns/renewalDateDetails.model';
import {NotificationService} from '../notifications/notification.service';
import {getCseOrderPageFaults} from '../lookups/faultyEquipment';
import {HardwareId} from '../models/mongoose-populated-ids/hardwareId.model';
import {CseOrder} from '../models/reporting/cseOrder.model';
import {StringIndexedObject} from '../models/utility/stringIndexedObject.model';
import {UsersService} from '../setup/users/users.service';
// import {AddNoteViaQueueRequest} from '../models/requests/addNoteViaQueueRequest.model';
// import {ProposedMessageService} from '../messages-list/proposed-message.service';

interface WooItem {
  'product_id': number;
  'quantity': number; 
  'subtotal': number;
  'total': number;
}

interface ItemForCouponCalulations {
  'product_id': number;
  'variation_id': number;
  'subtotalExVat': number;
  'subtotalVat': number;
  'totalExVat': number;
  'totalVat': number;
  'rentalItem': boolean;
}

interface ProcessingSteps {
  [stepName: string]: {
    'required': boolean;
    'completed': boolean;
    'messageToDisplay'?: string;
    // Below is only for steps that we sometimes want to allow skipping and not at other times
    'allowToBeSkipped'?: boolean;
    'messageToDisplayIfCanSkip'?: string;
    // Below is for data we need for another step
    'resultData'?: string;
  }
}

type VariationWithTitle =  RawVariation & {'title'?: string};

interface BasketItem extends RawProduct {
  'quantity'?: number;
  'original_price'?: number;
  'customerHeight'?: string;
  'wearingOption'?: string;
  'priceCalculated': boolean;
  'selectedVariation'?: {
    '_id': number;
    'title': string;
    'sku': string;
    'symbol': string;
    'regular_price': number;
    'original_price': number;
    'vat': string;
    'renewalAfterDate'?: number;
    'hardware': string[];
    'hardwareSets': string[];
  };
}

interface FullContactDetails {
  'email'?: string;
  'mobile'?: string;
  'firstName'?: string;
  'lastName'?: string;
  'userAddress'?: Address;
}

interface SetupFeeConfig {
  'normalFeeSkus': string[],
  'reducedFeeSkus': string[],
  'skusRequiringReducedFee': string[],
}

const SETUP_FEE_CONFIGS: {[websiteId: string]: SetupFeeConfig} = {
  '5ce1e1043c52bd1e7fe45a77': {
    'normalFeeSkus': ['LL-SETUP-FEE-INC', 'LL-SETUP-FEE-EX'],
    'reducedFeeSkus': ['SETUP-FEE-VAT-INC-ANALOGUE', 'SETUP-FEE-VAT-EX-ANALOGUE'],
    // TODO take these out when Trojan horse needs deactivating
    'skusRequiringReducedFee': ['LL-TUN-57000/320-Q-EX', 'LL-TUN-57000/320-Q-INC'],
    // 'skusRequiringReducedFee': [],
  },
  '5cd454426c0e9821baca0c1d': {
    'normalFeeSkus': ['SETUP-FEE-VAT-INC', 'SETUP-FEE-VAT-EX'],
    'reducedFeeSkus': ['SETUP-FEE-VAT-INC-ANALOGUE', 'SETUP-FEE-VAT-EX-ANALOGUE'],
    // TODO take these out when Trojan horse needs deactivating
    'skusRequiringReducedFee': ['CL-CMP-VAT-INC', 'CL-CMP-VAT-EXC', 'CL-FD-M-VAT-EXC', 'CL-FD-M-VAT-INC'],
    // 'skusRequiringReducedFee': [],
  },
};

enum OrderVatStatus {
  NOT_SET,
  VATABLE,
  VAT_EXEMPT,
}

enum FeeRequired {
  NO_FEE,
  NORMAL_FEE,
  REDUCED_FEE,
}

const SORT_REGEX: RegExp = /^\d{6}$/;
const BANK_ACCOUNT_REGEX: RegExp = /^\d{8}$/;

@Component({
  selector: 'app-cse-order',
  templateUrl: './cse-order.component.html',
  styleUrls: ['./cse-order.component.scss'],
  providers: [ConfirmationService]
})
export class CseOrderComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('cardInfoLL') cardInfoLL: ElementRef;
  @ViewChild('cardInfoCL') cardInfoCL: ElementRef;
  @ViewChild('cardInfoTC') cardInfoTC: ElementRef;
  @ViewChild('cardInfoCK') cardInfoCK: ElementRef;
  @ViewChild('cardInfoLLIE') cardInfoLLIE: ElementRef;
  @ViewChild('cardInfoLLOld') cardInfoLLOld: ElementRef;
  @ViewChild('cardInfoCLOld') cardInfoCLOld: ElementRef;
  @ViewChild('cardInfoTCOld') cardInfoTCOld: ElementRef;
  @ViewChild('cardInfoCKOld') cardInfoCKOld: ElementRef;
  @ViewChild('cardInfoLLIEOld') cardInfoLLIEOld: ElementRef;
  setupFeeTitle: string = 'setup fee';
  vatOptions: SelectItem<string>[];
  vatSelected: string;
  orderVatStatus: OrderVatStatus;
  OrderVatStatusEnum = OrderVatStatus;
  orderRenewalAfter: number;
  websites: Website[];
  items: BasketItem[];
  websiteSelected: string;
  selectedProduct: RawProduct;
  selectedVariation: VariationWithTitle;
  Object = Object;
  numberOnly = numberOnly;

  products: RawProduct[];
  coupons: Coupon[];
  coupon: Coupon|undefined;
  couponCode: string;
  couponError: string;
  productsFiltered: RawProduct[];
  woodata: any;
  variations: VariationWithTitle[];
  vat: number;
  TTorder: number;
  totalBeforeDiscountAndOverride: number;
  couponDiscount: number;
  searchTDCode: string;
  tdCodeFound: boolean;
  tdCodeSearched: boolean;
  hasBulkTag: boolean;
  infoBilling: FullContactDetails;
  infoDeliver: FullContactDetails;
  orderDetails: any;
  directDebit: any;
  differentDeliver: boolean;
  private totalOverridden: boolean;
  overrideReason: string;
  otherOverrideReason: string;
  allowReplacementWithoutTd: boolean;
  cards: {[brand: string]: StripeCardElement};
  stripes: {[brand: string]: Stripe};
  cardHandler = this.onChange.bind(this);
  error: string;
  processingError: {'message'?: string, 'code'?: string};
  category: string;
  categories: SelectItem<string>[];
  notExemptCategories: SelectItem<string>[];
  exemptCategories: SelectItem<string>[];
  showPlaceOrder: boolean;
  showProgressBar: boolean;
  color: {[brandCode: string]: string};
  websitetitle: string;
  stripeAccount: string;
  private emailSuffix: string;
  private userName: string;
  howHeard: string;
  howHeardOptions: SelectItem<string>[];
  customerCalledFrom: string;
  withheldNumber: boolean;
  success: boolean;
  orderLink: string;
  crmOrderLink: string;
  private url: string;
  validationErrors: string[];
  currentStep: string;
  websiteOrderId: number;
  paymentIntentRef: string;
  processingSteps: ProcessingSteps = {
    'Validating Order': { 
      required: true, 
      completed: false,
    },
    'Creating Payment Method on Stripe': { 
      required: true,
      completed: false,
      messageToDisplay: 'Failed trying to create card record on Stripe to take payment against. Please check if Stripe is up and retry if/when it is.',
    },
    'Creating Payment Intent on Stripe': { 
      required: true,
      completed: false,
      messageToDisplay: 'Failed trying to take payment on Stripe. Please see the above Error detailing what is wrong, ' +
        'correct the card details and then click retry.',
      allowToBeSkipped: false,
      messageToDisplayIfCanSkip: 'Failed trying to take payment on Stripe. Please check on Stripe if the payment has ' +
        'been taken and if it has enter the payment reference from Stripe before retrying.',
    },
    'Capturing Details for Reporting': {
      required: true, 
      completed: false, 
      messageToDisplay: 'Failed trying to capture the order details for reporting. Please retry.'
    },
    'Creating Direct Debit Form': { 
      required: true,
      completed: false,
      messageToDisplay: 'Failed trying to create Direct Debit form on Alarm website. Please check if the "Direct Debit" ' +
        'form for this customer has been created on the Alarm website. If it has check the below box before retrying.',
    },
    'Creating Order on Alarm Website': { 
      required: true, 
      completed: false,
      messageToDisplay: 'Failed trying to create the order on the Alarm website. Please check if the order exists on the Alarm website. ' +
        'If it does and the status is "Pending" or "Pending payment" set it to "Processing". ' +
        'If the status is not "Pending", "Pending payment", "Processing" or "Completed" please contact CRM Support.' +
        'If it exists enter the order id below before retrying, otherwise if it does not exist just click Retry.',
    },
    'Update Status on Alarm Website': { 
      required: true, 
      completed: false,
      allowToBeSkipped: false,
      messageToDisplay: 'Failed trying to update the order to "Processing" status on the Alarm website. ' +
        'Please check the status on the Alarm website and if is "Pending" or "Pending payment" set it to "Processing" before retrying. ' + 
        'If the status is not "Pending", "Pending payment", "Processing" or "Completed" please contact CRM Support.',
      messageToDisplayIfCanSkip: 'Failed trying to update the order to "Processing" status on the Alarm website. ' +
        'Please check the status on the Alarm website and if is "Pending" or "Pending payment" set it to "Processing". ' + 
        'If the status is not "Pending", "Pending payment", "Processing" or "Completed" please contact CRM Support. ' +
        'No further action is needed once the order status is updated, so you can close or reload this page',
    },
    'Importing into CRM': { 
      required: true, 
      completed: false, 
      messageToDisplay: 'Failed trying to import the order into the CRM. If the order is a Key Safe or Replacements only order and CRM record is not needed, ' +
        'then no further action is required, so you can close or reload this page. ' +
        'If the order is needed in the CRM please check if the order exists in the CRM and if it does check the box below before retrying.' 
    },
    'Updating CRM order ref, processor and alarm user': { 
      required: true, 
      completed: false, 
      messageToDisplay: 'Failed trying to update the CRM order ref, processor and alarm. Please retry.' 
    },
    'Updating Order Details for Reporting': {
      required: true, 
      completed: false, 
      messageToDisplay: 'Failed trying to update information on the reporting record. Please retry.'
    },
    'Checking User Supplied Payment Reference': { 
      required: false,
      completed: false,
      allowToBeSkipped: true,
      messageToDisplay: 'Invalid Payment reference entered, please see the Error above for the reason and either correct the reference, ' +
        'or remove the reference to create a new payment on Stripe.' 
    },
    'Checking User Supplied OrderId': {
      required: false, 
      completed: false, 
      messageToDisplay: 'Invalid Order Id entered, please see the Error above for the reason and either correct the Order Id, or remove the ' +
        'Order Id to create a new order on the alarm Website.' 
    }
  };
  readonly ORDER_CREATION_STEP: string = 'Creating Order on Alarm Website';
  readonly ORDER_STATUS_UPDATE_STEP: string = 'Update Status on Alarm Website';
  displayErrorDetails: boolean;
  alarmUserAddressOption: string;
  alarmUserNameOption: string;
  alarmUserPhoneOption: string;
  alarmUserAddress: Address;
  currencySymbol: string;
  currencyCode: string;
  vimPopupOpen: boolean = false;
  getAddrClient: getAddressClient;
  billingSearchPostCode: string;
  deliverySearchPostCode: string;
  alarmUserSearchPostCode: string;
  allowBillingAddressManualEntry: boolean;
  allowDeliveryAddressManualEntry: boolean;
  allowAlarmUserAddressManualEntry: boolean;
  billingSearchError: string;
  deliverySearchError: string;
  alarmUserSearchError: string;
  billingAddressResults: SelectItem<Address>[];
  deliveryAddressResults: SelectItem<Address>[];
  alarmUserAddressResults: SelectItem<Address>[]; 
  partnershipByVoucherCode: {[code: string]: BusinessGrowthCustomer};
  partnerships: SelectItem<BusinessGrowthCustomer>[];
  selectedPartnership: BusinessGrowthCustomer;
  cseOrderNavigationData: CseOrderNavigationData;
  emailMarketing: string;
  phoneMarketing: string;
  marketingOptInSelects: SelectItem<string>[] = marketingOptInSelects;
  currentMoment: moment.Moment;
  todayAsString: string;
  orderTypes: SelectItem<OrderType>[] = [];
  selectedOrderType: OrderType;
  hardware: Hardware[] = [];
  hardwareSets: HardwareSet[] = [];
  order: Order;
  orderDateYMD: string;
  recentOrder: boolean;
  calculationError: string;
  lifetimeLineNeedsOverride: boolean;
  proRataMultiplier: number;
  proRataToDate: string;
  nextRenewalDate: string;
  crmRenewalDate: string;
  proRataCurrentPrice: number;
  hasRentalItems: boolean;
  faultOptions: SelectItem<string>[];
  fault: string;
  otherFault: string
  company: string;
  nameOnCard: string;

  onChange(event: StripeCardElementChangeEvent) {
    if (event.error) {
      this.error = event.error.message;
    } else {
      this.error = null;
    }
    this.changeDetector.detectChanges();
  }

  constructor(
    private websiteService: WebsiteService,
    private gravityformsService: GravityformsService,
    private productsService: ProductsService,
    private couponsService: CouponsService,

    private orderService: OrderService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private title: Title,
    private businessGrowthService: BusinessGrowthService,
    private locksSocket: LocksSocketService,
    private leadService: LeadsService,
    private hardwareService: HardwareService,
    private notificationService: NotificationService,
    private userService: UsersService,
    // private proposedMessageService: ProposedMessageService,
  ) {
    // This has to be done here as ngOnInit is too late, navigation has completed
    if (this.router.getCurrentNavigation().extras.state) {
       this.cseOrderNavigationData = (this.router.getCurrentNavigation().extras.state as CseOrderNavigationData);
    }
  }

  ngOnDestroy(): void {
    if (this.cseOrderNavigationData) {
      this.locksSocket.emit('unlockingLead', { 'leadId': this.cseOrderNavigationData.leadId, 'user': this.userName });
    }
  }

  ngOnInit() {
    this.orderTypes = getCseOrderTypes();
    this.fault = '';
    this.otherFault = '';
    this.selectedOrderType = undefined;
    this.partnerships = [];
    this.partnershipByVoucherCode = {};
    this.vatOptions = vatStatuses;
    this.orderVatStatus = OrderVatStatus.NOT_SET;
    this.orderRenewalAfter = 0;
    this.tdCodeFound = false;
    this.tdCodeSearched = false;
    this.success = false;
    this.displayErrorDetails = false;
    this.validationErrors = [];
    this.orderLink = '';
    this.crmOrderLink = '';
    this.websiteOrderId = undefined;
    this.paymentIntentRef = '';
    this.userName = localStorage.getItem('userName');
    this.howHeard = '';
    this.customerCalledFrom = '';
    this.withheldNumber = false;
    this.currentMoment = moment.tz('Europe/London');
    this.todayAsString = this.currentMoment.format('YYYY-MM-DD');
    this.color = {'CL': 'red',
      'LL': 'green',
      'CK': 'purple',
      'TC': 'blue',
      'LLIE': '#5f7f3c'};
    this.showPlaceOrder = true;
    this.howHeardOptions = getHowHeardOptions();
    this.notExemptCategories = [{
      'label': 'Quarterly Plan',
      'value': 'quarterlyPlan'
    },{
      'label': 'Annual Plan',
      'value': 'annualPlan'
    },{
      'label': 'Lifetime Plan',
      'value': 'lifetimePlan'
    },{
      'label': 'Key Safe',
      'value': 'keySafe'
    },{
      'label': 'Additional Equipment',
      'value': 'additionalEquipment'
    },{
      'label': 'Installations',
      'value': 'installations'
    }, {
      'label': 'Cables',
      'value': 'cables'
    }, {
      'label': 'Discontinued',
      'value': 'discontinued'
    }];
    this.faultOptions = getCseOrderPageFaults();
    this.exemptCategories = this.notExemptCategories.filter((selectItem: SelectItem<string>) => 
      (selectItem.value != 'keySafe')
    );
    this.businessGrowthService.getBusinessGrowthCustomers().subscribe((response: MultiRecordResponse<BusinessGrowthCustomer>) => {
      if (!response.success) {
        console.error(`Error getting Business Growth Customers. Error: ${response.message}`, response.error);  
      } else {
        response.data.forEach((bgCust: BusinessGrowthCustomer) => {
          this.partnerships.push({
            'label': bgCust.bgcName,
            'value': bgCust,
          });
          if (bgCust.bgcCouponCode) {
            this.partnershipByVoucherCode[bgCust.bgcCouponCode] = bgCust;
          }
        });
      }
    }, err => {
      console.error('Cautght error getting Business Growth Customers. Error: ', err);
    });
    this.differentDeliver = false;
    this.allowReplacementWithoutTd = false;
    this.directDebit = {};
    this.coupon = undefined;
    this.woodata = {
      generatedEmail: false,
      status: "pending",
      paid: true,
      payment_method: "bacs",
      note: '',
      payment_method_title: "Direct Bank Transfer",
      set_paid: true,
      billing_address: {
        first_name: '',
        last_name: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        postcode: '',
        country:'GB',
        email: '',
        phone: ''
      },
      shipping_address: {
        first_name: '',
        last_name: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        postcode: '',
        country:'GB',
      },
      fee_lines: [
      ],
      line_items: [
      ],
      coupon_lines: [
      ],
      shipping_lines: [{
        "method_id": "free_shipping:8",
        "method_title": "Next Day Delivery (FREE)",
        "total": "0"
      }]
    };
    this.infoBilling = {
      email: '',
      firstName: '',
      lastName: '',
      mobile: '',
      userAddress: {
        addressOne: '',
        addressTwo: '',
        city: '',
        county: '',
        postcode: '',
        validated: false,
      }
    };
    this.infoDeliver ={
      email: '',
      firstName: '',
      lastName: '',
      mobile: '',
      userAddress: {
        addressOne: '',
        addressTwo: '',
        city: '',
        county: '',
        postcode: '',
        validated: false,
      }
    };
    this.alarmUserAddress = {
      addressOne: '',
      addressTwo: '',
      city: '',
      county: '',
      postcode: '',
      validated: false,
    };
    this.orderDetails = {
      'NCFRequired': 'Yes',
    };
    this.products = [];
    this.clearFields(true);
    this.websiteService
      .getWebsites()
      .subscribe((websites: WebsitesResponse) => {
        this.websites = websites.websites;
        if (this.cseOrderNavigationData) {
          const brand: string = this.cseOrderNavigationData.brand;
          if (brand) {
            const website: Website = this.websites.find((website: Website) => website.title == brand);
            if (website) {
              this.selectBrand(website);
            }
          }
        }
      }, err => {
        console.error('Error getting website data', err);
      });
    this.title.setTitle('CRM Order');
    this.billingAddressResults = [];
    this.deliveryAddressResults = [];
    this.alarmUserAddressResults = [];
    this.allowBillingAddressManualEntry = false;
    this.allowDeliveryAddressManualEntry = false;
    this.allowAlarmUserAddressManualEntry = false;
    this.getAddrClient = new getAddressClient(environment.getAddressDomainToken);
    if (this.cseOrderNavigationData) {
      this.alarmUserNameOption = 'other';
      this.alarmUserPhoneOption = 'other';
      this.alarmUserAddressOption = 'other';
      this.orderDetails.alarmUserFirstName = this.cseOrderNavigationData.alarmUserDetails.firstName;
      this.orderDetails.alarmUserLastName = this.cseOrderNavigationData.alarmUserDetails.lastName;
      this.orderDetails.alarmUserPhone = this.cseOrderNavigationData.alarmUserDetails.telephone;
      this.orderDetails.alarmUserMobile = this.cseOrderNavigationData.alarmUserDetails.mobile;
      this.alarmUserAddress = {
        'addressOne': this.cseOrderNavigationData.alarmUserDetails.userAddress.addressOne,
        'addressTwo': this.cseOrderNavigationData.alarmUserDetails.userAddress.addressTwo,
        'city': this.cseOrderNavigationData.alarmUserDetails.userAddress.city,
        'county': this.cseOrderNavigationData.alarmUserDetails.userAddress.county,
        'postcode': this.cseOrderNavigationData.alarmUserDetails.userAddress.postcode,
        'validated': this.cseOrderNavigationData.alarmUserDetails.userAddress.validated,
      };
      this.infoBilling.firstName = this.cseOrderNavigationData.alarmUserDetails.firstName;
      this.infoBilling.lastName = this.cseOrderNavigationData.alarmUserDetails.lastName;
      this.infoBilling.email = this.cseOrderNavigationData.alarmUserDetails.email;
      if (this.cseOrderNavigationData.alarmUserDetails.mobile) {
        this.infoBilling.mobile = this.cseOrderNavigationData.alarmUserDetails.mobile;
      } else if (!this.cseOrderNavigationData.alarmUserDetails.mobile && this.cseOrderNavigationData.alarmUserDetails.telephone) {
        this.infoBilling.mobile = this.cseOrderNavigationData.alarmUserDetails.telephone;
      }
      this.infoBilling.userAddress = {
        'addressOne': this.cseOrderNavigationData.alarmUserDetails.userAddress.addressOne,
        'addressTwo': this.cseOrderNavigationData.alarmUserDetails.userAddress.addressTwo,
        'city': this.cseOrderNavigationData.alarmUserDetails.userAddress.city,
        'county': this.cseOrderNavigationData.alarmUserDetails.userAddress.county,
        'postcode': this.cseOrderNavigationData.alarmUserDetails.userAddress.postcode,
        'validated': this.cseOrderNavigationData.alarmUserDetails.userAddress.validated,
      };
      this.validateBillingAddress(true);
      this.orderDetails.type = 'Lead';
      this.selectedOrderType = this.orderTypes.find((orderType: SelectItem<OrderType>) => (orderType.value.title == 'Lead')).value;
      this.locksSocket.emit('lockingLead', {
        leadId: this.cseOrderNavigationData.leadId,
        user: this.userName
      }, (addedLead: LeadLockData) => {
        console.log(`lead locked ${addedLead.added}`)
      });
    }
    this.emailMarketing = '';
    this.phoneMarketing = '';
  }

  clearFields(clearOrderData: boolean) {
    this.vatSelected = null;
    this.category = null;
    this.categories = [];
    this.selectedProduct = null;
    this.productsFiltered = [];
    this.selectedVariation = null;
    this.variations = [];

    this.items = [];
    this.TTorder = 0;
    this.totalBeforeDiscountAndOverride = 0;
    this.vat = 0;
    this.couponCode = '';
    this.couponError = '';
    this.couponDiscount = 0;
    this.calculationError = '';

    this.totalOverridden = false;
    this.overrideReason = '';
    this.otherOverrideReason = '';

    this.selectedPartnership = undefined;
    this.orderDetails.renewalFrequency = '';
    this.orderVatStatus = OrderVatStatus.NOT_SET;
    if (clearOrderData) {
      this.hasBulkTag = false;
      this.order = undefined;
      this.proRataMultiplier = 0;
      this.proRataToDate = '';
      this.nextRenewalDate = '';
      this.crmRenewalDate = '';
      this.proRataCurrentPrice = 0;
    }
  }

  billingAddressSearch() {
    if (!this.billingSearchPostCode) {
      return;
    }
    this.billingAddressResults = [];
    this.allowBillingAddressManualEntry = false;
    this.billingSearchError = '';
    this.getAddrClient.find(this.billingSearchPostCode).then((addressResult: Result<FindSuccess,FindFailed>) => {
      this.billingAddressResults = getLookupFromGetAddressResult(this.billingSearchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.billingSearchError = addressResult.toFailed().message;
        console.error('Billing Address search failed. Error:', this.billingSearchError);
      } else if (this.billingAddressResults.length <= 2) {
        this.billingSearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Billing Address search failed. Error:', error);
    })
  }

  setBillingAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowBillingAddressManualEntry = true;
      if (!this.infoBilling.userAddress.addressOne && !this.infoBilling.userAddress.postcode) {
        this.infoBilling.userAddress.postcode = this.billingSearchPostCode;
      }
      this.infoBilling.userAddress.validated = false;
      return;
    }
    this.allowBillingAddressManualEntry = false;
    // Don't want to copy Role
    this.infoBilling.userAddress = {
      'addressOne': selectedAddress.addressOne,
      'addressTwo': selectedAddress.addressTwo,
      'city': selectedAddress.city,
      'county': selectedAddress.county,
      'postcode': selectedAddress.postcode,
      'validated': selectedAddress.validated,
    };
  }

  deliveryAddressSearch() {
    if (!this.deliverySearchPostCode) {
      return;
    }
    this.deliveryAddressResults = [];
    this.allowDeliveryAddressManualEntry = false;
    this.deliverySearchError = '';
    this.getAddrClient.find(this.deliverySearchPostCode).then((addressResult: Result<FindSuccess,FindFailed>) => {
      this.deliveryAddressResults = getLookupFromGetAddressResult(this.deliverySearchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.deliverySearchError = addressResult.toFailed().message;
        console.error('Delivery Address search failed. Error:', this.deliverySearchError);
      } else if (this.deliveryAddressResults.length <= 2) {
        this.deliverySearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Delivery Address search failed. Error:', error);
    })
  }

  setDeliveryAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowDeliveryAddressManualEntry = true;
      if (!this.infoDeliver.userAddress.addressOne && !this.infoDeliver.userAddress.postcode) {
        this.infoDeliver.userAddress.postcode = this.deliverySearchPostCode;
      }
      this.infoDeliver.userAddress.validated = false;
      return;
    }
    this.allowDeliveryAddressManualEntry = false;
    // Don't want to copy Role
    this.infoDeliver.userAddress = {
      'addressOne': selectedAddress.addressOne,
      'addressTwo': selectedAddress.addressTwo,
      'city': selectedAddress.city,
      'county': selectedAddress.county,
      'postcode': selectedAddress.postcode,
      'validated': selectedAddress.validated,
    };
  }

  alarmUserAddressSearch() {
    if (!this.alarmUserSearchPostCode) {
      return;
    }
    this.alarmUserAddressResults = [];
    this.allowAlarmUserAddressManualEntry = false;
    this.alarmUserSearchError = '';
    this.getAddrClient.find(this.alarmUserSearchPostCode).then((addressResult: Result<FindSuccess,FindFailed>) => {
      this.alarmUserAddressResults = getLookupFromGetAddressResult(this.alarmUserSearchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.alarmUserSearchError = addressResult.toFailed().message;
        console.error('Alarm User Address search failed. Error:', this.alarmUserSearchError);
      } else if (this.alarmUserAddressResults.length <= 2) {
        this.alarmUserSearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Alarm User Address search failed. Error:', error);
    })
  }

  setAlarmUserAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowAlarmUserAddressManualEntry = true;
      if (!this.alarmUserAddress.addressOne && !this.alarmUserAddress.postcode) {
        this.alarmUserAddress.postcode = this.alarmUserSearchPostCode;
      }
      this.alarmUserAddress.validated = false;
      return;
    }
    this.allowAlarmUserAddressManualEntry = false;
    // Don't want to copy Role
    this.alarmUserAddress = {
      'addressOne': selectedAddress.addressOne,
      'addressTwo': selectedAddress.addressTwo,
      'city': selectedAddress.city,
      'county': selectedAddress.county,
      'postcode': selectedAddress.postcode,
      'validated': selectedAddress.validated,
    };
  }

  selectBrand(website: Website) {
    // Don't allow changes if processing started
    if (!this.showPlaceOrder) {
      return;
    }
    this.websiteSelected = website._id;
    if (website.title == this.websitetitle) {
      // Already on the correct one, nothing to do
      return;
    }
    this.websitetitle = website.title;
    this.stripeAccount = website.title;
    this.url = website.api.url;
    this.emailSuffix = this.url.replace('www.','').replace('https://','');
    this.productsService
      .getProductsForCsesPage(this.websiteSelected)
      .subscribe((response: MultiRecordResponse<RawProduct>) => {
        // Filter out the replacement product as it should not be done this way anymore
        this.products = response['data'].filter((product: RawProduct) => 
          !(product.crmTitle.toLowerCase().includes('replacement') && (!product.regularPrice || (product.regularPrice == 0)))
        );
      });
    this.couponsService.getCouponsByWebsite(this.websiteSelected).subscribe(couponResponse => {
      this.coupons = couponResponse['coupons'];
    }, err => {
      console.error('Error getting coupon data', err);
    });
    this.hardwareService.getHardwareForSite(this.websiteSelected)
      .subscribe((response: MultiRecordResponse<Hardware>) => {
        if (response.success) {
          this.hardware = response.data;
        }
      });
    this.hardwareService.getHardwareSetsForSite(this.websiteSelected)
      .subscribe((response: MultiRecordResponse<HardwareSet>) => {
        if (response.success) {
          this.hardwareSets = response.data;
        }
      });
    this.clearFields(true);
    // Clear basket statuses
    this.orderVatStatus = OrderVatStatus.NOT_SET;
    this.orderRenewalAfter = 0;
    this.allowReplacementWithoutTd = false;
    this.howHeard = '';
    this.customerCalledFrom = '';
    // Clear existing order details/search
    this.tdCodeFound = false;
    this.searchTDCode = '';
    this.currencySymbol = '\u00A3';
    this.currencyCode = 'GBP';
    if (this.websitetitle == 'LLIE') {
      this.currencySymbol = '\u20AC';
      this.currencyCode = 'EUR';
    }
  }

  addToInvoice() {
    const selectedProd: BasketItem = Object.assign({'priceCalculated': false}, this.selectedProduct);
    selectedProd.quantity = 1;
    if (!!this.selectedVariation) {
      const variationPrice: number = parseFloat(this.selectedVariation.regular_price);
      selectedProd.selectedVariation = {
        '_id': this.selectedVariation._id,
        'title': this.selectedVariation.title,
        'sku': this.selectedVariation.sku,
        'symbol': this.selectedVariation.symbol,
        'regular_price': variationPrice,
        'original_price': variationPrice,
        'vat': this.selectedVariation.vat,
        'renewalAfterDate': this.selectedVariation.renewalAfterDate,
        'hardware': this.selectedVariation.hardware,
        'hardwareSets': this.selectedVariation.hardwareSets,
      };
    } else {
      selectedProd.selectedVariation = undefined;
    }
    selectedProd.original_price = selectedProd.regularPrice;
    this.variations = [];

    const pos: number = this.items.findIndex((item: BasketItem) => selectedProd._id == item._id);
    if (pos == -1) {
      this.items.push(selectedProd);
    } else {
      this.items[pos].quantity+=1;
    }

    this.selectedVariation = null;
    this.selectedProduct = null;

    this.priceupdate();
    this.updateFilters();
  }

  updateFeeIfRequired(setupFeeConfig: SetupFeeConfig, feeRequired: FeeRequired): boolean {
    this.setVat();
    let requiredFeeSku: string;
    let alreadyHasRequiredFee: boolean = false;
    let feesChanged: boolean = false;
    this.selectedProduct = null;
    if (feeRequired != FeeRequired.NO_FEE) {
      // orderVatStatus 0 is NOT_SET, so subtract one to get sku index
      requiredFeeSku = (feeRequired == FeeRequired.NORMAL_FEE)?
        setupFeeConfig.normalFeeSkus[this.orderVatStatus - 1]:
        setupFeeConfig.reducedFeeSkus[this.orderVatStatus - 1];
      if (!requiredFeeSku) {
        console.error('Setup Fee not found');
        // Call this to reapply the category filter else the product list contains items from other categories
        if (!!this.category) {
          this.setCategory();
        }
        this.calculationError = 'Setup Fee required, but setup fee SKU not found';
        return true;
      }
    }
    const feeSkusToRemove: string[] = setupFeeConfig.normalFeeSkus
      .concat(setupFeeConfig.reducedFeeSkus).filter((feeSku: string) => 
        requiredFeeSku != feeSku
      );
    // Remove incorrect fees first
    for (let i: number = this.items.length - 1; i > -1; i--) {
      const currentItemSku: string = this.items[i].sku;
      if (feeSkusToRemove.includes(currentItemSku)) {
        feesChanged = true;
        this.items.splice(i, 1);
      } else if (requiredFeeSku && (requiredFeeSku == currentItemSku)) {
        alreadyHasRequiredFee = true;
      }
    }
    if ((feeRequired != FeeRequired.NO_FEE) && !alreadyHasRequiredFee) {
      feesChanged = true;
      this.selectedProduct = this.productsFiltered.find((product: RawProduct) => product.sku == requiredFeeSku);
      if (!this.selectedProduct) {
        // Call this to reapply the category filter else the product list contains items from other categories
        if (!!this.category) {
          this.setCategory();
        }
        console.error('Setup Fee not found');
        this.calculationError = 'Setup Fee required, but setup fee product not found';
        return true;
      }
    }
    if (alreadyHasRequiredFee) {
      feesChanged = false;
    }
    if (feesChanged) {
      if (this.selectedProduct) {
        this.addToInvoice();
      } else {
        // Fees were removed, so need to recalculate price
        this.priceupdate();
        this.updateFilters();
      }
    }
    // Call this to reapply the category filter else the product list contains items from other categories
    if (!!this.category) {
      this.setCategory();
    }
    return feesChanged;
  }

  get overrideTotal(): boolean {
    return this.totalOverridden;
  }

  set overrideTotal(overridden: boolean) {
    // Total was overridden, but turned off, so reset prices
    if (!overridden && this.totalOverridden) {
      for (let item of this.items) {
          item.regularPrice = item.original_price;
          if (!!item.selectedVariation) {
            item.selectedVariation.regular_price = item.selectedVariation.original_price;
          }
      }
    }
    this.totalOverridden = overridden;
    this.priceupdate();
  }

  get planType(): string {
    return (this.order && this.order.accountDetails)? this.order.accountDetails.planType: '';
  }

  get vims(): Vim[] {
    if (!this.order || !this.order.vim) {
      return [];
    }
    return this.order.vim;
  }

  /*
    Floats can end up with a lot of "noise" in terms of decimal places
    this rounds to 2 dp correctly with few exceptions
  */
  roundToTwoDecimalPlaces(valueToRound: number): number {
    return Math.round((valueToRound + Number.EPSILON) * 100) / 100;
  }

  floorToTwoDecimalPlaces(valueToRound: number): number {
    return Math.floor(valueToRound * 100) / 100;
  }

  hasCouponExpired(): boolean {
    // Handle WooCommerce legacy or new format coupons
    if (this.coupon && this.coupon.expiry_date) {
      if (moment(this.coupon.expiry_date).isBefore(moment.utc())) {
        this.couponError = 'Coupon has expired';
        return true;
      }
    } 
    return false;
  }

  isOrderValueValidForCoupon(): boolean {
    if (!this.coupon) {
      return true;
    }
    const minAllowed: number = Number(this.coupon.minimum_amount);
    const maxAllowed: number = Number(this.coupon.maximum_amount);
    if (!!minAllowed && !isNaN(minAllowed) && (minAllowed > 0) && (minAllowed > this.TTorder)) {
      this.couponError = 'Order does not meet minimum value for coupon';
      return false;
    }
    if (!!maxAllowed && !isNaN(maxAllowed) && (maxAllowed > 0) && (maxAllowed < this.TTorder)) {
      this.couponError = 'Order exceeds maximum value for coupon';
      return false;
    }
    return true;
  }

  isCartCoupon(): boolean {
    if (!this.coupon) {
      return false;
    }
    if (this.coupon.type == 'fixed_cart') {
      return true;
    }

    return false;
  }

  areProductsValidForCoupon(): boolean {
    if (this.items.length == 0) {
      return false;
    }
    const setupFeeConfig: SetupFeeConfig = SETUP_FEE_CONFIGS[this.websiteSelected];
    if ((this.coupon.product_ids.length == 0) && (this.coupon.exclude_product_ids.length == 0)
        && (!setupFeeConfig || (setupFeeConfig.skusRequiringReducedFee.length == 0))) {
      // No product restrictions
      return true;
    }
    let containsAllowedItems: boolean = false;
    let containsExcludedItems: boolean = false;
    let containsNoCouponItems: boolean = false;
    for (let item of this.items) {
      // Explicitly allowed item
      if (this.coupon.product_ids.includes(item.productWebID) ||
          (!!item.selectedVariation && this.coupon.product_ids.includes(item.selectedVariation._id))) {
        containsAllowedItems = true;
      }
      if (this.coupon.exclude_product_ids.includes(item.productWebID) || 
          (!!item.selectedVariation && this.coupon.exclude_product_ids.includes(item.selectedVariation._id))) {
        containsExcludedItems = true;
      } else if (this.coupon.product_ids.length == 0) {
        // Implicitly allowed item (not in exclusion list and does not have to be on allowed list)
        containsAllowedItems = true;
      }
      if (setupFeeConfig && (setupFeeConfig.skusRequiringReducedFee.includes(item.sku))) {
        containsNoCouponItems = true;
      }
    }
    if (containsNoCouponItems) {
      this.couponError = 'No coupons can be used on orders wtih reduced setup fee items';
      return false;
    }
    // For cart level coupons cannot have excluded products
    if (this.isCartCoupon() && containsExcludedItems) {
      this.couponError = 'Order contains items not allowed by the coupon';
      return false;
    }
    // Regardless of whether it is a cart or product coupon it needs at least one allowed item
    if (!containsAllowedItems) {
      this.couponError = 'Order does not contain required items for coupon';
      return false;
    }
    return true;
  }

  isCouponValid(): boolean {
    this.couponError = '';
    if (!this.couponCode) {
      return true;
    }
    if (!!this.couponCode && (!this.coupon || !this.coupon.code)) {
      this.couponError = 'Coupon code not found';
    }
    /* Currently not applying WooCommerce checks for:
      usage limit (As coupons on the CRM are a copy of the WooCommerce record the count to apply the limit will be out of date)
      user usage limit
      product categories (WooCommerce category ids are not CRM product records)
      excluded categories
      sale price check (CRM products don't have sale prices on)
    */
    if (!this.coupon || !this.coupon.code) {
      return false;
    }
    if (!['percent', 'percent_product', 'fixed_cart', 'fixed_product' , 'free_gift'].includes(this.coupon.type)) {
      this.couponError = 'Invalid coupon type';
      return false;
    }
    if (this.hasCouponExpired()) {
      return false;
    }
    if (!this.isOrderValueValidForCoupon()) {
      return false;
    }
    if (!this.areProductsValidForCoupon()) {
      return false;
    }
    return true;
  }

  priceupdate() {
    console.log('priceupdate');
    if (!this.selectedOrderType) {
      return;
    }
    this.vat = 0;
    this.TTorder = 0;
    this.totalBeforeDiscountAndOverride = 0;
    this.calculationError = '';
    this.lifetimeLineNeedsOverride = false;
    this.hasRentalItems = false;
    if (this.hasBulkTag) {
      this.freeOrderPriceUpdate();
    } else {
      switch (this.selectedOrderType.calculationMethod) {
        case CalculationMethod.FULL:
          this.newOrderPriceUpdate();
          break;
        case CalculationMethod.ADDITIONAL:
          this.proRataPriceUpdate(false);
          break;
        case CalculationMethod.DIFFERENCE:
          this.proRataPriceUpdate(true);
          break;
        case CalculationMethod.FREE:
          this.freeOrderPriceUpdate();
          break;
        case CalculationMethod.LOST:
          this.lostItemPriceUpdate();
          break;
      default:
          break;
      }
    }
    if (this.TTorder == 0) {
      this.orderDetails.paymentMethod = 'No Payment Required';
    } else if ((this.TTorder > 0) && (this.orderDetails.paymentMethod = 'No Payment Required')) {
      this.orderDetails.paymentMethod = '';
    }
  }

  newOrderPriceUpdate() {
    let feeRequired: FeeRequired = FeeRequired.NO_FEE;
    this.orderVatStatus = OrderVatStatus.NOT_SET;
    this.orderRenewalAfter = 0;

    console.log(this.websiteSelected);
    const calculationItems: ItemForCouponCalulations[] = [];
    const setupFeeConfig: SetupFeeConfig = SETUP_FEE_CONFIGS[this.websiteSelected];

    // Calculate undiscounted value for use in Coupon checks and check if fee needs to be applied
    for (let index in this.items) {
      const currentItem: BasketItem = this.items[index];
      // Reduced fee is the priority one to use, so don't change it if already set
      if ((setupFeeConfig) && (feeRequired != FeeRequired.REDUCED_FEE)) {
        if (currentItem.category.includes('Plan') && !currentItem.crmTitle.toLocaleLowerCase().includes('outlet')) {
          if (setupFeeConfig.skusRequiringReducedFee.includes(currentItem.sku)) {
            feeRequired = FeeRequired.REDUCED_FEE;
          } else {
            feeRequired = FeeRequired.NORMAL_FEE;
          }
        }
      }
      let unitCost: number = 0;
      let originalUnitCost: number = 0;
      let unitVat: number = 0;
      let notExempt: boolean;
      let rental: boolean = false;
      if (currentItem.selectedVariation) {
        unitCost = currentItem.selectedVariation.regular_price;
        originalUnitCost = currentItem.selectedVariation.original_price;
        if (!currentItem.selectedVariation.vat) {
          // If VAT status not set on the item, use the order VAT status
          if (this.orderVatStatus == OrderVatStatus.VATABLE) {
            currentItem.selectedVariation.vat = 'not exempt';
          } else if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
            currentItem.selectedVariation.vat = 'exempt';
          }
        }
        notExempt = (currentItem.selectedVariation.vat == 'not exempt');
        rental = (currentItem.selectedVariation.hardwareSets.length > 0) || (currentItem.selectedVariation.hardware.length > 0);
        if (currentItem.selectedVariation.renewalAfterDate) {
          this.orderRenewalAfter = currentItem.selectedVariation.renewalAfterDate;
        }
      } else {
        unitCost = currentItem.regularPrice;
        originalUnitCost = currentItem.original_price;
        if (!currentItem.vat) {
          // If VAT status not set on the item, use the order VAT status
          if (this.orderVatStatus == OrderVatStatus.VATABLE) {
            currentItem.vat = 'not exempt';
          } else if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
            currentItem.vat = 'exempt';
          }
        }
        notExempt = (currentItem.vat == 'not exempt');
        rental = (currentItem.hardwareSets.length > 0) || (currentItem.hardware.length > 0);
        if (currentItem.renewalAfterDate) {
          this.orderRenewalAfter = currentItem.renewalAfterDate;
        }
      }
      if (currentItem.category == 'lifetimePlan') {
        // Lifetime plans don't have a renewal after, but we need to stop them being mixed
        // With non-lifetime plans and accessories
        this.orderRenewalAfter = -1;
      }

      this.TTorder += unitCost * currentItem.quantity;
      this.totalBeforeDiscountAndOverride += originalUnitCost * currentItem.quantity;
      if (notExempt) {
        unitVat = this.roundToTwoDecimalPlaces(0.2 * unitCost / 1.2);
        // Set unitCost to VAT exempt cost
        unitCost = this.roundToTwoDecimalPlaces(unitCost - unitVat);
        this.vat += this.roundToTwoDecimalPlaces(unitVat * currentItem.quantity);
        if (currentItem.category != 'keySafe') {
          // Key safes are never exempt, so don't define the order status
          this.orderVatStatus = OrderVatStatus.VATABLE;
        }
      } else {
        // If they have at least 1 exempt item treat them as exempt (key safes are never exempt)
        this.orderVatStatus = OrderVatStatus.VAT_EXEMPT;
      }
      const itemForCouponCalulations: ItemForCouponCalulations = {
        'product_id': currentItem.productWebID,
        'variation_id': !!currentItem.selectedVariation? currentItem.selectedVariation._id: undefined,
        'subtotalExVat': unitCost,
        'subtotalVat': unitVat,
        'totalExVat': unitCost,
        'totalVat': unitVat,
        'rentalItem': rental,
      };
      if (rental) {
        this.hasRentalItems = true;
      }
      for (let i = 0; i < currentItem.quantity; i++) {
        calculationItems.push(itemForCouponCalulations);
      }
    }
    if (setupFeeConfig && this.updateFeeIfRequired(setupFeeConfig, feeRequired)) {
      // Exit this call of priceupdate as fee was udpated and will call this method again it once the the fee is updated
      return;
    }

    console.log('Price before coupon', this.TTorder);
    this.couponDiscount = this.TTorder;
    if (!this.couponCode || !this.isCouponValid()) {
      this.convertCalculationItemsToWooItems(calculationItems);
    } else {
      switch (this.coupon.type) {
        /*
          Percent amount off whole cart
        */
        case 'percent':
        /*
          Percent amount off specific product(s)
        */
        case 'percent_product':
          this.applyPercentDiscount(calculationItems);
          break;
        /*
          Currency amount off whole cart - divided across products in ratio of VAT inc value of each line
        */
        case 'fixed_cart':
          this.applyWholeCartFixedDiscount(calculationItems);
          break;
        /*
          Currency amount off specific product(s)
        */
        case 'fixed_product':
          this.applyProductFixedDiscount(calculationItems);
          break;
        default:
          // Straight convert, no discounts need applying
          this.convertCalculationItemsToWooItems(calculationItems);
          break;
      }
    }
    console.log('WooItems', this.woodata.line_items);
    // Coupon discount is what the value was before, minus the value now
    this.couponDiscount = this.couponDiscount - this.TTorder;
    switch (this.orderRenewalAfter) {
      case 3:
        this.orderDetails.renewalFrequency = 'Quarterly';
        break;
      case 12:
        this.orderDetails.renewalFrequency = 'Annually';
        break;
      default:
        break;
    } 
  }

  getProRataPriceForHardware(hardwareSetIds: string[], hardwareIds: string[], notExempt: boolean): number {
    let tempPrice: number = 0;
    hardwareSetIds.forEach((setId: string) => {
      const hardwareSet: HardwareSet = this.hardwareSets.find((hwSet: HardwareSet) =>
        hwSet._id == setId
      );
      if (hardwareSet) {
        const setPrice: number|undefined = getPriceForOrderDate(this.recentOrder, this.planType, this.orderDateYMD,
            hardwareSet.overridePricesExVat, hardwareSet.overrideRecentQuarterlyPrice, hardwareSet.overrideRecentAnnualPrice);
        if (!setPrice) {
          this.calculationError = `${this.calculationError}\nNo price for order date for ${hardwareSet.title}`;
        } else {
          tempPrice += setPrice;
        }
      }
    });
    hardwareIds.forEach((hwId: string) => {
      const hardware: Hardware = this.hardware.find((hw: Hardware) =>
        hw._id == hwId
      );
      if (hardware) {
        const hardwarePrice: number|undefined = getPriceForOrderDate(this.recentOrder, this.planType, this.orderDateYMD,
            hardware.pricesExVat, hardware.recentQuarterlyPrice, hardware.recentAnnualPrice);
        if (!hardwarePrice) {
          this.calculationError = `${this.calculationError}\nNo price for order date for ${hardware.title}`;
        } else {
          tempPrice += hardwarePrice;
        }
      }
    });
    if (this.planType == 'monthly') {
      tempPrice = tempPrice / 3.00;
    }
    if (notExempt) {
      tempPrice = tempPrice * 1.2;
    }
    return this.roundToTwoDecimalPlaces(tempPrice * this.proRataMultiplier);
  }

  proRataPriceUpdate(removeCurrentPrice: boolean) {
    const calculationItems: ItemForCouponCalulations[] = [];

    // Calculate the un-prorated value and check if fee needs to be removed
    for (let index in this.items) {
      const currentItem: BasketItem = this.items[index];
      let unitCost: number = 0;
      let originalUnitCost: number = 0;
      let unitVat: number = 0;
      let notExempt: boolean;
      let rental: boolean = false;
      if (currentItem.selectedVariation) {
        if (!currentItem.selectedVariation.vat) {
          // If VAT status not set on the item, use the order VAT status
          if (this.orderVatStatus == OrderVatStatus.VATABLE) {
            currentItem.selectedVariation.vat = 'not exempt';
          } else if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
            currentItem.selectedVariation.vat = 'exempt';
          }
        }
        notExempt = (currentItem.selectedVariation.vat == 'not exempt');
        rental = (currentItem.selectedVariation.hardwareSets.length > 0) || (currentItem.selectedVariation.hardware.length > 0);
        if (rental) {
          if ((this.planType == 'lifetime') && (currentItem.selectedVariation.regular_price == currentItem.selectedVariation.original_price)) {
            // We can't calculate for lifetime, but flag price hasn't been overridden
            this.lifetimeLineNeedsOverride = true;
          } else if (!currentItem.priceCalculated) {
            // Don't need to calculate price if already done. Overridden prices will have to have been calculated first, so this won't reset them
            const tempPrice: number =
              this.getProRataPriceForHardware(currentItem.selectedVariation.hardwareSets, currentItem.selectedVariation.hardware, notExempt);
            // Set the original price too, so if override is turned off can set back without recalculating
            currentItem.selectedVariation.regular_price = tempPrice;
            currentItem.selectedVariation.original_price = tempPrice;
            currentItem.priceCalculated = true;
          }
        }
        unitCost = currentItem.selectedVariation.regular_price;
        originalUnitCost = currentItem.selectedVariation.original_price;
      } else {
        if (!currentItem.vat) {
          // If VAT status not set on the item, use the order VAT status
          if (this.orderVatStatus == OrderVatStatus.VATABLE) {
            currentItem.vat = 'not exempt';
          } else if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
            currentItem.vat = 'exempt';
          }
        }
        notExempt = (currentItem.vat == 'not exempt');
        rental = (currentItem.hardwareSets.length > 0) || (currentItem.hardware.length > 0);
        if (rental) {
          if ((this.planType == 'lifetime') && (currentItem.regularPrice == currentItem.original_price)) {
            // We can't calculate for lifetime, but flag price hasn't been overridden
            this.lifetimeLineNeedsOverride = true;
          } else if (!currentItem.priceCalculated) {
            const tempPrice: number =
              this.getProRataPriceForHardware(currentItem.hardwareSets, currentItem.hardware, notExempt);
            // Set the original price too, so if override is turned off can set back without recalculating
            currentItem.regularPrice = tempPrice;
            currentItem.original_price = tempPrice;
            currentItem.priceCalculated = true;
          }
        }
        unitCost = currentItem.regularPrice;
        originalUnitCost = currentItem.original_price;
      }
      this.TTorder += unitCost * currentItem.quantity;
      this.totalBeforeDiscountAndOverride += originalUnitCost * currentItem.quantity;
      if (notExempt) {
        unitVat = this.roundToTwoDecimalPlaces(0.2 * unitCost / 1.2);
        // Set unitCost to VAT exempt cost
        unitCost = this.roundToTwoDecimalPlaces(unitCost - unitVat);
        this.vat += this.roundToTwoDecimalPlaces(unitVat * currentItem.quantity);
      }
      const itemForCouponCalulations: ItemForCouponCalulations = {
        'product_id': currentItem.productWebID,
        'variation_id': !!currentItem.selectedVariation? currentItem.selectedVariation._id: undefined,
        'subtotalExVat': unitCost,
        'subtotalVat': unitVat,
        'totalExVat': unitCost,
        'totalVat': unitVat,
        'rentalItem': rental,
      };
      if (rental) {
        this.hasRentalItems = true;
      }
      for (let i = 0; i < currentItem.quantity; i++) {
        calculationItems.push(itemForCouponCalulations);
      }
    }

    console.log('Price before existing renewal adjustment', this.TTorder);
    if (!removeCurrentPrice || (this.TTorder <= 0) || (this.proRataCurrentPrice <= 0)) {
      this.convertCalculationItemsToWooItems(calculationItems);
    } else {
      this.removeCurrentPlanPriceFromLines(calculationItems);
      this.totalBeforeDiscountAndOverride = this.roundToTwoDecimalPlaces(this.totalBeforeDiscountAndOverride - this.proRataCurrentPrice);
      if (this.totalBeforeDiscountAndOverride < 0) {
        this.totalBeforeDiscountAndOverride = 0;
      }
    }
    console.log('WooItems', this.woodata.line_items);
  }

  removeCurrentPlanPriceFromLines(calculationItems: ItemForCouponCalulations[]) {
    // Reset the order total as need to calculate with discount applied
    this.TTorder = 0;
    const line_items: WooItem[] = [];
    const itemsToDiscount: ItemForCouponCalulations[] = [];
    // Need to split the discount in the ratio of the item's value to the value of the items that can be discounted
    let discountableItemTotal: number = 0;
    let discountApplied: number = 0;

    for (let calculationItem of calculationItems) {
      if (calculationItem.rentalItem && (calculationItem.subtotalExVat > 0)) {
        itemsToDiscount.push(calculationItem);
        // Need to include the VAT when determining the ratio
        discountableItemTotal += this.roundToTwoDecimalPlaces(calculationItem.subtotalExVat + calculationItem.subtotalVat);
      } else {
        // Item not discounted, so straight add
        this.TTorder += this.roundToTwoDecimalPlaces(calculationItem.totalExVat + calculationItem.totalVat);
        line_items.push({
          'product_id': !!calculationItem.variation_id? calculationItem.variation_id: calculationItem.product_id,
          'quantity': 1,
          'subtotal': this.roundToTwoDecimalPlaces(calculationItem.subtotalExVat),
          'total': this.roundToTwoDecimalPlaces(calculationItem.totalExVat),
        });
      }
    }
    // Don't allow discounting more than the new equipment pro rata'ed cost (i.e. downgrades don't give a refund)
    const amountToDiscount: number = (discountableItemTotal < this.proRataCurrentPrice)? discountableItemTotal: this.proRataCurrentPrice;
    if (amountToDiscount > 0) {
      for (let itemToDiscount of itemsToDiscount) {
        // Floor the discount here, if necessary the logic in the next step will add pennies back in until the correct discount is given
        // This stops us possibly giving a pennies too much
        const discountIncVat: number = 
          this.floorToTwoDecimalPlaces(amountToDiscount * (itemToDiscount.subtotalExVat + itemToDiscount.subtotalVat) / discountableItemTotal);
        const discountExVat: number = (itemToDiscount.subtotalVat == 0)? discountIncVat: this.roundToTwoDecimalPlaces(discountIncVat / 1.2);
        itemToDiscount.totalExVat = this.roundToTwoDecimalPlaces(Math.max(0, itemToDiscount.totalExVat - discountExVat));
        // If it is VATable recalculate the VAT
        if (itemToDiscount.subtotalVat > 0) {
          itemToDiscount.totalVat = this.roundToTwoDecimalPlaces(0.2 * itemToDiscount.totalExVat);
        }
        // Add up the including VAT discount as the voucher amount includes VAT
        discountApplied += 
          this.roundToTwoDecimalPlaces((itemToDiscount.subtotalExVat + itemToDiscount.subtotalVat) - (itemToDiscount.totalExVat + itemToDiscount.totalVat));
      }
      // If we have a remainder due to capping the discount at the line value take pennies/cents of eligible 
      // lines until we get to the appropriate applied discount.
      while (discountApplied < amountToDiscount) {
        let eligibleLineFound: boolean = false;
        for (let itemToDiscount of itemsToDiscount) {
          // Cannot substract any value from this line
          if (itemToDiscount.totalExVat < 0.01) {
            continue;
          }
          eligibleLineFound = true;
          const discountBefore: number = 
            this.roundToTwoDecimalPlaces((itemToDiscount.subtotalExVat + itemToDiscount.subtotalVat) - (itemToDiscount.totalExVat + itemToDiscount.totalVat));
          itemToDiscount.totalExVat -= 0.01;
          // If it is VATable recalculate the VAT
          if (itemToDiscount.subtotalVat > 0) {
            itemToDiscount.totalVat = this.roundToTwoDecimalPlaces(0.2 * itemToDiscount.totalExVat);
          }
          const discountAfter: number = 
            this.roundToTwoDecimalPlaces((itemToDiscount.subtotalExVat + itemToDiscount.subtotalVat) - (itemToDiscount.totalExVat + itemToDiscount.totalVat));
          discountApplied += (discountAfter - discountBefore);
          if (discountApplied >= amountToDiscount) {
            break;
          }
        }
        // Avoid getting stuck in loop if there are no more lines we can discount
        if (!eligibleLineFound) {
          break;
        }
      }
    }
    // Add the discounted lines into the total ane line_items
    for (let itemToDiscount of itemsToDiscount) {
      this.TTorder += this.roundToTwoDecimalPlaces(itemToDiscount.totalExVat + itemToDiscount.totalVat);
      line_items.push({
        'product_id': !!itemToDiscount.variation_id? itemToDiscount.variation_id: itemToDiscount.product_id,
        'quantity': 1,
        'subtotal': this.roundToTwoDecimalPlaces(itemToDiscount.subtotalExVat),
        'total': this.roundToTwoDecimalPlaces(itemToDiscount.totalExVat),
      });
    }

    this.woodata.line_items = line_items;
  }

  freeOrderPriceUpdate() {
    const calculationItems: ItemForCouponCalulations[] = [];

    // Update appropriate prices to zero and check if fee needs to be removed
    // Calculations still happen in case price on any items has been overridden to be non-zero
    for (let index in this.items) {
      const currentItem: BasketItem = this.items[index];
      let unitCost: number = 0;
      let unitVat: number = 0;
      let notExempt: boolean;
      let rental: boolean = false;
      if (currentItem.selectedVariation) {
        if (!currentItem.selectedVariation.vat) {
          // If VAT status not set on the item, use the order VAT status
          if (this.orderVatStatus == OrderVatStatus.VATABLE) {
            currentItem.selectedVariation.vat = 'not exempt';
          } else if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
            currentItem.selectedVariation.vat = 'exempt';
          }
        }
        notExempt = (currentItem.selectedVariation.vat == 'not exempt');
        rental = (currentItem.selectedVariation.hardwareSets.length > 0) || (currentItem.selectedVariation.hardware.length > 0);
        if (!currentItem.priceCalculated) {
          currentItem.selectedVariation.regular_price = 0.00;
          currentItem.selectedVariation.original_price = 0.00;
          currentItem.priceCalculated = true;
        }
        unitCost = currentItem.selectedVariation.regular_price;
      } else {
        if (!currentItem.vat) {
          // If VAT status not set on the item, use the order VAT status
          if (this.orderVatStatus == OrderVatStatus.VATABLE) {
            currentItem.vat = 'not exempt';
          } else if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
            currentItem.vat = 'exempt';
          }
        }
        notExempt = (currentItem.vat == 'not exempt');
        rental = (currentItem.hardwareSets.length > 0) || (currentItem.hardware.length > 0);
        if (!currentItem.priceCalculated) {
          currentItem.regularPrice = 0.00;
          currentItem.original_price = 0.00;
          currentItem.priceCalculated = true;
        }
        unitCost = currentItem.regularPrice;
      }
      this.TTorder += unitCost * currentItem.quantity;
      if (notExempt) {
        unitVat = this.roundToTwoDecimalPlaces(0.2 * unitCost / 1.2);
        // Set unitCost to VAT exempt cost
        unitCost = this.roundToTwoDecimalPlaces(unitCost - unitVat);
        this.vat += this.roundToTwoDecimalPlaces(unitVat * currentItem.quantity);
      }
      const itemForCouponCalulations: ItemForCouponCalulations = {
        'product_id': currentItem.productWebID,
        'variation_id': !!currentItem.selectedVariation? currentItem.selectedVariation._id: undefined,
        'subtotalExVat': unitCost,
        'subtotalVat': unitVat,
        'totalExVat': unitCost,
        'totalVat': unitVat,
        'rentalItem': rental,
      };
      if (rental) {
        this.hasRentalItems = true;
      }
      for (let i = 0; i < currentItem.quantity; i++) {
        calculationItems.push(itemForCouponCalulations);
      }
    }
    this.convertCalculationItemsToWooItems(calculationItems);
    console.log('WooItems', this.woodata.line_items);
  }

  /**
   * This gets the VAT Exempt price
   * @param hardwareSetIds The hardware set ids to get the lost price for
   * @param hardwareIds The hardware ids to get the lost price for
   * @param notExempt whether VAT is due
   * @returns the VAT Exempt lost item price
   */
  getLostItemPriceForHardware(hardwareSetIds: string[], hardwareIds: string[], notExempt: boolean): number {
    let tempPrice: number = 0;
    hardwareSetIds.forEach((setId: string) => {
      const hardwareSet: HardwareSet = this.hardwareSets.find((hwSet: HardwareSet) =>
        hwSet._id == setId
      );
      if (hardwareSet) {
        tempPrice += hardwareSet.overrideReplacementPrice;
      }
    });
    hardwareIds.forEach((hwId: string) => {
      const hardware: Hardware = this.hardware.find((hw: Hardware) =>
        hw._id == hwId
      );
      if (hardware) {
        tempPrice += hardware.replacementPrice;
      }
    });
    if (notExempt) {
      tempPrice = tempPrice * 1.2;
    }
    return tempPrice;
  }

  lostItemPriceUpdate() {
    const calculationItems: ItemForCouponCalulations[] = [];

    // Update appropriate prices to zero and check if fee needs to be removed
    for (let index in this.items) {
      const currentItem: BasketItem = this.items[index];
      let unitCost: number = 0;
      let originalUnitCost: number = 0;
      let unitVat: number = 0;
      let notExempt: boolean;
      let rental: boolean = false;
      if (currentItem.selectedVariation) {
        if (!currentItem.selectedVariation.vat) {
          // If VAT status not set on the item, use the order VAT status
          if (this.orderVatStatus == OrderVatStatus.VATABLE) {
            currentItem.selectedVariation.vat = 'not exempt';
          } else if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
            currentItem.selectedVariation.vat = 'exempt';
          }
        }
        notExempt = (currentItem.selectedVariation.vat == 'not exempt');
        rental = (currentItem.selectedVariation.hardwareSets.length > 0) || (currentItem.selectedVariation.hardware.length > 0);
        if (rental && !currentItem.priceCalculated) {
          const tempPrice: number =
            this.getLostItemPriceForHardware(currentItem.selectedVariation.hardwareSets, currentItem.selectedVariation.hardware, notExempt);
          currentItem.selectedVariation.regular_price = tempPrice;
          currentItem.selectedVariation.original_price = tempPrice;
          currentItem.priceCalculated = true;
        }
        unitCost = currentItem.selectedVariation.regular_price;
        originalUnitCost = currentItem.selectedVariation.original_price;
      } else {
        if (!currentItem.vat) {
          // If VAT status not set on the item, use the order VAT status
          if (this.orderVatStatus == OrderVatStatus.VATABLE) {
            currentItem.vat = 'not exempt';
          } else if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
            currentItem.vat = 'exempt';
          }
        }
        notExempt = (currentItem.vat == 'not exempt');
        rental = (currentItem.hardwareSets.length > 0) || (currentItem.hardware.length > 0);
        if (rental && !currentItem.priceCalculated) {
          const tempPrice: number =
            this.getLostItemPriceForHardware(currentItem.hardwareSets, currentItem.hardware, notExempt);
          currentItem.regularPrice = tempPrice;
          currentItem.original_price = tempPrice;
          currentItem.priceCalculated = true;
        }
        unitCost = currentItem.regularPrice;
        originalUnitCost = currentItem.original_price;
      }
      this.TTorder += unitCost * currentItem.quantity;
      this.totalBeforeDiscountAndOverride += originalUnitCost * currentItem.quantity;
      if (notExempt) {
        unitVat = this.roundToTwoDecimalPlaces(0.2 * unitCost / 1.2);
        // Set unitCost to VAT exempt cost
        unitCost = this.roundToTwoDecimalPlaces(unitCost - unitVat);
        this.vat += this.roundToTwoDecimalPlaces(unitVat * currentItem.quantity);
      }
      const itemForCouponCalulations: ItemForCouponCalulations = {
        'product_id': currentItem.productWebID,
        'variation_id': !!currentItem.selectedVariation? currentItem.selectedVariation._id: undefined,
        'subtotalExVat': unitCost,
        'subtotalVat': unitVat,
        'totalExVat': unitCost,
        'totalVat': unitVat,
        'rentalItem': rental,
      };
      if (rental) {
        this.hasRentalItems = true;
      }
      for (let i = 0; i < currentItem.quantity; i++) {
        calculationItems.push(itemForCouponCalulations);
      }
    }
    this.convertCalculationItemsToWooItems(calculationItems);
    console.log('WooItems', this.woodata.line_items);
  }

  convertCalculationItemsToWooItems(calculationItems: ItemForCouponCalulations[]): void {
    // Just convert the items for coupon calculations into WooItems
    this.woodata.line_items = [];
    for (let calculationItem of calculationItems) {
      this.woodata.line_items.push({
        'product_id': !!calculationItem.variation_id? calculationItem.variation_id: calculationItem.product_id,
        // Quantity set to 1 here as 1 copy of this line is added per quantity i.e. the line is multiplied up the required number of times
        'quantity': 1,
        'subtotal': this.roundToTwoDecimalPlaces(calculationItem.subtotalExVat),
        'total': this.roundToTwoDecimalPlaces(calculationItem.totalExVat),
      });
    }
  }

  couponValidForProduct(item: ItemForCouponCalulations): boolean {
    if (item.totalExVat <= 0) {
      return false;
    }
    // Cart coupons once valid to apply apply to all non-zero priced items
    if (this.isCartCoupon()) {
      return true;
    }
    if ((this.coupon.product_ids.length == 0) && (this.coupon.exclude_product_ids.length == 0)) {
      return true;
    }
    if (this.coupon.exclude_product_ids.includes(item.product_id) ||
        (!!item.variation_id && this.coupon.exclude_product_ids.includes(item.variation_id))) {
      return false;
    }
    // Not excluded and does not require opt in
    if (this.coupon.product_ids.length == 0) {
      return true;
    }
    return (this.coupon.product_ids.includes(item.product_id) ||
        (!!item.variation_id && this.coupon.product_ids.includes(item.variation_id)));
  }

  /* 
    The only difference between cart and product % discount apply is that cart
    level vouchers will have checked there are no excluded items in the cart.
    No harm in checking again here - which allows both types to share one method.
    Both types can be limited to apply to a particular list of products and/or 
    limit the number of items they apply to.
  */
  applyPercentDiscount(calculationItems: ItemForCouponCalulations[]) {
    // Reset the order total as need to calculate with discount applied
    this.TTorder = 0;
    let appliedCount: number = 0;
    const line_items: WooItem[] = [];
    const discountMultiplier: number = 1 - parseFloat(this.coupon.amount)/100;
    for (let calculationItem of calculationItems) {
      let applyDiscount: boolean = false;
      if (!!this.coupon.limit_usage_to_x_items && (this.coupon.limit_usage_to_x_items <= appliedCount)) {
        applyDiscount = false;
      } else {
        applyDiscount = this.couponValidForProduct(calculationItem); 
      }
      if (applyDiscount) {
        calculationItem.totalExVat = this.roundToTwoDecimalPlaces(calculationItem.totalExVat * discountMultiplier);
        // If it is VATable recalculate the VAT
        if (calculationItem.subtotalVat > 0) {
          calculationItem.totalVat = this.roundToTwoDecimalPlaces(0.2 * calculationItem.totalExVat);
        }
      }
      this.TTorder += this.roundToTwoDecimalPlaces(calculationItem.totalExVat + calculationItem.totalVat);
      line_items.push({
        'product_id': !!calculationItem.variation_id? calculationItem.variation_id: calculationItem.product_id,
        'quantity': 1,
        'subtotal': this.roundToTwoDecimalPlaces(calculationItem.subtotalExVat),
        'total': this.roundToTwoDecimalPlaces(calculationItem.totalExVat),
      });
    }
    this.woodata.line_items = line_items;
  }

  /*
    TODO Newer WooCommerce divides the discount evenly across the quantity of eligible items, 
    rather than in ratio of value
  */
  applyWholeCartFixedDiscount(calculationItems: ItemForCouponCalulations[]) {
    // Reset the order total as need to calculate with discount applied
    this.TTorder = 0;
    const line_items: WooItem[] = [];
    const cartDiscount: number = parseFloat(this.coupon.amount);
    const itemsToDiscount: ItemForCouponCalulations[] = [];
    // Need to split the discount in the ratio of the item's value to the value of the items that can be discounted
    let discountableItemTotal: number = 0;
    let discountApplied: number = 0;

    for (let calculationItem of calculationItems) {
      if (this.couponValidForProduct(calculationItem)) {
        itemsToDiscount.push(calculationItem);
        // Need to include the VAT when determining the ratio
        discountableItemTotal += this.roundToTwoDecimalPlaces(calculationItem.subtotalExVat + calculationItem.subtotalVat);
      } else {
        // Item not discounted, so straight add
        this.TTorder += this.roundToTwoDecimalPlaces(calculationItem.totalExVat + calculationItem.totalVat);
        line_items.push({
          'product_id': !!calculationItem.variation_id? calculationItem.variation_id: calculationItem.product_id,
          'quantity': 1,
          'subtotal': this.roundToTwoDecimalPlaces(calculationItem.subtotalExVat),
          'total': this.roundToTwoDecimalPlaces(calculationItem.totalExVat),
        });
      }
    }
    for (let itemToDiscount of itemsToDiscount) {
      // Floor the discount here, if necessary the logic in the next step will add pennies back in until the correct discount is given
      // This stops us possibly giving a pennies too much
      const discountIncVat: number = 
        this.floorToTwoDecimalPlaces(cartDiscount * (itemToDiscount.subtotalExVat + itemToDiscount.subtotalVat) / discountableItemTotal);
      const discountExVat: number = (itemToDiscount.subtotalVat == 0)? discountIncVat: this.roundToTwoDecimalPlaces(discountIncVat / 1.2);
      itemToDiscount.totalExVat = this.roundToTwoDecimalPlaces(Math.max(0, itemToDiscount.totalExVat - discountExVat));
      // If it is VATable recalculate the VAT
      if (itemToDiscount.subtotalVat > 0) {
        itemToDiscount.totalVat = this.roundToTwoDecimalPlaces(0.2 * itemToDiscount.totalExVat);
      }
      // Add up the including VAT discount as the voucher amount includes VAT
      discountApplied += 
        this.roundToTwoDecimalPlaces((itemToDiscount.subtotalExVat + itemToDiscount.subtotalVat) - (itemToDiscount.totalExVat + itemToDiscount.totalVat));
    }
    // If we have a remainder due to capping the discount at the line value take pennies/cents of eligible 
    // lines until we get to the appropriate applied discount.
    while (discountApplied < cartDiscount) {
      let eligibleLineFound: boolean = false;
      for (let itemToDiscount of itemsToDiscount) {
        // Cannot substract any value from this line
        if (itemToDiscount.totalExVat < 0.01) {
          continue;
        }
        eligibleLineFound = true;
        const discountBefore: number = 
          this.roundToTwoDecimalPlaces((itemToDiscount.subtotalExVat + itemToDiscount.subtotalVat) - (itemToDiscount.totalExVat + itemToDiscount.totalVat));
        itemToDiscount.totalExVat -= 0.01;
        // If it is VATable recalculate the VAT
        if (itemToDiscount.subtotalVat > 0) {
          itemToDiscount.totalVat = this.roundToTwoDecimalPlaces(0.2 * itemToDiscount.totalExVat);
        }
        const discountAfter: number = 
          this.roundToTwoDecimalPlaces((itemToDiscount.subtotalExVat + itemToDiscount.subtotalVat) - (itemToDiscount.totalExVat + itemToDiscount.totalVat));
        discountApplied += (discountAfter - discountBefore);
        if (discountApplied >= cartDiscount) {
          break;
        }
      }
      // Avoid getting stuck in loop if there are no more lines we can discount
      if (!eligibleLineFound) {
        break;
      }
    }
    // Add the discounted lines into the total ane line_items
    for (let itemToDiscount of itemsToDiscount) {
      this.TTorder += this.roundToTwoDecimalPlaces(itemToDiscount.totalExVat + itemToDiscount.totalVat);
      line_items.push({
        'product_id': !!itemToDiscount.variation_id? itemToDiscount.variation_id: itemToDiscount.product_id,
        'quantity': 1,
        'subtotal': this.roundToTwoDecimalPlaces(itemToDiscount.subtotalExVat),
        'total': this.roundToTwoDecimalPlaces(itemToDiscount.totalExVat),
      });
    }

    this.woodata.line_items = line_items;
  }

  applyProductFixedDiscount(calculationItems: ItemForCouponCalulations[]) {
    // Reset the order total as need to calculate with discount applied
    this.TTorder = 0;
    let appliedCount: number = 0;
    const line_items: WooItem[] = [];
    const itemDiscount: number = parseFloat(this.coupon.amount);
    for (let calculationItem of calculationItems) {
      let applyDiscount: boolean = false;
      if (!!this.coupon.limit_usage_to_x_items && (this.coupon.limit_usage_to_x_items <= appliedCount)) {
        applyDiscount = false;
      } else {
        applyDiscount = this.couponValidForProduct(calculationItem); 
      }
      if (applyDiscount) {
        // Apply discount, but do not let line value go negative
        calculationItem.totalExVat = this.roundToTwoDecimalPlaces(Math.max(0, calculationItem.totalExVat - itemDiscount));
        // If it is VATable recalculate the VAT
        if (calculationItem.subtotalVat > 0) {
          calculationItem.totalVat = this.roundToTwoDecimalPlaces(0.2 * calculationItem.totalExVat);
        }
      }
      this.TTorder += this.roundToTwoDecimalPlaces(calculationItem.totalExVat + calculationItem.totalVat);
      line_items.push({
        'product_id': !!calculationItem.variation_id? calculationItem.variation_id: calculationItem.product_id,
        'quantity': 1,
        'subtotal': this.roundToTwoDecimalPlaces(calculationItem.subtotalExVat),
        'total': this.roundToTwoDecimalPlaces(calculationItem.totalExVat),
      });
    }
    this.woodata.line_items = line_items;
  }

  onOptionsSelected() {
    console.log(this.selectedProduct);
    if (!this.selectedProduct) {
      this.selectedVariation = null;
      this.variations = [];
      return;
    }
    this.variations = this.selectedProduct.variations
      .filter((variation: RawVariation) => {
        if (variation.vat != this.vatSelected) {
          return false;
        }
        if (this.orderRenewalAfter && variation.renewalAfterDate) {
          return variation.renewalAfterDate == this.orderRenewalAfter;
        }
        return true;
      })
      .map((variation: RawVariation) => {
        const variationWithTitle: VariationWithTitle = Object.assign({}, variation);
        let tmpAttributes: VariationAttribute[] = variationWithTitle.attributes;
        tmpAttributes = variationWithTitle.attributes.filter((attribute: VariationAttribute) => 
          !/initial term/i.test(attribute.name)
        );
        if (tmpAttributes.length > 1) {
          tmpAttributes = tmpAttributes.filter((attribute: VariationAttribute) => 
            !/\bVAT\b/i.test(attribute.name)
          );
        }
        variationWithTitle.title = tmpAttributes.map((attribute: VariationAttribute) => 
          `${attribute.name}: ${attribute.option}`
        ).join(' / ');
        return variationWithTitle;
      });
    if (this.variations.length == 1) {
      this.selectedVariation = this.variations[0];
    } else {
      this.selectedVariation = null;
    }
  }

  validateBillingAddress(fromPassedData: boolean) {
    // If fromPassedData then alarm user address set from the same data, so unlock it too if there's a problem
    this.allowBillingAddressManualEntry = false;
    if (fromPassedData) {
      this.allowAlarmUserAddressManualEntry = false;
    }
    if (this.infoBilling.userAddress.validated) {
      return;
    }
    validateAddress(this.getAddrClient, this.infoBilling.userAddress).then(
      (addressValResponse: MultiRecordResponse<SelectItem<Address>>) => {
        if (addressValResponse.success) {
          this.infoBilling.userAddress.validated = true;
          if (fromPassedData) {
            this.alarmUserAddress.validated = true;
          }
          return;
        }
        this.billingSearchError = addressValResponse.message;
        this.billingSearchPostCode = this.infoBilling.userAddress? this.infoBilling.userAddress.postcode: '';
        this.infoBilling.userAddress.validated = false;
        if (fromPassedData) {
          this.alarmUserSearchError = addressValResponse.message;
          this.alarmUserSearchPostCode = this.infoBilling.userAddress? this.infoBilling.userAddress.postcode: '';
          this.alarmUserAddress.validated = false;
        }
        if (!addressValResponse.data) {
          this.showErrorPopUp('Error Validating Address',
            `Error validating the existing address. Please check it to make sure it is correct. Reason: ${addressValResponse.message}`
          );
        } else {
          this.billingAddressResults = addressValResponse.data;
          if (fromPassedData) {
            this.alarmUserAddressResults = addressValResponse.data;
          }
          this.showInfoPopUp('Possible Invalid Address',
            `The existing address could not be validated please check it is correct. Reason: ${addressValResponse.message}`
          );
        }
      }
    );
  }

  search() {
    this.tdCodeFound = false;
    this.tdCodeSearched = false;
    this.searchTDCode = this.searchTDCode.trim().toLocaleUpperCase();
    this.clearFields(true);
    if (!this.searchTDCode) {
      return;
    }
    this.orderService.getOrderTdCode(this.searchTDCode, this.websiteSelected).subscribe((response: OrderResponse) => {
      this.tdCodeSearched = true;
      if (response.success && !!response.order) {
        this.order = response.order;
        let hasNccTag: boolean = false;
        this.order.tags.forEach((tag: OrderTag) => {
          const tagName: string = tag.tagID.tagName.toLocaleLowerCase();
          if (tagName.startsWith('bulk')) {
            this.hasBulkTag = true;
          }
          if (['norfolk cc', 'ncc 6 wk free'].includes(tagName)) {
            hasNccTag = true;
          }
        });
        if (this.hasBulkTag && !this.userService.userHasPermission('Bulk Order Entry')) {
          this.showErrorPopUp('Bulk Account',
            'This order has a Bulk Account tag. Only staff with the "Bulk Order Entry" permission can place orders for these customers'
          );
          this.searchTDCode = '';
          return;
        } else if (hasNccTag && !this.userService.userHasPermission('NCC Order Entry')) {
          this.showErrorPopUp('NCC Account',
            'This order has an NCC Account tag. Only staff with the "NCC Order Entry" permission can place orders for these customers'
          );
          this.searchTDCode = '';
          return;
        }
        if (!this.order.accountDetails || !this.planType) {
          this.showErrorPopUp('Missing Plan Type', 'This order has no Plan Type you will need to set it before you can  proceed.');
          this.searchTDCode = '';
          return;
        }
        if (!doPlanCodeAndTypeMatch(this.order.accountDetails.plan, this.planType)) {
          this.showErrorPopUp('Mismatched Plan Type', 
            `This order's plan code and plan type to not match. You will need to correct this before you can proceed.`
          );
          this.searchTDCode = '';
          return;
        }
        
        const createdMoment: moment.Moment = moment.tz(this.order.created, 'Europe/London');
        this.orderDateYMD = createdMoment.format('YYYY-MM-DD');
        this.recentOrder = (this.currentMoment.diff(createdMoment, 'months') <= 11);
        this.infoBilling = {
          'email': this.order.alarmUserDetails.email,
          'mobile': this.order.alarmUserDetails.mobile? this.order.alarmUserDetails.mobile: this.order.alarmUserDetails.telephone,
          'firstName': this.order.alarmUserDetails.firstName,
          'lastName': this.order.alarmUserDetails.lastName,
          'userAddress': this.order.alarmUserDetails.userAddress,
        };
        this.company = `Company: ${this.order.legalCompany}\n`;
        this.tdCodeFound = true;
        this.vimPopupOpen = (this.order.vim.length > 0);
        if (this.order.legalCompany == 'Lifeline24 Ltd') {
          this.stripeAccount = `${this.websitetitle}_OLD`;
        }
        this.crmOrderLink = '/order/' + this.order['_id'];
        this.validateBillingAddress(false);
        switch (this.planType) {
          case 'annual':
            this.orderRenewalAfter = 12;
            break;
          case 'quarterly':
          case 'monthly':
            this.orderRenewalAfter = 3;
            break;
          case 'lifetime':
            this.orderRenewalAfter = -1;
            break;
        }
        if (this.order.accountDetails && this.order.accountDetails.plan) {
          if (this.order.accountDetails.plan.includes('V')) {
            this.vatSelected = 'not exempt';
            this.orderVatStatus = OrderVatStatus.VATABLE;
          } else {
            this.vatSelected = 'exempt';
            this.orderVatStatus = OrderVatStatus.VAT_EXEMPT;
          }
          this.setVat();
        }
        this.calculateRenewalDateAndProrataMultiplier();
      } else {
        console.error('Order not found');
      }
    }, err => {
      this.tdCodeSearched = true;
      console.error('Error finding existing order from TD Code', err);
    });
  }

  closeVimPopup() {
    this.vimPopupOpen = false;
  }

  createOrder() {
    this.showPlaceOrder = false;
    // Make sure payment steps are set to not required
    this.processingSteps['Creating Payment Method on Stripe'].required = false;
    this.processingSteps['Creating Payment Intent on Stripe'].required = false;
    // Set Whether the Direct Debit Form is required
    this.processingSteps['Creating Direct Debit Form'].required = (this.orderDetails.renewalMethod == 'directDebit');
    // Set whether steps for importing into the CRM will be required
    this.updateImportIntoCrmProcessingSteps();

    const stepName: string = 'Validating Order';
    if (!this.processingSteps[stepName].completed) {
      this.currentStep = stepName;
      if (this.validationErrorsExist()) {
        this.currentStep = '';
        this.showPlaceOrder = true;
        return;
      }
      this.processingSteps[stepName].completed = true;
    }
    this.captureOrderDetailsForReporting();
  }
  
  captureOrderDetailsForReporting() {
    this.showProgressBar = true;
    this.showPlaceOrder = false;
    this.processingError = null;
    const stepName: string = 'Capturing Details for Reporting';
    if (!this.processingSteps[stepName].required || this.processingSteps[stepName].completed) {
      this.captureDdInformation();
    } else {
      this.currentStep = stepName;
      const cseOrder: CseOrder = {
        'orderId': this.order? this.order._id: null,
        'tdCode': this.order? this.order.alarmUserDetails.tdCode: '',
        'websiteId': this.websiteSelected,
        'user': this.userName,
        'websiteOrderId': '',
        'orderType': this.orderDetails.type,
        'fault': this.fault,
        'otherFault': this.otherFault,
        'originalOrderTotal': this.totalBeforeDiscountAndOverride,
        'finalOrderTotal': this.TTorder,
        'finalOrderVat': this.vat,
        'proRataMultiplier': '',
        'proRataCurrentPrice': 0,
        'proRataToDate': '',
        'overrideReason': this.overrideReason,
        'overrideReasonOther': this.otherOverrideReason,
        'couponCode': (this.coupon)? this.coupon.code: '',
        'couponAmount': this.couponDiscount,
        'cseOrderItems': [],
        'howHeard': this.howHeard,
        'partnership': this.selectedPartnership? this.selectedPartnership.bgcName: '',
        'calledFrom': this.customerCalledFrom,
        'billingPostcode': this.infoBilling.userAddress.postcode,
        'alarmUserPostcode': '',
        'leadId': this.cseOrderNavigationData? this.cseOrderNavigationData.leadId: null,
        'currentPlan': this.order? this.order.accountDetails.plan: '',
        'paymentMethod': this.orderDetails.paymentMethod,
      };
      if (this.proRataMultiplier && (this.proRataMultiplier > 0)) {
        if (this.proRataCurrentPrice && (this.proRataCurrentPrice > 0) &&
            (this.selectedOrderType.calculationMethod == CalculationMethod.DIFFERENCE)) {
              cseOrder.proRataCurrentPrice = this.proRataCurrentPrice;
        }
        cseOrder.proRataMultiplier = this.proRataMultiplier.toFixed(6);
        cseOrder.proRataToDate = this.proRataToDate;
      }
      if (this.alarmUserAddressOption == 'other') {
        cseOrder.alarmUserPostcode = this.alarmUserAddress.postcode;
      } else if (this.alarmUserAddressOption == 'delivery') {
        cseOrder.alarmUserPostcode = this.infoDeliver.userAddress.postcode;
      } else if (this.alarmUserAddressOption == 'billing') {
        cseOrder.alarmUserPostcode = this.infoBilling.userAddress.postcode;
      }
      this.items.forEach((item: BasketItem, itemIdx: number) => {
        let originalPrice: number = item.selectedVariation? item.selectedVariation.original_price: item.original_price;
        let finalPrice: number = item.selectedVariation? item.selectedVariation.regular_price: item.regularPrice;
        const notExempt: boolean = item.selectedVariation? (item.selectedVariation.vat == 'not exempt'): (item.vat == 'not exempt');
        originalPrice = originalPrice * item.quantity;
        finalPrice = finalPrice * item.quantity;
        let finalVat: number = 0;
        if (notExempt) {
          finalVat = this.roundToTwoDecimalPlaces(0.2 * finalPrice / 1.2);
        }
        if ((item.hardware.length == 0) && (item.hardwareSets.length == 0)) {
          cseOrder.cseOrderItems.push({
            'cseOrderLine': itemIdx + 1,
            'cseOrderLinePart': 1,
            'cseOrderTitle': item.crmTitle,
            'originalPrice': originalPrice,
            'finalPrice': finalPrice,
            'finalVat': finalVat,
            'quantity': item.quantity,
            'productId': item._id,
            'variationId': item.selectedVariation? item.selectedVariation._id: 0,
            'hardwareId': null,
          });
        } else {
          let priceAssigned: boolean = false;
          let partId: number = 1;
          item.hardware.forEach((hardwareId: string) => {
            const hardware: Hardware = this.hardware.find((hw: Hardware) =>
              hw._id == hardwareId
            );
            if (hardware) {
              cseOrder.cseOrderItems.push({
                'cseOrderLine': itemIdx + 1,
                'cseOrderLinePart': partId,
                'cseOrderTitle': hardware.title,
                'originalPrice': !priceAssigned? originalPrice: 0,
                'finalPrice': !priceAssigned? finalPrice: 0,
                'finalVat': !priceAssigned? finalVat: 0,
                'quantity': item.quantity,
                'productId': item._id,
                'variationId': item.selectedVariation? item.selectedVariation._id: 0,
                'hardwareId': hardwareId,
              });
              priceAssigned = true;
              partId++;
            }
          });
          item.hardwareSets.forEach((hardwareSetId: string) => {
            const hardwareSet: HardwareSet = this.hardwareSets.find((hwSet: HardwareSet) =>
              hwSet._id == hardwareSetId
            );
            if (hardwareSet) {
              hardwareSet.containedHardware.forEach((hardwareId: HardwareId) => {
                cseOrder.cseOrderItems.push({
                  'cseOrderLine': itemIdx + 1,
                  'cseOrderLinePart': partId,
                  'cseOrderTitle': hardwareId.title,
                  'originalPrice': !priceAssigned? originalPrice: 0,
                  'finalPrice': !priceAssigned? finalPrice: 0,
                  'finalVat': !priceAssigned? finalVat: 0,
                  'quantity': item.quantity,
                  'productId': item._id,
                  'variationId': item.selectedVariation? item.selectedVariation._id: 0,
                  'hardwareId': hardwareId._id,
                });
                priceAssigned = true;
                partId++;
              });
            }
          });
        }
  
      });
      this.orderService.createRecordOfCseOrder({cseOrder: cseOrder})
        .subscribe((cseOrderResponse: SingleRecordResponse<CseOrder>) => {
          if (!cseOrderResponse.success) {
            console.error('Error recording CSE order. Error:', cseOrderResponse.message);
            this.processingError = !!cseOrderResponse.error? cseOrderResponse.error: {'message': cseOrderResponse.message};
            this.showProgressBar = false;
          } else {
            this.processingSteps[stepName].resultData = cseOrderResponse.data._id;
            this.processingSteps[stepName].completed = true;
            this.captureDdInformation();
          }
        }, err => {
          console.error('Error recording CSE order. Error:', err);
          this.processingError = err;
          this.showProgressBar = false;
        });
    }
  }

  captureDdInformation() {
    const stepName: string = 'Creating Direct Debit Form';
    if (!this.processingSteps[stepName].required || this.processingSteps[stepName].completed) {
      this.createOrderOnWooCommerce();
    } else {
      this.currentStep = stepName;
      const form: {[field: string]: string} = {
        'status': 'active',
        '1': this.directDebit.accountHolderName,
        '2': this.directDebit.sortCode,
        '3': this.directDebit.accountNumber,
        '6.3': this.orderDetails.alarmUserFirstName,
        '6.6': this.orderDetails.alarmUserLastName,
        '13': this.infoBilling.email,
      };
      if (['CL', 'TC', 'CK'].includes(this.websitetitle)) {
        form['12.1'] = this.directDebit.addressLineOne;
        form['12.2'] = this.directDebit.addressLineTwo;
        form['12.3'] = this.directDebit.city;
        form['12.5'] = this.directDebit.postcode;
        form['12.6'] = 'United Kingdom';
      }

      if (this.websitetitle == 'LL') {
        form['form_id'] = '13';
        form['19'] = this.directDebit.addressLineOne;
        form['20'] = this.directDebit.addressLineTwo;
        form['21'] = this.directDebit.city;
        form['22'] = 'United Kingdom';
        form['24'] = this.directDebit.postcode;
      } else if (this.websitetitle == 'CL') {
        form['form_id'] = '17';
      } else if (this.websitetitle == 'TC') {
        form['form_id'] = '15';
      } else if (this.websitetitle == 'CK') {
        form['form_id'] = '5';
      }

      this.gravityformsService.createForm(this.websiteSelected, form)
        .subscribe((res: SingleRecordResponse<GravityFormResponse>) => {
          let gravityResponse: GravityFormResponse;
          if (!!res.data && (typeof res.data == 'string')) {
            if (!(res.data as string).startsWith('<!DOCTYPE')) {
              gravityResponse = JSON.parse(res.data);
            }
          } else {
            gravityResponse = res.data;
          }
          if (res.success && !!gravityResponse) {
            // Is coming back from Lifeline site as string was object from Careline
            if (!!gravityResponse.response && (gravityResponse.status == 201) && (gravityResponse.response.length > 0)) {
              this.processingSteps[this.currentStep].completed = true;
              this.processingSteps[this.currentStep].resultData = `${gravityResponse.response[0]}`;
              console.log('Gravity form response', res);
              this.createOrderOnWooCommerce();
              return;
            }
          }
          console.error('Gravity form request failed', res);
          this.processingError = {'message': 'Direct Debit (Gravity) form request failed', 'code': `${gravityResponse?.status}`};
          this.showProgressBar = false;
        }, err => {
          console.error('Direct Debit (Gravity) form error', err);
          this.processingError = err;
          this.showProgressBar = false;
        });
    }
  }

  prepareWooData() {
    if (this.alarmUserNameOption == 'billing') {
      this.orderDetails.alarmUserFirstName = this.infoBilling.firstName;
      this.orderDetails.alarmUserLastName = this.infoBilling.lastName;
    }
    if (this.alarmUserNameOption == 'delivery') {
      this.orderDetails.alarmUserFirstName = this.infoDeliver.firstName;
      this.orderDetails.alarmUserLastName = this.infoDeliver.lastName;
    }
    if (this.alarmUserPhoneOption == 'billing') {
      this.orderDetails.alarmUserPhone = this.infoBilling.mobile;
    }

    this.woodata.billing_address.first_name = this.infoBilling.firstName;
    this.woodata.billing_address.last_name = this.infoBilling.lastName;
    this.woodata.billing_address.address_1 = this.infoBilling.userAddress.addressOne;
    this.woodata.billing_address.address_2 = this.infoBilling.userAddress.addressTwo;
    this.woodata.billing_address.city = this.infoBilling.userAddress.city;
    this.woodata.billing_address.state = this.infoBilling.userAddress.county;
    this.woodata.billing_address.postcode = this.infoBilling.userAddress.postcode;
    this.woodata.billing_address.email = this.infoBilling.email;
    this.woodata.billing_address.phone = this.infoBilling.mobile;
    if (!this.differentDeliver) {
      this.infoDeliver = this.infoBilling
    }

    this.woodata.shipping_address.first_name = this.infoDeliver.firstName;
    this.woodata.shipping_address.last_name = this.infoDeliver.lastName;
    this.woodata.shipping_address.address_1 = this.infoDeliver.userAddress.addressOne;
    this.woodata.shipping_address.address_2 = this.infoDeliver.userAddress.addressTwo;
    this.woodata.shipping_address.city = this.infoDeliver.userAddress.city;
    this.woodata.shipping_address.state = this.infoDeliver.userAddress.county;
    this.woodata.shipping_address.postcode = this.infoDeliver.userAddress.postcode;
    this.woodata.shipping_address.email = this.infoDeliver.email;
    this.woodata.shipping_address.phone = this.infoDeliver.mobile;
    this.woodata.note = '';
    if (this.orderDetails.notes) {
      this.woodata.note = `${this.orderDetails.notes}\n`;
    }
    if (this.orderDetails.type == 'Phone Order') {
      if (this.withheldNumber) {
        this.customerCalledFrom = 'Withheld Number';
      } 
    } else {
      this.customerCalledFrom = '';
    }

    let pendantCount: number = 0;
    for (const item of this.items) {
      if (this.needsCustomerHeight(item) || this.needsWearingOption(item)) {
        pendantCount++;
        if (this.needsCustomerHeight(item) && item.customerHeight) {
          this.woodata.note += `Pendant ${pendantCount} Customer Height: ${item.customerHeight}\n`;
        }
        if (this.needsWearingOption(item) && item.wearingOption) {
          this.woodata.note += `Pendant ${pendantCount} Wearing Option: ${item.wearingOption}\n`;
        }
      }
    }
    if (this.overrideTotal) {
      if (this.overrideReason == 'Other') {
        this.woodata.note += `Price was overridden reason: ${this.overrideReason}\n ${this.otherOverrideReason}\n`;
      } else {
        this.woodata.note += `Price was overridden reason: ${this.overrideReason}\n`;
      }
    }
   
    if (this.tdCodeFound && this.searchTDCode) {
      this.woodata.note += `Existing Order TD Code: ${this.searchTDCode}\n${this.company}`;
    }
    if (this.isExistingCustomer()) {
      if (this.isAdditional()) {
        this.woodata.note += 'Additional Equipment, not replacement\n';
      } else {
        this.woodata.note += `Reason for Replacement: ${this.orderDetails.type}\n`;
      }
    }
    if (this.isReplacementDueToFault()) {
      this.woodata.note += `Fault: ${this.fault} ${this.otherFault}\n`;
    }

    this.woodata.note += `Payment Method: ${this.orderDetails.paymentMethod}`;
    if (this.coupon && this.coupon.code) {
      this.woodata.coupon_lines = [{
        'id': this.coupon.id,
        'code': this.coupon.code,
        'amount': this.couponDiscount,
      }];
    }

    //LL custom fields
    if (this.websitetitle == 'LL') {
      // This ends up in product_meta, trying to put it in product_meta it does not get saved
      this.woodata.order_meta = {
        'ncf_required': this.orderDetails.NCFRequired,
        'contact_name': this.orderDetails.contactName,
        'renewal_method': this.orderDetails.renewalMethod,
        'renewal_frequency': this.orderDetails.renewalFrequency,
        'renewal_order': 'no',
        'calender_ideal_delivery_date': '',
        'is_vat_exempt': (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT)? 'yes': 'no',
      };

      if ('directDebit' == this.orderDetails.renewalMethod) {
        this.woodata.order_meta.account_holder_name = this.directDebit.accountHolderName;
        this.woodata.order_meta.dd_address_line_one = this.directDebit.addressLineOne;
        this.woodata.order_meta.dd_address_line_two = this.directDebit.addressLineTwo
        this.woodata.order_meta.dd_city = this.directDebit.city;
        this.woodata.order_meta.dd_postcode = this.directDebit.postcode;
        this.woodata.order_meta.sort_code = this.directDebit.sortCode;
        this.woodata.order_meta.account_number = this.directDebit.accountNumber;
      }
    }

    //CL custom fields
    if (this.websitetitle == 'CL') {
      // This ends up in product_meta, trying to put it in product_meta it does not get saved
      this.woodata.order_meta = {
        'additional_field_116': this.orderDetails.NCFRequired,
        'additional_field_201': this.orderDetails.contactName,
        'additional_field_197': this.orderDetails.renewalMethod,
        'additional_field_83': this.orderDetails.type,
      };
      if ('directDebit' == this.orderDetails.renewalMethod) {
        this.woodata.order_meta.additional_field_812 = this.directDebit.accountHolderName;
        this.woodata.order_meta.additional_field_330 = this.directDebit.addressLineOne;
        this.woodata.order_meta.additional_field_893 = this.directDebit.sortCode;
        this.woodata.order_meta.additional_field_911 = this.directDebit.accountNumber;
      }
    }

    //TC custom fields
    if (this.websitetitle == 'TC') {
      // This ends up in product_meta, trying to put it in product_meta it does not get saved
      this.woodata.order_meta = {
        'additional_field_373': `${this.orderDetails.alarmUserFirstName} ${this.orderDetails.alarmUserLastName}`,
        'additional_field_331': this.orderDetails.contactName,
        'additional_field_307': this.orderDetails.type,
        'additional_field_231': this.orderDetails.renewalMethod,
        'additional_field_72': this.orderDetails.NCFRequired,
        'additional_field_309': '',
      };
      if ('directDebit' == this.orderDetails.renewalMethod) {
        this.woodata.order_meta.additional_field_480 = this.directDebit.sortCode;
        this.woodata.order_meta.additional_field_545 = this.directDebit.accountNumber;
        this.woodata.order_meta.additional_field_244 = this.directDebit.accountHolderName;
        this.woodata.order_meta.additional_field_674 = this.directDebit.addressLineOne;
        this.woodata.order_meta.additional_field_151 = this.directDebit.addressLineTwo;
        this.woodata.order_meta.additional_field_78 = this.directDebit.city;
        this.woodata.order_meta.additional_field_696 = this.directDebit.postcode;
      }
    }

    //CK custom fields
    if (this.websitetitle == 'CK') {
      // This ends up in product_meta, trying to put it in product_meta it does not get saved
      this.woodata.order_meta = {
        'additional_field_234': `${this.orderDetails.alarmUserFirstName} ${this.orderDetails.alarmUserLastName}`,
        'additional_field_167': this.orderDetails.contactName,
        'additional_field_89': this.orderDetails.type,
        'additional_field_68': this.orderDetails.renewalMethod,
        'additional_field_10': this.orderDetails.NCFRequired,
        'additional_field_967': '',
      };
      if ('directDebit' == this.orderDetails.renewalMethod) {
        this.woodata.order_meta.additional_field_352 = this.directDebit.sortCode;
        this.woodata.order_meta.additional_field_679 = this.directDebit.accountNumber;
        this.woodata.order_meta.additional_field_513 = this.directDebit.accountHolderName;
        this.woodata.order_meta.additional_field_957 = this.directDebit.addressLineOne;
        this.woodata.order_meta.additional_field_901 = this.directDebit.addressLineTwo;
        this.woodata.order_meta.additional_field_546 = this.directDebit.city;
        this.woodata.order_meta.additional_field_768 = this.directDebit.postcode;
      }
    }

    //LLIE custom fields
    if (this.websitetitle == 'LLIE') {
      // This ends up in product_meta, trying to put it in product_meta it does not get saved
      this.woodata.order_meta = {
        'additional_field_3': `${this.orderDetails.alarmUserFirstName} ${this.orderDetails.alarmUserLastName}`,
        'additional_field_470': this.orderDetails.contactName,
        'additional_field_374': this.orderDetails.type,
        'additional_field_591': this.orderDetails.renewalMethod,
        // 'additional_field_10': this.orderDetails.NCFRequired,
        'additional_field_481': '',
      };
    }

    //Common fields 
    this.woodata.order_meta.store_clerk = this.userName;
    this.woodata.order_meta.how_heard = this.howHeard;
    this.woodata.order_meta.how_heard_partnership = this.selectedPartnership? this.selectedPartnership.bgcName: '';
    this.woodata.order_meta.alarm_users_name = `${this.orderDetails.alarmUserFirstName} ${this.orderDetails.alarmUserLastName}`;
    this.woodata.order_meta.alarm_users_phone_number = this.orderDetails.alarmUserPhone;
    this.woodata.order_meta.alarm_users_mobile_number = this.orderDetails.alarmUserMobile;
    this.woodata.order_meta.customer_called_from = this.customerCalledFrom;
    this.woodata.order_meta.order_type = this.orderDetails.type;
    if (this.tdCodeFound && this.searchTDCode) {
      this.woodata.order_meta.td_code = this.searchTDCode;
    }
    if (this.cseOrderNavigationData) {
      this.woodata.order_meta.leadId = this.cseOrderNavigationData.leadId;
    }
    this.woodata.order_meta.email_marketing = this.emailMarketing
    this.woodata.order_meta.phone_marketing = this.phoneMarketing;
    if (!this.hasBulkTag) {
      if (this.proRataMultiplier && (this.proRataMultiplier > 0)) {
        if (this.proRataCurrentPrice && (this.proRataCurrentPrice > 0) &&
            (this.selectedOrderType.calculationMethod == CalculationMethod.DIFFERENCE)) {
          this.woodata.order_meta.pro_rata_current_price = this.proRataCurrentPrice.toFixed(2);
        }
        this.woodata.order_meta.pro_rata_multiplier = this.proRataMultiplier.toFixed(6);
        this.woodata.order_meta.pro_rata_to_date = this.proRataToDate;
      }
    }
    if (this.orderDetails.paymentMethod == 'Secured Debit/Credit Card') {
      this.woodata.order_meta.name_on_card = this.nameOnCard;
    }
    this.woodata.order_meta.total_before_changes = this.totalBeforeDiscountAndOverride;
    if (this.overrideTotal) {
      if (this.overrideReason == 'Other') {
        this.woodata.order_meta.override_reason = `${this.overrideReason} ${this.otherOverrideReason}`;
      } else {
        this.woodata.order_meta.override_reason = this.overrideReason;
      }
    }
    console.log('WooData to be sent to website', this.woodata);
  }

  createOrderOnWooCommerce() {
    if (this.processingSteps[this.ORDER_CREATION_STEP].completed) {
      this.callCrmWebhook();
    } else {
      this.currentStep = this.ORDER_CREATION_STEP;
      // TODO correct return type
      this.orderService.createOrderWebsites(this.websiteSelected, this.woodata).subscribe((rsp: SingleRecordResponse<any>) => {
        if (!rsp.success) {
          console.error('Success false creating order on Alarm website', rsp.message);
          this.processingError = !!rsp.error? rsp.error: {'message': rsp.message};
          this.showProgressBar = false;
          return;
        }
        this.processingSteps[this.currentStep].completed = true;
        this.processingSteps[this.currentStep].resultData = rsp.data['order']['id'];
        this.orderLink = this.url+'/wp-admin/post.php?post='+rsp.data['order']['id']+'&action=edit';
        // We know the order id, so we do not need the staff member to enter it manually
        this.websiteOrderId = rsp.data['order']['id'];

        this.currentStep = this.ORDER_STATUS_UPDATE_STEP;
        // If success, but an error object is returned then the order was created, but status was not updated
        if (!!rsp.error) {
          console.error('Order created on Alarm website, but status not updated to processing.', rsp.message);
          this.processingError = rsp.error;
          this.showProgressBar = false;
          return;
        }
        this.processingSteps[this.currentStep].completed = true;
        console.log('WooCommerce order creation response', rsp);
        this.callCrmWebhook();
      }, err => {
        console.error('Error creating order on alarm website', err);
        this.processingError = err;
        this.showProgressBar = false;
      });
    }
  }

  callCrmWebhook() {
    const stepName: string = 'Importing into CRM';
    if (!this.processingSteps[stepName].required || this.processingSteps[stepName].completed) {
      this.updateCrmOrder();
    } else {
      this.currentStep = stepName;
      const orderId: string = this.processingSteps[this.ORDER_CREATION_STEP].resultData;
      console.log('calling webhook');
      this.orderService.addOrderCRM(this.websiteSelected, {'order': {'id': orderId}})
        .subscribe((_res: SimpleResponse) => {
          console.log('webhook called');
          // Do not mark as completed here as webhook returns before it's actually finished it's work
          setTimeout(() => this.updateCrmOrder(), 4000);
        }, err => {
          console.error('Error calling CRM webhook to import order', err);
          this.processingError = err;
          this.showProgressBar = false;
        });
    }
  }

  updateCseOrderRecord() {
    //TODO change to updating reporting record
    const stepName: string = 'Updating Order Details for Reporting';
    if (!this.processingSteps[stepName].required || this.processingSteps[stepName].completed) {
      if (this.order) {
        this.crmOrderLink = '/order/' + this.order['_id'];
      }
      this.showProgressBar = false;
      this.success = true;
      this.currentStep = '';
    } else {
      this.currentStep = stepName;
      const orderId: string = this.processingSteps[this.ORDER_CREATION_STEP].resultData;
      this.orderService.updateCseOrderRecord({
        cseOrderUpdate: {
          '_id': this.processingSteps['Capturing Details for Reporting'].resultData,
          'websiteOrderId': orderId,
          'orderId': this.order? this.order._id: null,
          'tdCode': this.order? this.order.alarmUserDetails.tdCode: '',
          'currentPlan': this.order? this.order.accountDetails.plan: '',
        }
      })
        .subscribe((cseOrderResponse: SimpleResponse) => {
          if (!cseOrderResponse.success) {
            console.error('Error updating CSE order. Error:', cseOrderResponse.message);
            this.processingError = !!cseOrderResponse.error? cseOrderResponse.error: {'message': cseOrderResponse.message};
            this.showProgressBar = false;
          } else {
            this.showProgressBar = false;
            this.success = true;
            this.currentStep = '';
            if (this.order) {
              this.crmOrderLink = '/order/' + this.order['_id'];
              if (!this.isExistingCustomer()) {
                this.router.navigate(['/order/' + this.order['_id']]);
              }
            }
          }
        }, err => {
          console.error('Error updating CSE order. Error:', err);
          this.processingError = err;
          this.showProgressBar = false;
        });
    }
  }

  updateCrmOrder() {
    const stepName: string = 'Updating CRM order ref, processor and alarm user';
    const orderId: string = this.processingSteps[this.ORDER_CREATION_STEP].resultData;
    if (this.processingSteps[stepName].completed) {
      this.updateCseOrderRecord();
    } else if (!this.processingSteps[stepName].required) {
      if (this.isExistingCustomer() && this.tdCodeFound) {
        const tdCode: string = this.order.alarmUserDetails.tdCode;
        let equipmentSent: string[] = this.items.map((basketItem: BasketItem) => 
            `${basketItem.crmTitle} x ${basketItem.quantity}`
        );
        let overrideMsg: string = '';
        if (this.overrideTotal) {
          overrideMsg = `Note: the order total was overridden for reason: ${this.overrideReason} ${this.otherOverrideReason}`;
        }
        /*
        TODO remove until notes not on order itself
        let noteText: string = `${this.userName} placed a ${this.orderTypeTitle} order WooCommerce Order: ${this.orderLink}\n` +
          `The items on the order are: ${equipmentSent.join('\n')}`;
        if (this.hasRentalItems &&
            [CalculationMethod.ADDITIONAL, CalculationMethod.DIFFERENCE].includes(this.selectedOrderType.calculationMethod)) {
          noteText = `${noteText}\nTheir next renewal is ${this.nextRenewalDate} and payment of ${this.TTorder.toFixed(2)} ` +
            `has been taken to cover the increase up to ${this.proRataToDate}.\n${overrideMsg}`;
        }

        const params: AddNoteViaQueueRequest = {
          'mongoOrderId': this.order._id,
          'brand': this.websitetitle,
          'tdCode': tdCode,
          'note': noteText,
          'username': this.userName,
          'categories': ['General']
        };
        this.proposedMessageService.addEntryToNoteQueue(params).subscribe((response: any) => {
          if (!response.success) {
            this.showErrorPopUp('Error adding note to note queue', `Error queuing request to add a note about this order. Error: ${response.message}`);
          }
        }, (err: Error) => {
          this.showErrorPopUp('Error adding note to note queue', `Error queuing request to add a note about this order. Error: ${err.message}`);
        });
        */
        if (this.hasRentalItems &&
            [CalculationMethod.ADDITIONAL, CalculationMethod.DIFFERENCE].includes(this.selectedOrderType.calculationMethod)) {
          const crmOrderLink: string = `${environment.protocol}${environment.IPAddress}/order/${this.order._id}`;
          const plainTextMsg: string = 
            `a ${this.orderTypeTitle} order has been placed for the below order, which will require a change to their renewal.\n` +
            `Customer Order (TD): ${tdCode} ${crmOrderLink}\nWooCommerce Order: ${this.orderLink}\n` +
            `The items on the order are: ${equipmentSent.join('\n')}` +
            `Their next renewal is ${this.nextRenewalDate} and payment of ${this.TTorder.toFixed(2)} ` +
            `has been taken to cover the increase up to ${this.proRataToDate}.\n${overrideMsg}`;
          const recipients: string[] = JSON.parse(localStorage.getItem('email: Admin'));
          this.notificationService.sendEmail({
            'recipients': recipients,
            'subject': `Renewal Payment Change due to ${this.orderTypeTitle} order`,
            'plainTextMsg': `Hi Team,\n${plainTextMsg}\nThanks,\n${this.userName}`,
            'htmlMsg': 
                `<p>Hi Team,</p>` +
                `<p>a ${this.orderTypeTitle} order has been placed for the below order, which will require a change to their renewal.</p>` + 
                `<p>Customer Order (TD): <a href='${crmOrderLink}' target='_blank'>${tdCode}</a><br/>` +
                `WooCommerce Order: <a href='${this.orderLink}' target='_blank'>${this.processingSteps[this.ORDER_CREATION_STEP].resultData}</a></p>` +
                `<p>The items on the order are: ${equipmentSent.join('<br/>')}</p>` +
                `<p>Their next renewal is ${this.nextRenewalDate} and payment of ${this.TTorder.toFixed(2)} ` + 
                `has been taken to cover the increase up to ${this.proRataToDate}.</p><p>${overrideMsg}</p>` +
                `<p>Thanks,<br/>${this.userName}</p>`,
          }).subscribe((emailResponse: MultiRecordResponse<string>) => {
            if (emailResponse.success) {
              if (emailResponse.data.length > 0) {
                this.processingError = {
                  'message': `There was an error emailing ${emailResponse.data.join(';')} to notify them. Please email them these details manually:\n${plainTextMsg}`
                };
              } else {
                this.processingSteps[stepName].completed = true;
                this.updateCseOrderRecord();
                return;
              }
            } else {
              this.processingError = {
                'message': `There was an error emailing ${recipients.join(';')} to notify them. Please email them these details manually:\n${plainTextMsg}`
              };
            }
            this.showProgressBar = false;
            // Skip sending the email as we've asked staff to do it manually
            this.processingSteps[stepName].completed = true;
          });
        } else {
          this.processingSteps[stepName].completed = true;
          this.updateCseOrderRecord();
        }
      } else {
        this.processingSteps[stepName].completed = true;
        this.updateCseOrderRecord();
      }
      return;
    }
    // Have to run find to be able to do the follow on steps
    this.orderService.findOrder({'orderId': orderId, 'website': this.websiteSelected, 'deleted': false}).subscribe((findResponse: FindOrderResponse) => {
      console.log('Find CRM Order response', findResponse);
      if (!findResponse.success || (findResponse.orders.length == 0)) {
        this.processingError = {'message': 'Order not found in CRM'};
        this.showProgressBar = false;
      } else {
        // Now we can mark webhook as complete as it must have been called to find the order
        this.processingSteps['Importing into CRM'].completed = true;
        this.order = findResponse['orders'][0];
        // This is done as normal webhook sets the alarm user's name to the biller's name
        if (this.orderDetails.alarmUserFirstName) {
          this.order.alarmUserDetails.firstName = this.orderDetails.alarmUserFirstName;
          this.order.alarmUserDetails.lastName = this.orderDetails.alarmUserLastName;
          this.order.alarmUserDetails.userAddress.validated = this.infoBilling.userAddress.validated;
        }
        this.order.initialOrderDetails.processor = this.userName;
        this.order.initialOrderDetails.payment = this.orderDetails.paymentMethod;
        if (this.orderDetails.paymentMethod == 'Secured Debit/Credit Card') {
          this.order.initialOrderDetails.transactionId = this.processingSteps['Creating Payment Intent on Stripe'].resultData;
        }
        this.order.alarmUserDetails.tdCode = this.order.alarmUserDetails.tdCode.replace('WEB','CSE');
        if (this.cseOrderNavigationData) {
          this.leadService.updateLead({
            _id: this.cseOrderNavigationData.leadId,
            orderId: this.order._id,
            tdCode: this.order.alarmUserDetails.tdCode,
            status: 'Ordered Over Phone',
          }).subscribe((response: SimpleResponse) => {
            console.log('Response :: ', response);
          }, (err: Error) => {
            console.log('ERROR :: ', err);
          });
        }
        if (this.alarmUserAddressOption == 'other') {
          this.order.alarmUserDetails.userAddress.addressOne = this.alarmUserAddress.addressOne;
          this.order.alarmUserDetails.userAddress.addressTwo = this.alarmUserAddress.addressTwo;
          this.order.alarmUserDetails.userAddress.city = this.alarmUserAddress.city;
          this.order.alarmUserDetails.userAddress.county = this.alarmUserAddress.county;
          this.order.alarmUserDetails.userAddress.postcode = this.alarmUserAddress.postcode;
          this.order.alarmUserDetails.userAddress.validated = this.alarmUserAddress.validated;
          this.order.initialOrderDetails.installationAddress = getFormattedAddress(this.alarmUserAddress);
        } else if (this.alarmUserAddressOption == 'unknown') {
          this.order.alarmUserDetails.userAddress.unknown = true;
          this.order.initialOrderDetails.installationAddress = '"Unknown" alarm user address selected when CSE entered this.order'
        } else if (this.alarmUserAddressOption == 'delivery') {
          this.order.alarmUserDetails.userAddress.addressOne = this.infoDeliver.userAddress.addressOne;
          this.order.alarmUserDetails.userAddress.addressTwo = this.infoDeliver.userAddress.addressTwo;
          this.order.alarmUserDetails.userAddress.city = this.infoDeliver.userAddress.city;
          this.order.alarmUserDetails.userAddress.county = this.infoDeliver.userAddress.county;
          this.order.alarmUserDetails.userAddress.postcode = this.infoDeliver.userAddress.postcode;
          this.order.alarmUserDetails.userAddress.validated = this.alarmUserAddress.validated;
          this.order.initialOrderDetails.installationAddress = getFormattedAddress(this.infoDeliver.userAddress);
        } else if (this.alarmUserAddressOption == 'billing') {
          // The alarm user will already be set to this, so just need to populate installation address
          this.order.initialOrderDetails.installationAddress = getFormattedAddress(this.infoBilling.userAddress);
        }

        this.order.accountContacts[0].emailMarketing = this.emailMarketing;
        this.order.accountContacts[0].emailMarketingUpdated = new Date();
        if (isValidAnyCountryMobile(this.infoBilling.mobile)) {
          this.order.accountContacts[0].mobileMarketing = this.phoneMarketing;
          this.order.accountContacts[0].mobileMarketingUpdated = new Date();
        }

        // If the alarm user is the billing user then the alarm user is opted in
        if (this.alarmUserNameOption == 'billing') {
          this.order.alarmUserDetails.emailMarketing = this.emailMarketing;
          this.order.alarmUserDetails.emailMarketingUpdated = new Date();
          if (((this.alarmUserPhoneOption == 'billing') || (this.infoBilling.mobile == this.orderDetails.alarmUserMobile)) &&
              isValidAnyCountryMobile(this.infoBilling.mobile)) {
            this.order.alarmUserDetails.mobileMarketing = this.phoneMarketing;
            this.order.alarmUserDetails.mobileMarketingUpdated = new Date();
          }
        }
        
        this.orderService.updateOrder(this.order._id, {'order': this.order}).subscribe((res: OrderResponse) => {
          if (res.success) {
            this.processingSteps[this.currentStep].completed = true;
            this.updateCseOrderRecord();
          } else {
            this.processingError = {
              'message': `There was an error updating the CRM order details. ${res.message}`
            };
            this.showProgressBar = false;
          }
        }, err => {
          console.error('Error updating CRM order ref, processor and alarm user', err);
          this.processingError = err;
          this.showProgressBar = false;
        });
      }
    }, err => {
      console.error('Error finding order on CRM after webhook called', err);
      this.processingError = err;
      this.showProgressBar = false;
    });
  }

  delete(i: number) {
    this.items.splice(i, 1);
    this.priceupdate();
    this.updateFilters();
  }

  updateFilters() {
    // Update the category list for changes to the VAT status and frequency of products in the basket
    if (this.category) {
      // This calls setVat, so no need to call that if category is set
      this.setCategory();
    } else if (this.vatSelected) {
      this.setVat();
    }
  }

  setCategory() {
    this.setVat();
    this.productsFiltered = this.productsFiltered.filter((product: RawProduct) => {
      if (product.category != this.category) {
        return false;
      }
      if (this.orderRenewalAfter) {
        if (product.renewalAfterDate) {
          return product.renewalAfterDate == this.orderRenewalAfter;
        }
        if (product.variations && product.variations.length) {
          return product.variations.some((variation: RawVariation) =>
            // Find a variation that does not have a renew after set, or matches
            !variation.renewalAfterDate || (variation.renewalAfterDate == this.orderRenewalAfter)
          );
        }
      }
      return true;
    });
    this.selectedProduct = null;
    this.variations = [];
  }

  setVat() {
    this.productsFiltered = this.products.filter((product: RawProduct) => 
      (product.vat == null) || (product.vat == '') || (product.vat == this.vatSelected)
    );
    if (this.vatSelected == 'exempt') {
      if (this.orderVatStatus == OrderVatStatus.VATABLE) {
        // They can't choose anything from this category with VATable items in their basket
        this.categories = [];
      } else {
        this.categories = this.exemptCategories; // Ex keysafe
      }
    } else {
      if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
        // They have VAT exempt items in the order, so can only add keysafes from the VATable category
        this.categories = [{
          'label': 'Key Safe',
          'value': 'keySafe'
        }];
      } else {
        this.categories = this.notExemptCategories; // Inc keysafe
      }
    }
    this.categories = this.categories.filter((categorySelect: SelectItem<string>) => {
      switch (categorySelect.value) {
        case 'quarterlyPlan':
          return !this.orderRenewalAfter || (this.orderRenewalAfter == 3);
        case 'annualPlan':
          return !this.orderRenewalAfter || (this.orderRenewalAfter == 12);
        case 'lifetimePlan':
          return !this.orderRenewalAfter || (this.orderRenewalAfter == -1);
        case 'discontinued':
          return (this.isAllowedDiscontinued());
        case 'cables':
          return (this.isAllowedDiscontinued());
        default:
          return true;
      }
    });
  }

  createCardPaidOrder() {
    this.showPlaceOrder = false;
    // Make sure payment steps are set to required
    this.processingSteps['Creating Payment Method on Stripe'].required = true;
    this.processingSteps['Creating Payment Intent on Stripe'].required = true;
    // Set Whether the Direct Debit Form is required
    this.processingSteps['Creating Direct Debit Form'].required = (this.orderDetails.renewalMethod == 'directDebit');
    // Set whether steps for importing into the CRM will be required
    this.updateImportIntoCrmProcessingSteps();

    const stepName: string = 'Validating Order';
    if (!this.processingSteps[stepName].completed) {
      this.currentStep = stepName;
      if (this.validationErrorsExist()) {
        this.currentStep = '';
        this.showPlaceOrder = true;
        return;
      }
      this.processingSteps[this.currentStep].completed = true;
    }
    this.createPaymentMethod();
  }

  async createPaymentMethod() {
    this.showPlaceOrder = false;
    this.showProgressBar = true;
    this.processingError = null;
    /*
      Even if 'Creating Payment Method on Stripe' completed we need to redo it to get the paymentMethod id for 
      'Creating Payment Intent on Stripe' if that step has not been completed
    */
    if (this.processingSteps['Creating Payment Intent on Stripe'].completed) {
      this.captureOrderDetailsForReporting();
    } else {
      this.currentStep = 'Creating Payment Method on Stripe';
      try {
        const result: PaymentMethodResult = await this.stripes[this.stripeAccount].createPaymentMethod({
          type: 'card',
          card: this.cards[this.stripeAccount],
        });
        if (!!result['error']) {
          this.processingError = result['error'];
          this.showProgressBar = false;
        } else {
          this.processingSteps[this.currentStep].completed = true;
          this.processingSteps[this.currentStep].resultData = result['paymentMethod']['id'];
          this.createPaymentIntent();
        }
      } catch (error) {
        this.processingError = {'message': error.message};
        console.error('Payment processing error', this.processingError);
        this.showProgressBar = false;
      }
    }
  }

  createPaymentIntent() {
    const custName: string = `${this.infoBilling.firstName} ${this.infoBilling.lastName}`;
    let paymentDescription: string = '';
    let customerDescription: string = custName;
    const metadata: StringIndexedObject<string> = {};
    if (this.tdCodeFound && this.order) {
      paymentDescription = `${this.order.alarmUserDetails.tdCode} existing customer order`;
      customerDescription = `${this.order.alarmUserDetails.tdCode} ${custName}`;
      metadata.tdCode = this.order.alarmUserDetails.tdCode;
    } else {
      paymentDescription = `${custName} - CSE Order`;
    }
    this.currentStep = 'Creating Payment Intent on Stripe';
    this.orderService.createOrderPaymentIntent({
        stripeAccount: this.stripeAccount,
        amount: this.TTorder.toFixed(2),
        currency: this.currencyCode.toLocaleLowerCase(),
        description: paymentDescription,
        customer_name: this.nameOnCard,
        billing_details: {
          address: {
            line1: this.infoBilling.userAddress.addressOne,
            line2: this.infoBilling.userAddress.addressTwo,
            city: this.infoBilling.userAddress.city,
            postal_code: this.infoBilling.userAddress.postcode,
          },
          email: this.infoBilling.email,
          name: custName,
          phone: this.infoBilling.mobile,
          description: customerDescription,
          metadata: metadata,
        },
        paymentMethod: this.processingSteps['Creating Payment Method on Stripe'].resultData
      }
    ).subscribe((rsp: SingleRecordResponse<PaymentIntent>) => {
      console.log(rsp);
      if (!rsp.success) {
        const error: StripeError & {raw: any} = rsp.error;
        this.processingError = {'message': rsp.message, 'code': error.code};
        // error.type is StripeCardError typing says it should be card_error, which is what the type on the raw has
        this.processingSteps[this.currentStep].allowToBeSkipped = (error.raw.type != 'card_error');
        this.showProgressBar = false;
        return;
      } else if (rsp.data.status != 'succeeded') {
        this.processingError = {'message': 'Payment Intent created, but not at "succeeded" status'};
        this.processingSteps[this.currentStep].allowToBeSkipped = false;
        this.showProgressBar = false;
      } else {
        this.processingSteps[this.currentStep].completed = true;
        this.processingSteps[this.currentStep].resultData = rsp.data.id;
        this.captureOrderDetailsForReporting();
      }
    }, err => {
      console.error('Error creating payment intent on stripe', err);
      this.processingError = {'message': err.message};
      console.error('Something is wrong: V2', this.processingError);
      this.showProgressBar = false;
    });
  }

  async ngAfterViewInit() {
    const style = {
      'base': {
        'fontFamily': 'monospace',
        'fontSmoothing': 'antialiased',
        'fontSize': '19px',
        '::placeholder': {
          'color': 'purple'
        }
      }
    };
    const stripeLL: Stripe = await loadStripe(environment.stripeKeyLL);
    const stripeCL: Stripe = await loadStripe(environment.stripeKeyCL);
    const stripeCK: Stripe = await loadStripe(environment.stripeKeyCK);
    const stripeTC: Stripe = await loadStripe(environment.stripeKeyTC);
    const stripeLLIE: Stripe = await loadStripe(environment.stripeKeyLLIE);
    const stripeLLOld: Stripe = await loadStripe(environment.stripeKeyLLOld);
    const stripeCLOld: Stripe = await loadStripe(environment.stripeKeyCLOld);
    const stripeCKOld: Stripe = await loadStripe(environment.stripeKeyCKOld);
    const stripeTCOld: Stripe = await loadStripe(environment.stripeKeyTCOld);
    const stripeLLIEOld: Stripe = await loadStripe(environment.stripeKeyLLIEOld);
    const elementsLL: StripeElements = stripeLL.elements();
    const elementsCL: StripeElements = stripeCL.elements();
    const elementsCK: StripeElements = stripeCK.elements();
    const elementsTC: StripeElements = stripeTC.elements();
    const elementsLLIE: StripeElements = stripeLLIE.elements();
    const elementsLLOld: StripeElements = stripeLLOld.elements();
    const elementsCLOld: StripeElements = stripeCLOld.elements();
    const elementsCKOld: StripeElements = stripeCKOld.elements();
    const elementsTCOld: StripeElements = stripeTCOld.elements();
    const elementsLLIEOld: StripeElements = stripeLLIEOld.elements();

    this.stripes = {};
    this.stripes['LL'] = stripeLL;
    this.stripes['CL'] = stripeCL;
    this.stripes['TC'] = stripeTC;
    this.stripes['CK'] = stripeCK;
    this.stripes['LLIE'] = stripeLLIE;
    this.stripes['LL_OLD'] = stripeLLOld;
    this.stripes['CL_OLD'] = stripeCLOld;
    this.stripes['TC_OLD'] = stripeTCOld;
    this.stripes['CK_OLD'] = stripeCKOld;
    this.stripes['LLIE_OLD'] = stripeLLIEOld;

    this.cards={};
    this.cards['LL'] = elementsLL.create('card', { style });
    this.cards['LL'].mount(this.cardInfoLL.nativeElement);
    this.cards['LL'].on('change', this.cardHandler);

    this.cards['CL'] = elementsCL.create('card', { style });
    this.cards['CL'].mount(this.cardInfoCL.nativeElement);
    this.cards['CL'].on('change', this.cardHandler);

    this.cards['CK'] = elementsCK.create('card', { style });
    this.cards['CK'].mount(this.cardInfoCK.nativeElement);
    this.cards['CK'].on('change', this.cardHandler);

    this.cards['TC'] = elementsTC.create('card', { style });
    this.cards['TC'].mount(this.cardInfoTC.nativeElement);
    this.cards['TC'].on('change', this.cardHandler);

    this.cards['LLIE'] = elementsLLIE.create('card', { style });
    this.cards['LLIE'].mount(this.cardInfoLLIE.nativeElement);
    this.cards['LLIE'].on('change', this.cardHandler);

    this.cards['LL_OLD'] = elementsLLOld.create('card', { style });
    this.cards['LL_OLD'].mount(this.cardInfoLLOld.nativeElement);
    this.cards['LL_OLD'].on('change', this.cardHandler);

    this.cards['CL_OLD'] = elementsCLOld.create('card', { style });
    this.cards['CL_OLD'].mount(this.cardInfoCLOld.nativeElement);
    this.cards['CL_OLD'].on('change', this.cardHandler);

    this.cards['CK_OLD'] = elementsCKOld.create('card', { style });
    this.cards['CK_OLD'].mount(this.cardInfoCKOld.nativeElement);
    this.cards['CK_OLD'].on('change', this.cardHandler);

    this.cards['TC_OLD'] = elementsTCOld.create('card', { style });
    this.cards['TC_OLD'].mount(this.cardInfoTCOld.nativeElement);
    this.cards['TC_OLD'].on('change', this.cardHandler);

    this.cards['LLIE_OLD'] = elementsLLIEOld.create('card', { style });
    this.cards['LLIE_OLD'].mount(this.cardInfoLLIEOld.nativeElement);
    this.cards['LLIE_OLD'].on('change', this.cardHandler);
  }

  retryOrder() {
    // clear the previous error
    this.processingError = null;
    this.showProgressBar = true;
    this.displayErrorDetails = false;
    if ([this.ORDER_CREATION_STEP, 'Checking User Supplied OrderId'].includes(this.currentStep)) {
      if (!!this.websiteOrderId) {
        this.checkOrderId();
        return;
      }
    } else if (this.currentStep == this.ORDER_STATUS_UPDATE_STEP) {
      // This step the code can't redo and the user should have done this before retrying, so mark it done
      this.processingSteps[this.ORDER_STATUS_UPDATE_STEP].completed = true;
    }
    if (this.processingSteps['Creating Payment Intent on Stripe'].required
        && !this.processingSteps['Creating Payment Intent on Stripe'].completed) {
      if (!!this.paymentIntentRef) {
        this.checkPaymentReference();
      } else {
        // Card order, with payment not taken so go down that route
        this.createPaymentMethod();
      }
    } else {
      this.captureOrderDetailsForReporting();
    }
  }

  checkOrderId() {
    this.currentStep = 'Checking User Supplied OrderId';
    this.orderService.checkWooOrderId(this.websiteSelected, this.websiteOrderId)
      .subscribe((rsp: SingleRecordResponse<string>) => {
        if (rsp.success) {
          console.log('Woo Order Id check - Order Status:', rsp.data);
          this.processingSteps[this.ORDER_CREATION_STEP].completed = true;
          this.processingSteps[this.ORDER_CREATION_STEP].resultData = `${this.websiteOrderId}`;
          this.processingSteps[this.ORDER_STATUS_UPDATE_STEP].completed = true;
          this.orderLink = this.url+'/wp-admin/post.php?post='+this.websiteOrderId+'&action=edit';
          this.captureOrderDetailsForReporting();
        } else {
          console.error(`Error checking WooCommerce order id ${this.websiteOrderId}`, !!rsp.error? rsp.error: rsp.message);
          if (!!rsp.error) {
            this.processingError = rsp.error;
          } else {
            this.processingError = {'message': rsp.message};
          }
          this.showProgressBar = false;
          this.displayErrorDetails = true;
        }
      }, err => {
        console.error(`Thrown Error checking WooCommerce order id ${this.websiteOrderId}`, err);
        this.processingError = err;
        this.showProgressBar = false;
        this.displayErrorDetails = true;
      });    
  }

  checkPaymentReference() {
    this.currentStep = 'Checking User Supplied Payment Reference';
    this.paymentIntentRef = this.paymentIntentRef.trim();
    if (!this.paymentIntentRef.startsWith('pi_')) {
      this.processingError = {
        'message': 'Invalid payment reference - it must start with pi_',
      };
      this.showProgressBar = false;
      this.displayErrorDetails = false;
      return;
    }
    this.orderService.getPaymentIntent(this.stripeAccount, this.paymentIntentRef)
      .subscribe((response: SingleRecordResponse<PaymentIntent>) => {
        if (response.success) {
          if (response.data.status != 'succeeded') {
            this.processingError = {
              'message': `Payment Intent ${this.paymentIntentRef} is not at "succeeded" status. Payment must be successful to proceed`
            };
            this.displayErrorDetails = true;
            this.showProgressBar = false;
          } else {
            this.processingSteps['Creating Payment Intent on Stripe'].completed = true;
            this.captureOrderDetailsForReporting();
          }
        } else {
          console.error(`Error getting payment intent for pi_ reference ${this.paymentIntentRef}`, response.error);
          const error: StripeError = response.error;
          this.processingError = {'message': response.message, 'code': error.code};
          this.displayErrorDetails = true;
          this.showProgressBar = false;
        }
      }, err => {
        console.error(`Thrown Error getting payment intent for pi_ reference ${this.paymentIntentRef}`, err);
        this.processingError = err;
        this.displayErrorDetails = true;
        this.showProgressBar = false;
      });
  }

  searchCoupon() {
    if (!this.couponCode) {
      this.couponError = '';
      this.coupon = undefined;
    }
    // Coupon codes on WooCommerce are all lowercase
    this.couponCode = this.couponCode.toLocaleLowerCase();
    let res: Coupon|undefined = undefined;
    for (let coupon of this.coupons) {
      if (coupon['code'] == this.couponCode) {
        res = coupon;
        if (this.partnershipByVoucherCode[this.couponCode]) {
          this.howHeard = 'Partnership';
          this.selectedPartnership = this.partnershipByVoucherCode[this.couponCode];
        }
      }
    }
    this.coupon = res;
    this.priceupdate();
  }

  addAddress() {
    if ('directDebit' == this.orderDetails.renewalMethod) {
      this.directDebit.addressLineOne=this.infoBilling.userAddress.addressOne;
      this.directDebit.addressLineTwo=this.infoBilling.userAddress.addressTwo;
      this.directDebit.city=this.infoBilling.userAddress.city;
      this.directDebit.postcode = this.infoBilling.userAddress.postcode;
      this.showInfoPopUp('Reminder', 'Remember to tell the customer about direct debit guarantee.');
    } else {
      this.directDebit.addressLineOne='';
      this.directDebit.addressLineTwo='';
      this.directDebit.city='';
      this.directDebit.postcode='';
    }
  }

  isExistingCustomer(): boolean {
    return this.selectedOrderType && !this.selectedOrderType.isNewOrder;
  }

  isReplacementWithoutTdAllowed(): boolean {
    return this.selectedOrderType && (this.selectedOrderType.calculationMethod == CalculationMethod.FREE);
  }

  isAdditional(): boolean {
    return this.selectedOrderType && (this.selectedOrderType.calculationMethod == CalculationMethod.ADDITIONAL);
  }

  isReplacementDueToFault(): boolean {
    return this.selectedOrderType && (this.selectedOrderType.title == 'Faulty');
  }

  isAllowedDiscontinued(): boolean {
    return this.selectedOrderType &&
      ([CalculationMethod.FREE, CalculationMethod.LOST].includes(this.selectedOrderType.calculationMethod));
  }

  get orderTypeTitle(): string {
    if (!this.selectedOrderType) {
      return '';
    }
    return this.selectedOrderType.title;
  }

  /**
   * Apply the rules the CRM uses to decide whether it will import the order
   * to determine if those steps are required
   */
  private updateImportIntoCrmProcessingSteps(): void {
    // rule that only applies up until "One Off Purchase" status is added
    const hasPullinOrderItems: boolean = this.items.some((item: BasketItem) => 
      (item.pullinOrders == 'yes')
    );
    // isExistingCustomer is the rule that is staying even after "One Off Purchase" is added
    if (!this.isExistingCustomer() && hasPullinOrderItems) {
      this.processingSteps['Importing into CRM'].required = true;
      this.processingSteps['Updating CRM order ref, processor and alarm user'].required = true;
      this.processingSteps[this.ORDER_STATUS_UPDATE_STEP].allowToBeSkipped = false;
    } else {
      this.processingSteps['Importing into CRM'].required = false;
      this.processingSteps['Updating CRM order ref, processor and alarm user'].required = false;
      this.processingSteps[this.ORDER_STATUS_UPDATE_STEP].allowToBeSkipped = true;
    }
  }
  
  private validationErrorsExist(): boolean {
    this.validationErrors = [];
    this.changeDetector.detectChanges();
    if (this.items.length === 0) {
      this.validationErrors.push('No Product was added to the basket');
    } else {
      const zeroQtyItems: BasketItem[] = this.items.filter((item: BasketItem) => 
        !item.quantity || (item.quantity <= 0)
      );
      if (zeroQtyItems.length > 0) {
        this.validationErrors.push('Products must have a quantity of 1 or more. Please remove any items not required.');
      }
    }
    if (this.infoBilling.firstName.trim() === '' || this.infoBilling.lastName.trim() === '' || this.infoBilling.mobile.trim() === '' ||
        this.infoBilling.userAddress.addressOne.trim() === '' || this.infoBilling.userAddress.city.trim() === '' ||
        this.infoBilling.userAddress.postcode.trim() === '') {
      this.validationErrors.push('Missing required Billing fields');
    }
    if ((this.infoBilling.email == null) || (this.infoBilling.email == undefined) || (this.infoBilling.email.trim() == '')) {
      this.woodata.generatedEmail = true;
      let emailStart: string = this.infoBilling.firstName+'.'+this.infoBilling.lastName+'.'+ Math.floor(Math.random() * 10000);
      emailStart = emailStart.replace(/[^A-Za-z0-9\.]/g, '_').replace(/\.\.+/g, '.');
      this.infoBilling.email = `${emailStart}@${this.emailSuffix}`;
    }
    this.infoBilling.email = this.infoBilling.email.trim();
    if ((!!this.infoBilling.email) && !isValidEmailAddress(this.infoBilling.email)) {
      this.validationErrors.push('Billing Email is invalid');
    }
    this.validateDifferentDelivery();
    if (!this.selectedOrderType) {
      this.validationErrors.push('Missing required Order Type');
    }
    if ((this.orderDetails.type == 'Phone Order') && !this.withheldNumber &&
        ((this.customerCalledFrom == '') || !isValidAnyCountryPhoneNumber(this.customerCalledFrom))) {
      this.validationErrors.push('Missing or Invalid required Customer Called From'); 
    }
    if (!this.orderDetails.paymentMethod || (this.orderDetails.paymentMethod.trim() === '')) {
      this.validationErrors.push('Missing required Payment Method');
    }
    if (this.isExistingCustomer()) {
      if (!this.tdCodeFound && !this.allowReplacementWithoutTd) {
        this.validationErrors.push(`You must enter an existing order's TD code or tick "No existing CRM Account" for ${this.orderTypeTitle} orders`);
      }
      if (this.lifetimeLineNeedsOverride) {
        this.validationErrors.push('You must override the price for all rental items on Lifetime orders as the CRM cannot calculate the values');
      }
      if (this.hasRentalItems && this.allowReplacementWithoutTd) {
        this.validationErrors.push(`You cannot add rental items unless you have provided a TD Code for ${this.orderTypeTitle} orders`);
      }
      if (this.isReplacementDueToFault() && (!this.fault || ((this.fault == 'Other') && !this.otherFault))) {
        this.validationErrors.push('You must enter details of the fault.');
      }
    } else {
      if (!this.isCouponValid()) {
        this.validationErrors.push('Coupon is invalid - please remove it');
      }
      this.validateAlarmUserDetails();
      if (!this.orderDetails.renewalMethod || (this.orderDetails.renewalMethod.trim() === '')) {
        this.validationErrors.push('Missing required Renewal Method field');
      }
      this.validateDirectDebitForm();
      if (!this.orderDetails.contactName || (this.orderDetails.contactName.trim() === '')) {
        this.validationErrors.push('Missing required Order Contact Name field');
      }
      if (['directDebit', 'goCardless', 'recurringBilling'].includes(this.orderDetails.renewalMethod)) {
        if (!this.orderDetails.renewalFrequency || (this.orderDetails.renewalFrequency.trim() === '')) {
          this.validationErrors.push('Missing required Renewal Frequency field');
        }
      }
      if (!this.orderDetails.NCFRequired || (this.orderDetails.NCFRequired.trim() === '')) {
        this.validationErrors.push('Missing required Paper NCF field');
      }
    }
    if (this.calculationError) {
      this.validationErrors.push(`Error calculating basket value: ${this.calculationError}`);
    }
    if (this.validationErrors.length > 0) {
      console.error('Validation errors found', this.validationErrors);
      return true;
    }
    this.prepareWooData();
    return false;
  }

  validateDifferentDelivery() {
    if (!this.differentDeliver) {
      return;
    }
    if (this.infoDeliver.firstName.trim() === '' || this.infoDeliver.lastName.trim() === '' ||
        this.infoDeliver.userAddress.addressOne.trim() === '' || this.infoDeliver.userAddress.city.trim() === '' ||
        this.infoDeliver.userAddress.postcode.trim() === '') {
      this.validationErrors.push('Missing required Delivery fields');
    }
  }

  validateAlarmUserDetails() {
    if (!this.alarmUserNameOption) {
      this.validationErrors.push("You must select an option for the Alarm User's name");
    } else if ((this.alarmUserNameOption == 'other') &&
        (!this.orderDetails.alarmUserLastName || (this.orderDetails.alarmUserLastName.trim() === '') ||
        !this.orderDetails.alarmUserFirstName || (this.orderDetails.alarmUserFirstName.trim() === ''))) {
      this.validationErrors.push("Missing required Alarm User's Name fields");
    }
    if (!this.alarmUserPhoneOption) {
      this.validationErrors.push("You must select an option for the Alarm User's phone number");
    } else if ((this.alarmUserPhoneOption == 'other') &&
        (!this.orderDetails.alarmUserPhone || (this.orderDetails.alarmUserPhone.trim() === '')) && 
        (!this.orderDetails.alarmUserMobile || (this.orderDetails.alarmUserMobile.trim() === ''))) {
      this.validationErrors.push("Missing required Alarm User's Phone number or Mobile");
    }
    if (!this.alarmUserAddressOption) {
      this.validationErrors.push("You must select an option for the Alarm User's address");
    } else if ((this.alarmUserAddressOption == 'other') &&
        ((!this.alarmUserAddress.addressOne || this.alarmUserAddress.addressOne.trim() === '') || (!this.alarmUserAddress.city || this.alarmUserAddress.city.trim() === '') ||
        (!this.alarmUserAddress.postcode || this.alarmUserAddress.postcode.trim() === ''))) {
      this.validationErrors.push("Missing required Alarm User's Address fields");
    }
  }

  validateDirectDebitForm() {
    if (this.orderDetails.renewalMethod != 'directDebit') {
      return;
    }
    if (!this.directDebit.sortCode || !this.directDebit.sortCode.trim()) {
      this.validationErrors.push('Missing Sort Code for Direct Debit');
    } else if (!SORT_REGEX.test(this.directDebit.sortCode)) {
      this.validationErrors.push('Invalid Sort Code for Direct Debit. It must be 6 digits exactly.');
    }
    if (!this.directDebit.accountNumber || !this.directDebit.accountNumber.trim()) {
      this.validationErrors.push('Missing Account Number for Direct Debit');
    } else if (!BANK_ACCOUNT_REGEX.test(this.directDebit.accountNumber)) {
      this.validationErrors.push('Invalid Account Number for Direct Debit. It must be 8 digits exactly.');
    }
    if (!this.directDebit.accountHolderName || !this.directDebit.accountHolderName.trim()) {
      this.validationErrors.push('Missing Account Holder Name for Direct Debit');
    }
    if (!this.directDebit.addressLineOne || !this.directDebit.addressLineOne.trim()) {
      this.validationErrors.push('Missing Address Line One for Direct Debit');
    }
    if (!this.directDebit.city || !this.directDebit.city.trim()) {
      this.validationErrors.push('Missing City for Direct Debit');
    }
    if (!this.directDebit.postcode || !this.directDebit.postcode.trim()) {
      this.validationErrors.push('Missing Post Code for Direct Debit');
    }
  }

  get validationErrorExists(): boolean {
    return this.validationErrors.length > 0;
  }

  refresh(): void {
    window.location.reload();
   }

   needsCustomerHeight(item: BasketItem) {
    // If the product or variation's plan code includes F it is a fall detector
    // However, if they also includes 1T or 1B it is a GPS unit, so we don't need the question
    if (this.itemPlanContainsCode(item, 'F') && !this.itemPlanContainsCode(item, '1B') &&
        !this.itemPlanContainsCode(item, '1T')) {
      return true;
    }
    return false;
  }

  needsWearingOption(item: BasketItem) {
    // If the product or variation's plan code includes F it is a fall detector
    // However, if they also includes 1T or 1B it is a GPS unit, so we don't need the question
    if (this.itemPlanContainsCode(item, 'F') && !this.itemPlanContainsCode(item, '1B') &&
        !this.itemPlanContainsCode(item, '1T')) {
      return true;
    }
    return false;
  }

  itemPlanContainsCode(item: BasketItem, code: string): boolean {
    return (item.planOrEquipment && item.planOrEquipment.includes(code))
      || (item.selectedVariation && item.selectedVariation.symbol && item.selectedVariation.symbol.includes(code));
  }

  manuallyCompleteStep(event: Event) {
    this.processingSteps[this.currentStep].completed = (event.target as HTMLInputElement).checked;
  }

  get isPlaceOrderDisabled(): boolean {
    const tmpItems: BasketItem[] = this.items.filter((item: BasketItem) => 
      item.quantity && (item.quantity > 0)
    );
    if (tmpItems.length == 0) {
      return true;
    }
    if (!this.orderDetails.paymentMethod || (this.orderDetails.paymentMethod.trim() == '')) {
      return true;
    }
    if ((this.orderDetails.paymentMethod == 'Secured Debit/Credit Card') &&
        (!this.nameOnCard || (this.nameOnCard.trim() == ''))) {
      return true;
    }
    if (isNaN(this.TTorder) || (this.TTorder < 0)) {
      return true;
    }
    if (this.overrideTotal) {
      if (!this.overrideReason || ((this.overrideReason == 'Other') && !this.otherOverrideReason)) {
        return true;
      }
    }
    if (this.isReplacementWithoutTdAllowed()) {
      if (!this.allowReplacementWithoutTd && !this.tdCodeFound) {
        return true;
      }
    } else if (this.isExistingCustomer() && !this.tdCodeFound) {
      return true;
    }
    return false;
  }

  changeHowHeard(): void {
    if (this.howHeard != 'Partnership') {
      this.selectedPartnership = undefined;
    }
  }

  changePartnership(): void {
    if (this.couponCode || !this.selectedPartnership) {
      return;
    }
    const couponCode: string = this.selectedPartnership.bgcCouponCode;
    if (couponCode) {
      this.confirmationService.confirm({
        'key': 'general',
        'header': 'Partnership Coupon',
        'message': 
          `${this.selectedPartnership.bgcName} has a coupon code ${couponCode} configured. Would you like to apply it now?`,
        'rejectVisible': true,
        'acceptLabel': 'Yes',
        'rejectLabel': 'No',
        'icon': 'pi pi-question-circle',
        'accept': () => {
          this.couponCode = couponCode;
          this.searchCoupon();
        },
        'reject': () => {}
      });
    }
  }

  emailBlur(): void {
    if (this.infoBilling.email) {
      this.orderDetails.NCFRequired = 'No';
    }
  }

  changeOrderType() {
    let oldOrderType: OrderType|undefined;
    if (this.orderDetails.type) {
      oldOrderType = this.orderTypes.find((orderType: SelectItem<OrderType>) =>
        orderType.value.title == this.orderDetails.type
      ).value;
    }
    this.orderDetails.type = this.selectedOrderType.title;
    this.allowReplacementWithoutTd = false;
    if (this.selectedOrderType.isNewOrder) {
      // Only clear values if it has changed from being a new order
      if (!oldOrderType || !oldOrderType.isNewOrder) {
        this.clearFields(true);
        this.searchTDCode = '';
        this.tdCodeFound = false;      
        this.tdCodeSearched = false;
        this.order = undefined;
        this.orderDateYMD = '';
        this.recentOrder = false;
        this.proRataMultiplier = 0;
        this.proRataToDate = '';
        this.nextRenewalDate = '';
        this.crmRenewalDate = '';
        this.proRataCurrentPrice = 0;
      }
    } else {
      this.clearFields(false);
      // Only clear values if it was a new order before
      if (!oldOrderType || oldOrderType.isNewOrder) {
        this.alarmUserNameOption = 'other';
        this.orderDetails.alarmUserFirstName = '';
        this.orderDetails.alarmUserLastName = '';
        this.alarmUserPhoneOption = 'other';
        this.orderDetails.alarmUserMobile = '';
        this.orderDetails.alarmUserPhone = '';
        this.alarmUserAddressOption = 'other';
        this.alarmUserAddress = {
          addressOne: '',
          addressTwo: '',
          city: '',
          county: '',
          postcode: '',
          validated: false,
        };
        this.customerCalledFrom = '';
        this.withheldNumber = false;
        this.orderDetails.renewalMethod = '';
        this.directDebit = {};
        this.orderDetails.contactName = '';
        this.orderDetails.renewalFrequency = '';
        this.orderDetails.NCFRequired = 'No';
        this.emailMarketing = '';
        this.phoneMarketing = '';
      }
      // Different types have different calculation methods, so recalculate
      this.priceupdate();
    }
  }

  calculateRenewalDateAndProrataMultiplier() {
    if (this.planType == 'lifetime') {
      if (!this.isAllowedDiscontinued()) {
        this.showErrorPopUp('Lifetime Calculations',
          'Charges cannot be calculated for rental equipment for Lifetime orders, you will need to set the prices manually.'
        );
      }
      return;
    }
    // If this is > 0 it has already been calculated
    if (this.proRataMultiplier > 0) {
      return;
    }
    const renewalDateDetails: RenewalDateDetails = getRenewalDateDetails(this.order);
    let futureRenewalDetails: FutureRenewalDetails;
    if (renewalDateDetails.crm) {
      futureRenewalDetails = renewalDateDetails.crm;
    } else {
      futureRenewalDetails = renewalDateDetails.calculated;
    }
    // Use first future rather than first adjustable as will add whole periods for those skipped
    // to avoid slight discrepancy that would be caused by periods having different number of days
    const daysToFirstFutureRenewal: number = futureRenewalDetails.firstFutureRenewal.diff(this.currentMoment, 'days');
    switch (this.order.accountDetails.planType) {
      case 'monthly':
        this.proRataMultiplier = daysToFirstFutureRenewal / 30;
        break;
      case 'quarterly':
        this.proRataMultiplier = daysToFirstFutureRenewal / 91;
        break;
      case 'annual':
        this.proRataMultiplier = daysToFirstFutureRenewal / 365;
        break;
    }
    this.proRataMultiplier += futureRenewalDetails.renewalsBeforeAjustable;
    this.proRataToDate = futureRenewalDetails.firstAdjustableRenewal.format('DD/MM/YYYY');
    this.nextRenewalDate = futureRenewalDetails.firstFutureRenewal.format('DD/MM/YYYY');
    if (renewalDateDetails.crmStoredNextRenewal) {
      this.crmRenewalDate = renewalDateDetails.crmStoredNextRenewal.format('DD/MM/YYYY');
    }
    if (PRICE_REGEX.test(this.order.renewalInformation.renewalPrice)) {
      this.proRataCurrentPrice = this.roundToTwoDecimalPlaces(Number(this.order.renewalInformation.renewalPrice) * this.proRataMultiplier);
    }
  }

  showInfoPopUp(header: string, message: string): void {
    this.showPopUp('general', header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('error', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
