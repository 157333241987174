import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {parsePhoneNumberFromString, PhoneNumber} from 'libphonenumber-js/max';

function parsePhoneNumber(numberToParse: string): PhoneNumber {
  if (!numberToParse) {
    return null;
  }
  numberToParse = numberToParse.trim();
  if (numberToParse.length === 0) {
    return null;
  } else if (numberToParse.startsWith('00')) {
    // need to replace the 00 with +
    numberToParse = '+' + numberToParse.substring(2);
  } else if (!numberToParse.startsWith('0')) {
    // If it's a single zero then it's domestic format
    // This tell the validator we are dealing with an international number
    numberToParse = '+' + numberToParse;
  }
  const phoneNumber: PhoneNumber = parsePhoneNumberFromString(numberToParse, 'GB');
  return phoneNumber;
}

function isValidAnyCountryPhoneNumber(numberToCheck: string): boolean {
  if (numberToCheck.match(/\s/)) {
    return false;
  }
  const phoneNumber: PhoneNumber = parsePhoneNumber(numberToCheck);
  if (!phoneNumber || !phoneNumber.isValid()) {
    return false;
  }
  return true;
}

function isValidUkMobile(numberToCheck: string): boolean {
  if (numberToCheck.match(/\s/)) {
    return false;
  }
  const phoneNumber: PhoneNumber = parsePhoneNumber(numberToCheck);
  if (!phoneNumber || !phoneNumber.isValid() || (phoneNumber.getType() !== 'MOBILE') ||
      (phoneNumber.country !== 'GB')) {
    return false;
  }
  return true;
}

function isValidAnyCountryMobile(numberToCheck: string): boolean {
  if (numberToCheck.match(/\s/)) {
    return false;
  }
  const phoneNumber: PhoneNumber = parsePhoneNumber(numberToCheck);
  if (!phoneNumber || !phoneNumber.isValid() || (phoneNumber.getType() !== 'MOBILE')) {
    return false;
  }
  return true;
}

const phoneNumberValidator: ValidatorFn = (phoneField: AbstractControl): ValidationErrors|null => {
  if (!phoneField.value || ((phoneField.value as string).trim().length === 0)) {
    return null;
  }
  if ((phoneField.value as string).match(/\s/)) {
    return {'phoneNumberValid': phoneField.value};
  }
  const phoneNumber: PhoneNumber = parsePhoneNumber(phoneField.value as string);
  if (!phoneNumber || !phoneNumber.isValid()) {
    return {'phoneNumberValid': phoneField.value};
  }
  return null;
}

const mobileNumberValidator: ValidatorFn = (phoneField: AbstractControl): ValidationErrors|null => {
  if (!phoneField.value || ((phoneField.value as string).trim().length === 0)) {
    return null;
  }
  if ((phoneField.value as string).match(/\s/)) {
    return {'mobileNumberValid': phoneField.value};
  }
  const phoneNumber: PhoneNumber = parsePhoneNumber(phoneField.value as string);
  if (!phoneNumber || !phoneNumber.isValid() || (phoneNumber.getType() !== "MOBILE")) {
    return {'mobileNumberValid': phoneField.value};
  }
  return null;
}

const ukMobileNumberValidator: ValidatorFn = (phoneField: AbstractControl): ValidationErrors|null => {
  if (!phoneField.value || ((phoneField.value as string).trim().length === 0)) {
    return null;
  }
  if ((phoneField.value as string).match(/\s/)) {
    return {'ukMobileNumberValid': phoneField.value};
  }
  if (!isValidUkMobile(phoneField.value as string)) {
    return {'ukMobileNumberValid': phoneField.value};
  }
  return null;
}

export {
  isValidAnyCountryPhoneNumber,
  isValidUkMobile,
  isValidAnyCountryMobile,
  phoneNumberValidator,
  mobileNumberValidator,
  ukMobileNumberValidator,
};
