import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Announcement} from '../../models/notifications/announcements';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
  API_URL = environment.protocol + environment.IPAddress + '/api';

  constructor(
    private http: HttpClient,
  ) { }

  getRecentAnnouncements(): Observable<MultiRecordResponse<Announcement>> {
    return this.http.get<MultiRecordResponse<Announcement>>(`${this.API_URL}/announcement/most-recent`);
  }

  createNewAnnouncement(announcement: Announcement): Observable<SingleRecordResponse<Announcement>> {
    return this.http.post<SingleRecordResponse<Announcement>>(`${this.API_URL}/announcement/`, announcement);
  }
}
