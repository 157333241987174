const DIGITS: string[] = [
  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
];

function numberOnly(event: KeyboardEvent, allowDecimals: boolean): boolean {
  if (!DIGITS.includes(event.key) && !(allowDecimals && (event.key == '.'))) {
    return false;
  }
  return true;
}

function wasCtrlPlusEnterPressed(event: KeyboardEvent): boolean {
  return ['NumpadEnter', 'Enter'].includes(event.code) && event.ctrlKey;
}

export {
  numberOnly,
  wasCtrlPlusEnterPressed,
};
