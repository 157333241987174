import { Component, OnInit, ViewChild } from '@angular/core';
import {Column} from '../../models/column.model';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {BrandCfg, getBrandConfigs, getBrandSelectItems} from '../../lookups/brands';
import {Title} from '@angular/platform-browser';
import {CseOrder, CseOrderItem} from '../../models/reporting/cseOrder.model';
import {cseLineCols, cseOrderCols} from '../../lookups/reporting/cseOrderReportColumns';
import {Table} from 'primeng/table';
import moment from 'moment-timezone';
import {OrderService} from '../../post-order/order.service';
import {PageCountResponse} from '../../models/responses/pageCountResponse.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';

import {Website} from '../../models/website.model';
import {ExcelExportService} from '../../post-order/excel-export.service';
import {MultiSelectChangeEvent} from '../../models/primeng/multiSelectChangeEvent.model';

const MAX_PARALLEL: number = 5;
const PAGE_RETRIES: number = 3;

@Component({
  selector: 'app-cse-order-report',
  templateUrl: './cse-order-report.component.html',
  styleUrls: ['./cse-order-report.component.scss'],
  providers: [ConfirmationService]
})
export class CseOrderReportComponent implements OnInit {
  orderCols: Column[];
  lineCols: Column[];
  selectedOrderCols: Column[];
  selectedLineCols: Column[];
  minDateStr: string;
  maxDateStr: string;
  defaultStartDateStr: string;
  recordsLoading: boolean = false;
  recordPagesToLoad: number = 0;
  filterMinDate: Date;
  filterMaxDate: Date;
  filterYearRange: string;
  dateFilters: Date[];
  brands: SelectItem<string>[];
  brandConfigs: BrandCfg;
  selectedBrand: string[];
  cseOrderRecords: CseOrder[];
  @ViewChild('cseOrderTable', {static: false})
  cseOrderTable: Table;
  pagesProcessing: number;

  constructor(
    private title: Title,
    private orderService: OrderService,
    private confirmationService: ConfirmationService,
    private excelService: ExcelExportService,
  ) { }

  ngOnInit(): void {
    this.brandConfigs = getBrandConfigs();
    this.brands = getBrandSelectItems();
    this.title.setTitle('CSE Order Report');
    this.cseOrderRecords = [];
    this.orderCols = cseOrderCols;
    this.lineCols = cseLineCols;
    this.selectedOrderCols = this.orderCols.filter((col: Column) => col.hide != true);
    this.selectedLineCols = this.lineCols.filter((col: Column) => col.hide != true);
    // Shortly before data started being captured
    this.minDateStr = '2024-04-01';
    this.defaultStartDateStr = moment.tz('Europe/London').subtract(1, 'months').format('YYYY-MM-DD');
    this.maxDateStr = moment.tz('Europe/London').format('YYYY-MM-DD');
  }

  loadOrderRecords(startDate: string, endDate: string) {
    this.recordsLoading = true;
    this.cseOrderRecords = [];
    this.recordPagesToLoad = 0;
    this.filterMinDate = new Date(startDate);
    this.filterMaxDate = new Date(endDate);
    this.filterYearRange = `${this.filterMinDate.getFullYear()}:${this.filterMaxDate.getFullYear()}`;
    this.orderService.getCseOrderReportPageCount(startDate, endDate).subscribe(
      async (response: PageCountResponse) => {
        if (!response.success) {
          this.recordsLoading = false;
          this.showErrorPopUp('Error getting page count', response.message);
        } else {
          this.pagesProcessing = 0;
          this.recordPagesToLoad = response.pageCount!;
          if (this.recordPagesToLoad === 0) {
            this.recordsLoading = false;
          } else {
            for (let page: number = 1; page <= response.pageCount!; page++) {
              // Limit the max number of pages being updated in parallel which allows other work to complete
              // whilst pages are still loading and allows abort if page navigated away from
              while (this.pagesProcessing >= MAX_PARALLEL) {
                await this.delay(250);
              }
              this.pagesProcessing++;
              this.loadOrderRecordPage(startDate, endDate, page, PAGE_RETRIES);
            }
          }
        }
      }
    );
  }

  loadOrderRecordPage(startDate: string, endDate: string, page: number, retryCount: number) {
    this.orderService
      .getCseOrderReportPage(startDate, endDate, page)
      .subscribe((response: MultiRecordResponse<CseOrder>) => {
        if (!response.success || !response.data) {
          console.log(`Error loading page ${page}. Error: ${response.message}`);
          if (retryCount > 0) {
            this.loadOrderRecordPage(startDate, endDate, page, retryCount - 1);
            return;
          }
          // Only decrement count if we are not retrying page
          this.recordPagesToLoad--;
          this.pagesProcessing--;
          this.showErrorPopUp('Error loading order records', `Something went wrong try again. Error: ${response.message}`);
          return;
        }
        this.cseOrderRecords = this.cseOrderRecords.concat(response.data);
        this.recordPagesToLoad--;
        this.pagesProcessing--;
        if (this.recordPagesToLoad === 0) {
          this.recordsLoading = false;
        }
      }, 
      (err: any) => {
        console.log(`Error loading page ${page}. Error ${err.message}`);
        if (retryCount > 0) {
          this.loadOrderRecordPage(startDate, endDate, page, retryCount - 1);
          return;
        }
        // Only decrement count if we are not retrying page
        this.recordPagesToLoad--;
        this.pagesProcessing--;
        this.showErrorPopUp('Error loading order records', `Something went wrong try again. Error: ${err.message}`);
      });
  }

  applyFilter($event: Event, field: string, filterType: string): void {
    this.cseOrderTable.filter(($event.target as HTMLInputElement).value, field, filterType);
  }

  delay(ms: number): Promise<void> {
    return new Promise((resolve): void => {
      setTimeout(resolve, ms);
    });
  }

  showInfoPopUp(header: string, message: string): void {
    this.showPopUp('general', header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('error', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }

  orderColumnsChanged(event: MultiSelectChangeEvent<Column>) {
    const orderDateExists: boolean = event.value.some((col: Column) => col.field == 'createdAt');
    const brandExists: boolean = event.value.some((col: Column) => col.field == 'websiteId');
    const websiteIdExists: boolean = event.value.some((col: Column) => col.field == 'websiteOrderId');
    // Have to push the current column definition, else it doesn't work correctly
    if (!orderDateExists) {
      this.selectedOrderCols.push(this.orderCols.find((defaultCfg: Column) => defaultCfg.field == 'createdAt'));
    }
    if (!brandExists) {
      this.selectedOrderCols.push(this.orderCols.find((defaultCfg: Column) => defaultCfg.field == 'websiteId'));
    }
    if (!websiteIdExists) {
      this.selectedOrderCols.push(this.orderCols.find((defaultCfg: Column) => defaultCfg.field == 'websiteOrderId'));
    }
    this.selectedOrderCols.sort((a: Column, b: Column) => a.order - b.order);
  }

  lineColumnsChanged(event: MultiSelectChangeEvent<Column>) {
    const orderLineExists: boolean = event.value.some((col: Column) => col.field == 'cseOrderLine');
    const orderLinePartExists: boolean = event.value.some((col: Column) => col.field == 'cseOrderLinePart');
    const titleExists: boolean = event.value.some((col: Column) => col.field == 'cseOrderTitle');
    // Have to push the current column definition, else it doesn't work correctly
    if (!orderLineExists) {
      this.selectedLineCols.push(this.lineCols.find((defaultCfg: Column) => defaultCfg.field == 'cseOrderLine'));
    }
    if (!orderLinePartExists) {
      this.selectedLineCols.push(this.lineCols.find((defaultCfg: Column) => defaultCfg.field == 'cseOrderLinePart'));
    }
    if (!titleExists) {
      this.selectedLineCols.push(this.lineCols.find((defaultCfg: Column) => defaultCfg.field == 'cseOrderTitle'));
    }
    this.selectedLineCols.sort((a: Column, b: Column) => a.order - b.order);
  }

  exportCseOrders() {
    let recordsToExport: CseOrder[] = this.cseOrderTable.filteredValue? this.cseOrderTable.filteredValue: this.cseOrderRecords;
    const ordersFormattedForExport: any[] = recordsToExport.map((cseOrder: CseOrder) => {
      let formattedOrderRecord: any = {};
      this.selectedOrderCols.forEach((col: Column) => {
        switch (col.field) {
          case 'websiteId':
            formattedOrderRecord[col.header] = (cseOrder.websiteId as Website).title;
            break;
          case 'proRataToDate':
            formattedOrderRecord[col.header] = moment.tz(cseOrder.proRataToDate, 'DD/MM/YYYY', 'Europe/London').toDate();
            break;
          case 'createdAt':
            formattedOrderRecord[col.header] = moment.tz(cseOrder.createdAt, 'Europe/London').toDate();
            break;
          default:
            formattedOrderRecord[col.header] = cseOrder[col.field];
            break;
        }
      });
      return formattedOrderRecord;
    });
    this.excelService.exportAsExcelFile({'CSE Orders': ordersFormattedForExport}, 'CSE_Orders');
  }

  exportCseOrderLines() {
    let recordsToExport: CseOrder[] = this.cseOrderTable.filteredValue? this.cseOrderTable.filteredValue: this.cseOrderRecords;
    const orderLinesFormattedForExport: any[]  = [];
    recordsToExport.forEach((cseOrder: CseOrder) => {
      let formattedOrderRecord: any = {};
      this.selectedOrderCols.forEach((col: Column) => {
        switch (col.field) {
          case 'websiteId':
            formattedOrderRecord[col.header] = (cseOrder.websiteId as Website).title;
            break;
          case 'proRataToDate':
            formattedOrderRecord[col.header] = moment.tz(cseOrder.proRataToDate, 'DD/MM/YYYY', 'Europe/London').toDate();
            break;
          case 'createdAt':
            formattedOrderRecord[col.header] = moment.tz(cseOrder.createdAt, 'Europe/London').toDate();
            break;
          default:
            formattedOrderRecord[col.header] = cseOrder[col.field];
            break;
        }
      });
      cseOrder.cseOrderItems.forEach((orderItem: CseOrderItem) => {
        let formattedOrderLineRecord: any = Object.assign({}, formattedOrderRecord);
        this.selectedLineCols.forEach((col: Column) => {
          formattedOrderLineRecord[col.header] = orderItem[col.field];
        });
        orderLinesFormattedForExport.push(formattedOrderLineRecord);
      })
    });
    this.excelService.exportAsExcelFile({'CSE Order Lines': orderLinesFormattedForExport}, 'CSE_Order_Lines');
  }
}
