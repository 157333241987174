import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CouponsService {
  private API_URL: string = environment.protocol+environment.IPAddress+'/api';

  constructor(private http: HttpClient) {
  }

  getCoupons(): Observable<any[]> {
    return this.http.get<any[]>(`${this.API_URL}/coupons/`);
  }
  
  getCouponsByWebsite(id: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.API_URL}/coupons/${id}/websites`);
  }

  getCoupon(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_URL}/coupons/${id}`);
  }

}
