import moment from 'moment-timezone';
import {SelectItem, SortEvent} from 'primeng/api';
import {WorkSheet, utils, Range, CellObject, WSKeys,} from 'xlsx';
import {User} from '../models/user.model';
import { v4 as uuidv4 } from 'uuid';
import {ObjectWithContactFlags} from '../models/function-params/objectWithContactFlags.model';

const VALID_ALARM_CODE: RegExp = /^(TD|SC|HP|GY)\d+$/;

function sortByLabel(arrayToSort: SelectItem[]): SelectItem[] {
  return arrayToSort.sort((a, b) => {
    if (!a.label || !b.label) {
      return 0;
    }
    const fa = a.label.toLowerCase();
    const fb = b.label.toLowerCase();
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
}

function filteredUsersByRole(users: User[]): User[] {
  return users.filter((user: User) => {
    return (user.roles.length > 0);
  });
}

function escapeForRegExp(stringToEscape: string): string {
  return stringToEscape.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function getErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  }
  return String(error);
}

function getWorkingDaysBetween(startDate: string|Date, endDate: string|Date): number {
  const startMoment: moment.Moment = moment.tz(startDate, 'Europe/London').startOf('day');
  const endMoment: moment.Moment = moment.tz(endDate, 'Europe/London').startOf('day');
  if (startMoment.isAfter(endMoment)) {
    return 0;
  }
  let workingDays: number = 0;
  while (startMoment.isBefore(endMoment)) {
    if (![6, 7].includes(startMoment.isoWeekday())) {
      workingDays++;
    }
    startMoment.add(1, 'day');
  }
  return workingDays;
}

function getDaysBetween(startDate: string|Date, endDate: string|Date): number {
  const startMoment: moment.Moment = moment.tz(startDate, 'Europe/London').startOf('day');
  const endMoment: moment.Moment = moment.tz(endDate, 'Europe/London').startOf('day');
  if (startMoment.isAfter(endMoment)) {
    return 0;
  }
  let days: number = 0;
  while (startMoment.isBefore(endMoment)) {
    days++;
    startMoment.add(1, 'day');
  }
  return days;
}

function doPlanCodeAndTypeMatch(planCode: string, planType: string): boolean {
  // The plan code contains more than one frequency indicator, so it cannot match the plan type
  if ((/\b(MON)?M1/.test(planCode) && /\b(MON)?[AL]1/.test(planCode)) ||
      (/\b(MON)?A1/.test(planCode) && /\b(MON)?[ML]1/.test(planCode)) ||
      (/\b(MON)?L1/.test(planCode) && /\b(MON)?[MA]1/.test(planCode))) {
    return false;
  }
  switch (planType) {
    case 'monthly':
    case 'quarterly':
      if (!/\b(MON)?M1/.test(planCode)) {
        return false;
      }
      break;
    case 'annual':
      if (!/\b(MON)?A1/.test(planCode)) {
        return false;
      }
      break;
    case 'lifetime':
      if (!/\b(MON)?L1/.test(planCode)) {
        return false;
      }
      break;
  }
  return true;
}

function doPlanCodeAndVatStatusMatch(planCode: string, vatStatus: string): boolean {
  switch (vatStatus) {
    case 'not exempt':
      return planCode.includes('V');
    case 'exempt':
      return !planCode.includes('V');
    // blank VAT status, must be incorrect
    default:
      return false;
  }
}

function clearLocalStorage(): void {
  let deviceId = localStorage.getItem('deviceId');
  if (!deviceId) {
    deviceId = uuidv4();
  }
  localStorage.clear()
  localStorage.setItem('deviceId', deviceId);
}

async function getBase64EncodedFileContents(file: File): Promise<string> {
  return new Promise<string>((resolve) => {
    const fileReader: FileReader = new FileReader();
    fileReader.onload = (ev: ProgressEvent<FileReader>) => {
      let encoded: string = (ev.target.result as string).replace(/^data:(.*,)?/, '');
      if ((encoded.length % 4) > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    }
    fileReader.readAsDataURL(file);
  });
}

/**
 * Get the headers i.e. the cell text from the first row in the sheet
 * @param {WorkSheet} sheet the worksheet to get the headers for
 * @return {string[]} the text from the cells in the first row
 */
function getSheetHeaders(sheet: WorkSheet): string[] {
  const headers: string[] = [];
  const rangeAsString: string|undefined = sheet['!ref'];
  if (!rangeAsString) {
    return headers;
  }
  const range: Range = utils.decode_range(rangeAsString);
  /* start in the first row */
  const firstRowNum: number = range.s.r;
  /* walk every column in the range */
  for (let colNum: number = range.s.c; colNum <= range.e.c; colNum++) {
    /* find the cell in the first row */
    const cell: CellObject | WSKeys | any = sheet[utils.encode_cell({c: colNum, r: firstRowNum})];
    let hdr: string = '';
    if (!!cell && !!cell.t) {
      hdr = utils.format_cell(cell);
    }

    headers.push(hdr);
  }
  return headers;
}

/**
 * Check the sheet at least contains columns with the names provided
 * @param {string[]} columnNamesToCheck the array of column names to look for
 * @param {WorkSheet} sheet the worksheet to check
 * @return {boolean} whether all columns found or not
 */
function doesSheetContainColumnsNamed(columnNamesToCheck: string[], sheet: WorkSheet): boolean {
  const columnsInSheet: string[] = getSheetHeaders(sheet);
  for (let i = 0; i < columnNamesToCheck.length; i++) {
    const columnName: string = columnNamesToCheck[i];
    if (!columnsInSheet.includes(columnName)) {
      return false;
    }
  }
  return true;
}

/**
 * Check whether the passed in string is a valid representation of an object id
 * @param {string} objectId the value to check
 * @return {boolean} whether the value is syntactically correct to be an object id
 */
function isValidObjectId(objectId: string): boolean {
  return (!!objectId && /^[a-f0-9]{24}$/.test(objectId));
}

function getContactFlags(contact: ObjectWithContactFlags): string {
  let label: string = '';
  if (contact.primaryContact) {
    label = '*** PRIMARY CONTACT ***';
  }
  if (contact.doNotCall) {
    label += '*** DO NOT CALL ***';
  }
  return label;
}

function subfieldSort(event: SortEvent) {
  event.data.sort((row1: any, row2: any): number => {
    let result: number = 0;
    let value1 = row1;
    let value2 = row2;
    if (event.field.includes('.')) {
      const subfields: string[] = event.field.split('.');
      subfields.forEach((subfield: string) => {
        value1 = value1? value1[subfield]: '';
        value2 = value2? value2[subfield]: '';
      });
    } else {
      value1 = row1[event.field];
      value2 = row2[event.field];
    }
    if (value1 == null && value2 != null) {
      result = -1;
    } else if (value1 != null && value2 == null) {
      result = 1;
    } else if (value1 == null && value2 == null) {
      result = 0;
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
    } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }
    return result * event.order;
  });
}

function selectItemSort(selectItem: SelectItem[]) {
  selectItem.sort((a: any, b: any): number => {
    if (a.disabled && !b.disabled) {
      return 1;
    }
    if (!a.disabled && b.disabled) {
      return -1;
    }
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
}

function getSubfieldData(data: any, fieldName: string): any {
  if (!fieldName.includes('.')) {
    return data[fieldName];
  }
  const fieldNames: string[] = fieldName.split('.');
  let fieldData: any = data;
  fieldNames.forEach((subfield: string) => {
    fieldData = fieldData? fieldData[subfield]: '';
  });
  return fieldData;
}

export {
  VALID_ALARM_CODE,
  sortByLabel,
  selectItemSort,
  filteredUsersByRole,
  escapeForRegExp,
  getErrorMessage,
  getWorkingDaysBetween,
  doPlanCodeAndTypeMatch,
  doPlanCodeAndVatStatusMatch,
  getBase64EncodedFileContents,
  doesSheetContainColumnsNamed,
  isValidObjectId,
  clearLocalStorage,
  getContactFlags,
  subfieldSort,
  getSubfieldData,
  getDaysBetween,
}
