import {SelectItem} from 'primeng/api';
import {sortByLabel} from '../helpers/helperFunctions';
import {PaymentDetails} from '../models/paymentDetails.model';

interface EquipmentSerialLookup {
  'description': string;
  'comment'?: string;
  'serialRegex': string;
  'prices': {[brandCode: string]: number};
  'manualFile': string;
}

interface BrandOrTypeSelectItem extends SelectItem<string> {
  'brandOrTypeRegex'?: string;
  'manufacturedYearRegex'?: string;
  'yearFirst'?: boolean;
  'weekOrMonth'?: string;
  'manualFile'?: string;
}

let equipmentSerialLookups: EquipmentSerialLookup[];
let hardwareSerialLookups: BrandOrTypeSelectItem[];
const HARDWARE_LOOKUP_KEY_PREFIX: string = 'hardware: brands/types for plan code ';
const PHONE_NUM_MATCH: RegExp = /\b447\d{9}\b/;

function getEquipmentSerialLookup(): EquipmentSerialLookup[] {
  if (!equipmentSerialLookups) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('equipment: serial lookup')) {
      return [];
    }
    equipmentSerialLookups = JSON.parse(localStorage.getItem('equipment: serial lookup')) || [];
  }
  return equipmentSerialLookups;
}

function getEquipmentPriceAndDescription(serial: string, brandCode: string, addVat: boolean): PaymentDetails {
  const paymentDetails: PaymentDetails = {
    'paymentFor': '',
    'paymentAmount': '',
  };
  if (!serial) {
    return paymentDetails;
  }
  const equipLookups: EquipmentSerialLookup[] = getEquipmentSerialLookup();
  const upperSerial: string = serial.toLocaleUpperCase();
  const matchingLookup: EquipmentSerialLookup = equipLookups.find((equipLookup: EquipmentSerialLookup) => {
    const serialRegex: RegExp = new RegExp(equipLookup.serialRegex);
    return (serialRegex.test(upperSerial));
  });
  if (matchingLookup && matchingLookup.prices[brandCode]) {
    paymentDetails.paymentFor = matchingLookup.description;
    let amount: number = Number(matchingLookup.prices[brandCode]);
    if (addVat) {
      amount *= 1.2;
    }
    paymentDetails.paymentAmount = amount.toFixed(2);
  }
  return paymentDetails;
}

function getEquipmentManualFilename(serial: string): string {
  if (!serial) {
    return '';
  }
  const equipLookups: EquipmentSerialLookup[] = getEquipmentSerialLookup();
  const upperSerial: string = serial.toLocaleUpperCase();
  const matchingLookup: EquipmentSerialLookup = equipLookups.find((equipLookup: EquipmentSerialLookup) => {
    const serialRegex: RegExp = new RegExp(equipLookup.serialRegex);
    return (serialRegex.test(upperSerial));
  });
  if (matchingLookup) {
    return matchingLookup.manualFile;
  }
  return '';
}

function getHardwareSerialLookups(): BrandOrTypeSelectItem[] {
  if (!hardwareSerialLookups) {
    const tmpHardwareSerialLookups: BrandOrTypeSelectItem[] = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key: string = localStorage.key(i);
      if (key.startsWith(HARDWARE_LOOKUP_KEY_PREFIX)) {
        const brandSelects: BrandOrTypeSelectItem[] = JSON.parse(localStorage.getItem(key))
        brandSelects.forEach((brandOrType: BrandOrTypeSelectItem) => {
          if (brandOrType.brandOrTypeRegex) {
            tmpHardwareSerialLookups.push(brandOrType);
          }
        });
      }
    }
    if (tmpHardwareSerialLookups.length > 0) {
      hardwareSerialLookups = tmpHardwareSerialLookups;
    }
    return tmpHardwareSerialLookups;
  }
  return hardwareSerialLookups;
}

function getEquipmentName(serial: string): string {
  if (!serial) {
    return '';
  }
  if (PHONE_NUM_MATCH.test(serial)) {
    return 'Pebbell/GO - Unknown';
  }
  const serialLookups: BrandOrTypeSelectItem[] = getHardwareSerialLookups();
  const matchingSelectItem: BrandOrTypeSelectItem = serialLookups.find((serialLookup: BrandOrTypeSelectItem) => {
    const serialRegex: RegExp = new RegExp(serialLookup.brandOrTypeRegex, 'i');
    return (serialRegex.test(serial));
  });
  if (matchingSelectItem) {
    return matchingSelectItem.label;
  }
  return '';
}

function getManualLookups(): SelectItem<string>[] {
  const manualLookups: SelectItem<string>[] = [];
  const equipLookups: EquipmentSerialLookup[] = getEquipmentSerialLookup();
  equipLookups.forEach((equipLookup: EquipmentSerialLookup) => {
    if (equipLookup.manualFile) {
      // Descriptions tend to be customer facing and don't necessarily distinguish between brands of accessory
      // Comments provide precise detail for internal use
      manualLookups.push({
        'label': equipLookup.comment? equipLookup.comment: equipLookup.description,
        'value': equipLookup.manualFile
      });
    }
  });
  return sortByLabel(manualLookups);
}

export {
  getEquipmentPriceAndDescription,
  getEquipmentManualFilename,
  getManualLookups,
  getEquipmentName,
}