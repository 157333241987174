import {Component, OnInit} from '@angular/core';
import {WebsiteService} from "../websites/website.service";
import {ActivatedRoute, Params} from "@angular/router";
import {CouponsService} from "./coupons.service";
import {WebsitesResponse} from '../../models/responses/websitesResponse.model';
import {Website} from '../../models/website.model';
import {Title} from '@angular/platform-browser';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {ConfirmationService} from 'primeng/api';
import {getErrorMessage, isValidObjectId} from '../../helpers/helperFunctions';
import {Coupon} from '../../models/coupon.model';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss'],
  providers: [ConfirmationService]
})
export class CouponsComponent implements OnInit {
  websites: Website[];
  webId: string;
  coupons: Coupon[];

  constructor(
    private websiteService: WebsiteService,
    private couponsService: CouponsService,
    private route: ActivatedRoute,
    private title: Title,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit() {
    this.websiteService
      .getWebsites()
      .subscribe((websites: WebsitesResponse) => {
        this.websites = websites.websites;
      }, err => {
        console.log(err);
      });
    this.route.params.subscribe((params: Params) => {
      this.webId = params.id;
      if (!!this.webId && isValidObjectId(this.webId)) {
        this.couponsService.getCouponsByWebsite(this.webId).subscribe(couponResponse => {
          this.coupons = couponResponse['coupons'];
          console.log(this.coupons)

        }, err => {
          console.log(err);
        });
      }
    });
    this.title.setTitle('CRM Coupons');
  }

  updateCoupons(id: string) {
    this.websiteService
      .updateCouponsWebsite(id)
      .subscribe((response: SimpleResponse) => {
        if (response.success) {
          this.showInfoPopUp('Refreshing Coupons...', response.message);
        } else {
          this.showErrorPopUp('Error Refreshing Coupons', 
            'There was an error refreshing coupons, please try again. Error: ' + 
            response.message);
        }
      }, err => {
        this.showErrorPopUp('Error Refreshing Coupons', 
          'There was an error refreshing coupons, please try again. Error: ' + 
          getErrorMessage(err));
        console.log('Error refreshing coupons', err);
      });
  }

  showInfoPopUp(header: string, message: string) {
    this.showPopUp(header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string) {
    this.showPopUp(header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(header: string, message: string, icon: string) {
    this.confirmationService.confirm({
      key: 'general',
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
