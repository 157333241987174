import {Injectable} from '@angular/core';
 import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Website} from '../../models/website.model';
import {environment} from '../../../environments/environment';
import {WebsitesResponse} from '../../models/responses/websitesResponse.model';
import {WebsiteResponse} from '../../models/responses/websiteResponse.model';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {
  private API_URL = environment.protocol+environment.IPAddress+'/api';

  constructor(private http: HttpClient) {
  }

  getWebsites(): Observable<WebsitesResponse> {
    return this.http.get<WebsitesResponse>(`${this.API_URL}/websites/`);
  }

  getWebsite(id: string): Observable<Website> {
    return this.http.get<Website>(`${this.API_URL}/websites/${id}`);
  }

  createWebsite(OrderParams): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/websites`, OrderParams);
  }

  updateWebsite(id: string, OrderParams): Observable<SimpleResponse> {
    return this.http.put<SimpleResponse>(`${this.API_URL}/websites/${id}`, OrderParams);
  }
  
  importNewProductsForWebsite(id: string): Observable<WebsiteResponse> {
    return this.http.get<WebsiteResponse>(`${this.API_URL}/websites/${id}/importNewProducts`);
  }

  importSpecificProductsForWebsite(id: string, skus: string[]): Observable<WebsiteResponse> {
    return this.http.post<WebsiteResponse>(`${this.API_URL}/websites/${id}/importSpecificProducts`, {'skus': skus});
  }

  updateCouponsWebsite(id: string): Observable<SimpleResponse> {
    return this.http.get<SimpleResponse>(`${this.API_URL}/websites/${id}/updateCoupons`);
  }
}
