// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// IPAddress and ioAddress are the same as proxies the requests to the appropriate port internally
import {Environment} from '../app/models/environment.model';

export const environment: Environment = {
  production: false,
  IPAddress: 'vmi450258.contaboserver.net',
  ioAddress: 'vmi450258.contaboserver.net',
  webName: 'CRM RH Dev',
  navBarColour: '#CC2A5F',
  protocol: 'https://',
  stripeKeyLL: 'pk_test_51LTN6gD1XjPt1RYUzN5UfFUNh7uPe8DWjnVvQNZsVy4ufhJ1y62FaruWrk2PN5W27FjofM5siJyTkSZglyCo2v6X00fqw4pAev',
  stripeKeyCL: 'pk_test_51LTMpcA3SG7uUDhVtt4KxDegLwe69c20NNeBGuspRLA8mkBaqXsyWQgImSmfBOUif5jsjxOoItN3TXdNtCF3UgR400HB2njx2l',
  stripeKeyCK: 'pk_test_51LNDrXKhCxrkk8k7glZdkczPDAmY1b5RjJXLQ2pzqPF13NAB6QBRVxUlLotfsM0d4g1pVon7zRKESkcmreCGfaoc00U6rjobCE',
  stripeKeyTC: 'pk_test_51LQq8cCCeD0NTuVygocOUc2l2Xqhmh7EtQ4OJgxAf485HSBdZrQG9bXiTmhGDNFESND9vv5QXlx7ztncsOAgOONl001RC2pYVn',
  stripeKeyLLIE: 'pk_test_51LTNLeDxFrpV80kBquz0h7lQGfEj2y8Haon6lVq2EECsFjNZrNcOSjH3spUtY7fCcIOEXgpUuWOvbKOcfXfaXky800vwWkSI4t',
  stripeKeyLLOld: 'pk_test_PyO8DaaJjQYu7ZFOZzWxjtni',
  stripeKeyCLOld: 'pk_test_9OVstJxMBMuCF034RUkOYpT8',
  stripeKeyCKOld: 'pk_test_0WI3m9bCNhSBCstgxWlBuUtt',
  stripeKeyTCOld: 'pk_test_LRPqk5ZF2NXN0GPYPn2g0TLL',
  stripeKeyLLIEOld: 'pk_test_nNWkH2VwMR4BA6bKnMdIz70W',
  getAddressDomainToken: 'dtoken_hEDzcyiWMr0dhwkRFTVay3iui9Cl8dkXuhSMGOW4WNGBpCeX_5pV141eyhADhPZyJIDxM5pV8T-pDoFOQQRNYlTIQ6ced0ZCEZu1W4OFauhGOzvdc7dEYLWM3SmxM228eG6JxkyD733xm4k6Bj-gTLGTcDDj9mszjz8K75TNioaR4rbsmUcVBr5w5oZeRChRxqLIgqJkndqXb_9sFpxl_zKIIu0dkTKW',
  evoBaseUrl: 'https://appellosbrtest.crm4.dynamics.com/main.aspx?appid=fb9a4e2b-380c-4e5c-9c2a-05cfa2444850&forceUCI=1',
};
