import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {Order} from "../models/order.model";
import {environment} from '../../environments/environment';
import {PageCountResponse} from '../models/responses/pageCountResponse.model';
import {SavePageLayoutRequest} from '../models/requests/savePageLayoutRequest.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {UpdateFreeMonthsRequest} from '../models/requests/updateFreeMonthsRequest.model';
import {OrderUpdateResponse} from '../models/responses/orderUpdateResponse.model';
import {OrderPageResponse} from '../models/responses/orderPageResponse.model';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import {ContactAttempt} from '../models/contactAttempt.model';
import {FindOrderResponse} from '../models/responses/findOrderResponse.model';
import {OrderResponse} from '../models/responses/orderResponse.model';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {DiffHistory} from '../models/changelog/diffHistory.model';
import {PaymentIntent} from '@stripe/stripe-js';
import {CreatePaymentIntentRequest} from '../models/requests/createPaymentIntentRequest.model';
import {JontekCodeMatches} from '../models/jontekCodeMatches.model';
import {CheckJontekCodesRequest} from '../models/requests/checkJontekCodesRequest.model';
import {ExpiryDateChangeRequest} from '../models/requests/expiryDateChangeRequest.model';
import {FriendsAndFamilyStatusUpdateRequest} from '../models/requests/friendsAndFamilyStatusUpdateRequest.model';
import {ServiceUser} from '../models/serviceUser.model';
import {AlarmActivation} from '../models/alarmActivation.model';
import {OrderThirdPartyPaymentDataResponse} from '../models/responses/orderThirdPartyPaymentDataResponse.model';
import {OrderThirdPartyPaymentRequest} from '../models/requests/orderThirdPartyPaymentRequest.model';
import {ExternalId} from '../models/externalId.model';
import {CseOrder} from '../models/reporting/cseOrder.model';
import {AsAtOrder} from '../models/reporting/asAtOrder.model';
import {CseOrderUpdateRequest} from '../models/reporting/request/cseOrderUpdateRequest.model';

@Injectable({
  providedIn: 'root'
})

export class OrderService {
  private API_URL: string = environment.protocol+environment.IPAddress+'/api';

  constructor(private http: HttpClient) {
  }

  getOrderPageCount(): Observable<PageCountResponse> {
    return this.http.get<PageCountResponse>(`${this.API_URL}/orders/pagecount` );
  }

  getOrdersByPage(page: number): Observable<OrderPageResponse> {
    return this.http.get<OrderPageResponse>(`${this.API_URL}/orders/page/${page}` );
  }

  getOrder(id: string): Observable<OrderResponse> {
    return this.http.get<OrderResponse>(`${this.API_URL}/orders/${id}`);
  }

  getOrderHistories(id: string): Observable<MultiRecordResponse<DiffHistory>> {
    return this.http.get<MultiRecordResponse<DiffHistory>>(`${this.API_URL}/orders/histories/${id}`);
  }

  getOrderTdCode(tdCode: string, webSiteId: string): Observable<OrderResponse> {
    return this.http.get<OrderResponse>(`${this.API_URL}/orders/tdCode/${tdCode}/webSite/${webSiteId}`);
  }

  createOrderWebsites(websiteId: string, OrderParams: any): Observable<SingleRecordResponse<any>> {
    return this.http.post<SingleRecordResponse<any>>(`${this.API_URL}/orders/create/${websiteId}`, OrderParams);
  }
  
  checkWooOrderId(websiteId: string, orderId: number): Observable<SingleRecordResponse<string>> {
    return this.http.get<SingleRecordResponse<string>>(`${this.API_URL}/orders/checkWooOrderId/${websiteId}/${orderId}`);
  }

  addOrderCRM(websiteId: string, OrderParams): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/webhooks/${websiteId}/order`, OrderParams);
  }

  findOrder(OrderParams: any): Observable<FindOrderResponse> {
    return this.http.post<FindOrderResponse>(`${this.API_URL}/orders/find`, OrderParams);
  }

  createOrder(OrderParams): Observable<OrderResponse> {
    return this.http.post<OrderResponse>(`${this.API_URL}/orders`, OrderParams);
  }

  createOrderPayment(OrderParams): Observable<Order> {
    return this.http.post<Order>(`${this.API_URL}/orders/payment`, OrderParams);
  }

  createOrderPaymentIntent(createPiRequest: CreatePaymentIntentRequest): Observable<SingleRecordResponse<PaymentIntent>> {
    return this.http.post<SingleRecordResponse<PaymentIntent>>(`${this.API_URL}/orders/paymentIntent`, createPiRequest);
  }

  getPaymentIntent(stripeAccount: string, paymentIntentId: string): Observable<SingleRecordResponse<PaymentIntent>> {
    return this.http.get<SingleRecordResponse<PaymentIntent>>(`${this.API_URL}/orders/paymentIntent/${stripeAccount}/${paymentIntentId}`);
  }

  updateOrder(id: string, OrderParams): Observable<OrderResponse> {
    return this.http.put<OrderResponse>(`${this.API_URL}/orders/${id}`, OrderParams);
  }

  updateOutstandingAction(data): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/orders/update/outstandingaction`, data);
  }

  updateThumbsUpOrDown(data): Observable<SingleRecordResponse<ContactAttempt>> {
    return this.http.put<SingleRecordResponse<ContactAttempt>>(`${this.API_URL}/orders/update/thumbsup-thumbsdown`, data);
  }

  updateOrderExpiryDate(params: ExpiryDateChangeRequest): Observable<OrderUpdateResponse> {
    return this.http.put<OrderUpdateResponse>(`${this.API_URL}/orders/update/order-expiry-date`, params);
  }

  // TODO remove for hardware version
  updateOrderRenewalPrice(params): Observable<OrderUpdateResponse> {
    return this.http.put<OrderUpdateResponse>(`${this.API_URL}/orders/update/order-renewal-price`, params);
  }
  
  updateOrderFrozenPrice(params): Observable<OrderUpdateResponse> {
    return this.http.put<OrderUpdateResponse>(`${this.API_URL}/orders/update/order-frozen-price`, params);
  }

  savePageLayout(params: SavePageLayoutRequest): Observable<SimpleResponse> {
    return this.http.put<SimpleResponse>(`${this.API_URL}/accounts/orderPageSections`, params);
  }

  updateFreeMonths(params: UpdateFreeMonthsRequest): Observable<OrderUpdateResponse> {
    return this.http.put<OrderUpdateResponse>(`${this.API_URL}/orders/update/free-months`, params);
  }

  sendAutoNotificationOnDeleteOrder(params): Observable<SingleRecordResponse<any>> {
    return this.http.post<SingleRecordResponse<any>>(`${this.API_URL}/notifications/delete-order/auto-notification`,params);
  }

  sendPlanTypeChangeAutoNotification(params) {
    return this.http.post(`${this.API_URL}/notifications/change-plan/auto-notification`,params)
  }

  checkJontekCodes(params: CheckJontekCodesRequest): Observable<MultiRecordResponse<JontekCodeMatches>> {
    return this.http.post<MultiRecordResponse<JontekCodeMatches>>(`${this.API_URL}/orders/checkJontekCodes`, params);
  }

  getNextNumber(sequenceName: string): Observable<SingleRecordResponse<number>> {
    return this.http.get<SingleRecordResponse<number>>(`${this.API_URL}/sequence/${sequenceName}`);
  }

  getServiceUsers(orderId: string): Observable<MultiRecordResponse<ServiceUser>> {
    return this.http.get<MultiRecordResponse<ServiceUser>>(`${this.API_URL}/accountServices/users/forOrder/${orderId}`);
  }

  activateFriendsAndFamily(params: FriendsAndFamilyStatusUpdateRequest): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/accountServices/activateFriendsAndFamily`, params);
  }

  deactivateFriendsAndFamily(params: FriendsAndFamilyStatusUpdateRequest): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/accountServices/deactivateFriendsAndFamily`, params);
  }

  addServiceUser(serviceUser: ServiceUser, serviceActive: boolean): Observable<SingleRecordResponse<ServiceUser>> {
    return this.http.post<SingleRecordResponse<ServiceUser>>(`${this.API_URL}/accountServices/users`, {
      'serviceUser': serviceUser,
      'serviceActive': serviceActive,
    });
  }

  deleteServiceUser(serviceUserId: string): Observable<SingleRecordResponse<ServiceUser>> {
    return this.http.delete<SingleRecordResponse<ServiceUser>>(`${this.API_URL}/accountServices/users/${serviceUserId}`);
  }

  getAlarmActivationsForOrder(orderId: string): Observable<MultiRecordResponse<AlarmActivation>> {
    return this.http.get<MultiRecordResponse<AlarmActivation>>(`${this.API_URL}/alarmActivations/forOrder/${orderId}`);
  }

  getAlarmActivationsForOrderAndType(orderId: string, activationType: string): Observable<MultiRecordResponse<AlarmActivation>> {
    return this.http.get<MultiRecordResponse<AlarmActivation>>(`${this.API_URL}/alarmActivations/forOrderAndType/${orderId}/${activationType}`);
  }

  getOrderThirdPartyPaymentData(ottpRequest: OrderThirdPartyPaymentRequest): Observable<OrderThirdPartyPaymentDataResponse> {
    return this.http.post<OrderThirdPartyPaymentDataResponse>(`${this.API_URL}/thirdPartyPayments/forOrder`, ottpRequest);
  }

  getExternalIdsForOrder(orderId: string): Observable<MultiRecordResponse<ExternalId>> {
    return this.http.get<MultiRecordResponse<ExternalId>>(`${this.API_URL}/externalIds/forOrder/${orderId}`);
  }

  getExternalIdForOrderAndExactName(orderId: string, idName: string): Observable<SingleRecordResponse<ExternalId>> {
    return this.http.get<SingleRecordResponse<ExternalId>>(`${this.API_URL}/externalIds/forOrderAndExactName/${orderId}/${idName}`);
  }

  getExternalIdForOrderAndNameStart(orderId: string, idName: string): Observable<MultiRecordResponse<ExternalId>> {
    return this.http.get<MultiRecordResponse<ExternalId>>(`${this.API_URL}/externalIds/forOrderAndNameStart/${orderId}/${idName}`);
  }

  createRecordOfCseOrder(cseOrderParams: {'cseOrder': CseOrder}): Observable<SingleRecordResponse<CseOrder>> {
    return this.http.post<SingleRecordResponse<CseOrder>>(`${this.API_URL}/cse-orders/record/`, cseOrderParams);
  }

  updateCseOrderRecord(cseOrderUpdateParams: {'cseOrderUpdate': CseOrderUpdateRequest}): Observable<SimpleResponse> {
    return this.http.put<SimpleResponse>(`${this.API_URL}/cse-orders/updateCseOrderRecord/`, cseOrderUpdateParams);
  }

  getCseOrderReportPageCount(startDate: string, endDate: string): Observable<PageCountResponse> {
    return this.http.get<PageCountResponse>(`${this.API_URL}/cse-orders/pagecount/${startDate}/${endDate}`);
  }

  getCseOrderReportPage(startDate: string, endDate: string, page: number): Observable<MultiRecordResponse<CseOrder>> {
    return this.http.get<MultiRecordResponse<CseOrder>>(`${this.API_URL}/cse-orders/page/${startDate}/${endDate}/${page}`);
  }

  getAsAtOrderReportPageCount(forDate: string, includeDeleted: string): Observable<PageCountResponse> {
    return this.http.get<PageCountResponse>(`${this.API_URL}/as-at-orders/pagecount/${forDate}/${includeDeleted}`);
  }

  getAsAtOrderReportPage(forDate: string, includeDeleted: string, page: number): Observable<MultiRecordResponse<AsAtOrder>> {
    return this.http.get<MultiRecordResponse<AsAtOrder>>(`${this.API_URL}/as-at-orders/page/${forDate}/${includeDeleted}/${page}`);
  }
}


