const CRM_CLIENT_VERSION: string = '2024-07-10';

interface Environment {
  'production': boolean;
  'IPAddress': string;
  'ioAddress': string;
  'webName': string;
  'navBarColour': string;
  'protocol': string;
  'stripeKeyLL': string;
  'stripeKeyCL': string;
  'stripeKeyCK': string;
  'stripeKeyTC': string;
  'stripeKeyLLIE': string;
  'stripeKeyLLOld': string;
  'stripeKeyCLOld': string;
  'stripeKeyCKOld': string;
  'stripeKeyTCOld': string;
  'stripeKeyLLIEOld': string;
  'getAddressDomainToken': string;
  'evoBaseUrl': string;
}

export {
  Environment,
  CRM_CLIENT_VERSION,
};
