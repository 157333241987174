import {Component, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {MessageService, SelectItem, SortEvent} from 'primeng/api';
import {BrandCfg, getBrandConfigs, getBrandSelectItems} from '../../lookups/brands';
import {Column} from '../../models/column.model';
import {HardwareAuditRecord} from '../../models/hardwareAuditRecord.model';
import {HardwareService} from '../hardware.service';
import moment from 'moment-timezone';
import {PageCountResponse} from '../../models/responses/pageCountResponse.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {Table} from 'primeng/table';

const MAX_PARALLEL: number = 5;
const PAGE_RETRIES: number = 3;

@Component({
  selector: 'app-hardware-audit',
  templateUrl: './hardware-audit.component.html',
  styleUrls: ['./hardware-audit.component.scss'],
  providers: [MessageService],
})
export class HardwareAuditComponent implements OnInit {
  hardwareAuditRecords: HardwareAuditRecord[];
  cols: Column[];
  minDateStr: string;
  maxDateStr: string;
  defaultStartDateStr: string;
  recordsLoading: boolean = false;
  recordPagesToLoad: number = 0;
  filterMinDate: Date;
  filterMaxDate: Date;
  filterYearRange: string;
  dateFilters: Date[];
  brands: SelectItem<string>[];
  brandConfigs: BrandCfg;
  selectedBrand: string[];
  @ViewChild('auditTable', {static: false})
  auditTable: Table;
  pagesProcessing: number;

  constructor(
    private hardwareService: HardwareService,
    private messageService: MessageService,
    private title: Title,
  ) { }

  ngOnInit() {
    this.brandConfigs = getBrandConfigs();
    this.brands = getBrandSelectItems();
    this.title.setTitle('CRM Hardware Audit');
    this.hardwareAuditRecords = [];

    this.cols = [
      {field: 'createdAt', header: 'Date'},
      {field: 'websiteId', header: 'Brand'},
      {field: 'hardwareOrSet', header: 'Title'},
      {field: 'fieldChanged', header: 'Field Changed'},
      {field: 'changedFrom', header: 'Before'},
      {field: 'changedTo', header: 'After'},
      {field: 'changedBy', header: 'Staff member'},
    ];

    this.minDateStr = moment.tz('Europe/London').subtract(12, 'months').format('YYYY-MM-DD');
    this.defaultStartDateStr = moment.tz('Europe/London').subtract(1, 'months').format('YYYY-MM-DD');
    this.maxDateStr = moment.tz('Europe/London').format('YYYY-MM-DD');
  }

  loadAuditRecords(startDate: string, endDate: string) {
    this.recordsLoading = true;
    this.hardwareAuditRecords = [];
    this.recordPagesToLoad = 0;
    this.filterMinDate = new Date(startDate);
    this.filterMaxDate = new Date(endDate);
    this.filterYearRange = `${this.filterMinDate.getFullYear()}:${this.filterMaxDate.getFullYear()}`;
    this.hardwareService.getHardwareAuditPageCount(startDate, endDate).subscribe(
      async (response: PageCountResponse) => {
        if (!response.success) {
          this.recordsLoading = false;
          this.showPageCountError(response.message);
        } else {
          this.pagesProcessing = 0;
          this.recordPagesToLoad = response.pageCount!;
          if (this.recordPagesToLoad === 0) {
            this.recordsLoading = false;
          }
          for (let page: number = 1; page <= response.pageCount!; page++) {
            // Limit the max number of pages being updated in parallel which allows other work to complete
            // whilst pages are still loading and allows abort if page navigated away from
            while (this.pagesProcessing >= MAX_PARALLEL) {
              await this.delay(250);
            }
            this.pagesProcessing++;
            this.loadPage(startDate, endDate, page, PAGE_RETRIES);
          }
        }
      }
    );
  }

  loadPage(startDate: string, endDate: string, page: number, retryCount: number) {
    this.hardwareService
      .getHardwareAuditPage(startDate, endDate, page)
      .subscribe((response: MultiRecordResponse<HardwareAuditRecord>) => {
        if (!response.success || !response.data) {
          console.log(`Error loading page ${page}. Error: ${response.message}`);
          if (retryCount > 0) {
            this.loadPage(startDate, endDate, page, retryCount - 1);
            return;
          }
          // Only decrement count if we are not retrying page
          this.recordPagesToLoad--;
          this.pagesProcessing--;
          return;
        }
        this.hardwareAuditRecords = this.hardwareAuditRecords.concat(response.data);
        this.recordPagesToLoad--;
        this.pagesProcessing--;
        if (this.recordPagesToLoad === 0) {
          this.recordsLoading = false;
        }
      }, 
      (err: any) => {
        console.log(`Error loading page ${page}. Error ${err.message}`);
        if (retryCount > 0) {
          this.loadPage(startDate, endDate, page, retryCount - 1);
          return;
        }
        // Only decrement count if we are not retrying page
        this.recordPagesToLoad--;
        this.pagesProcessing--;
      });
  }

  delay(ms: number): Promise<void> {
    return new Promise((resolve): void => {
      setTimeout(resolve, ms);
    });
  }

  showPageCountError(errorMessage: string) {
    this.messageService.add({
      severity: 'error',
      life: 300000,
      summary: 'Something went wrong!',
      detail: 'Error getting page count' + errorMessage,
    });
  }

  applyFilter($event: Event, field: string, filterType: string): void {
    this.auditTable.filter(($event.target as HTMLInputElement).value, field, filterType);
  }

  customSort(event: SortEvent) {
    event.data.sort((row1: HardwareAuditRecord, row2: HardwareAuditRecord): number => {
      let result: number = 0;
      let value1 = row1[event.field];
      let value2 = row2[event.field];
      if (event.field === 'websiteId') {
        value1 = row1.websiteId?.title;
        value2 = row2.websiteId?.title;
      }
      if (event.field === 'hardwareOrSet') {
        value1 = row1.hardwareOrSet?.title;
        value2 = row2.hardwareOrSet?.title;
      }
      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
      } else {
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return result * event.order;
    });
  }
}
