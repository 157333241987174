import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ConfirmationService} from 'primeng/api';
import {Column} from '../../models/column.model';
import {Announcement} from '../../models/notifications/announcements';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {AnnouncementService} from './announcement.service';

@Component({
  selector: 'app-maintain-announcement',
  templateUrl: './maintain-announcement.component.html',
  styleUrls: ['./maintain-announcement.component.scss'],
  providers: [ConfirmationService]
})
export class MaintainAnnouncementComponent implements OnInit {
  username: string;
  cols: Column[];
  announcements: Announcement[] = [];
  newMsg: string;

  constructor(
    private announcementService: AnnouncementService,
    private confirmationService: ConfirmationService,
    private title: Title,
  ) { }

  ngOnInit(): void {
    this.title.setTitle('CRM Announcements');
    this.username = localStorage.getItem('userName');
    this.cols = [
      {field: 'createdAt', header: 'Created'},
      {field: 'createdBy', header: 'Created By'},
      {field: 'message', header: 'Message'},
      {field: 'removedBy', header: 'Removed By'},
      {field: 'removedAt', header: 'Removed At'},
    ];
    this.announcementService.getRecentAnnouncements().subscribe((response: MultiRecordResponse<Announcement>) => {
      if (response.success) {
        this.announcements = response.data;
      } else {
        this.showErrorPopUp('Error loading recent announcements',
          response.message || 'Something went wrong loading recent announcements please try again.');
      }
    });
  }

  updateAnnouncement(): void {
    const currentDate: Date = new Date();
    this.announcementService.createNewAnnouncement({
      'message': this.newMsg,
      'createdBy': this.username,
      'createdAt': currentDate,
    }).subscribe((response: SingleRecordResponse<Announcement>) => {
      if (response.success) {
        this.announcements.forEach((announcement: Announcement) => {
          // Update will have removed current unremoved announcement
          if (!announcement.removedBy) {
            announcement.removedBy = this.username;
            announcement.removedAt = currentDate;
          }
        });
        if (response.data.message) {
          this.announcements = [response.data, ...this.announcements];
        }
      } else {
        this.showErrorPopUp('Error loading recent announcements',
          response.message || 'Something went wrong loading recent announcements please try again.');
      }
    })
  }

  showInfoPopUp(header: string, message: string) {
    this.showPopUp('general', header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string) {
    this.showPopUp('general', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string) {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }

}
