import {BusinessGrowthCustomersComponent} from './business-growth-customers/business-growth-customers.component';
import {CancellationLinkComponent} from './setup/cancellation-link/cancellation-link.component';
import {ActionsComponent} from './setup/actions/actions.component';
import {AddLeadComponent} from './leads/add-lead/add-lead.component';
import {LeadsComponent} from './leads/leads.component';
import {MaintainRolesComponent} from './setup/maintain-roles/maintain-roles.component';
import {TooltipsComponent} from './setup/tooltips/tooltips.component';
import {DisplayFaultyEquipmentsComponent} from './post-order/faulty-equipments/display-faulty-equipments/display-faulty-equipments.component';
import {MessagesListComponent} from './messages-list/messages-list.component';
import {HomeOutboundComponent} from './home-outbound/home-outbound.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RegistrationComponent} from './registration/registration.component';
import {LoginComponent} from './login/login.component';
import {ProfileComponent} from './profile/profile.component';
import {SettingsComponent} from './settings/settings.component';
import {WebsitesComponent} from './setup/websites/websites.component';
import {PostOrderComponent} from './post-order/post-order.component';
import {AuthGuardFn} from './auth-guard.service';
import {MyOrdersComponent} from './my-orders/my-orders.component';
import {ProductsComponent} from './setup/products/products.component';
import {TagsComponent} from './setup/tags/tags.component';
import {UsersComponent} from './setup/users/users.component';
import {PermissionComponent} from './permission/permission.component';
import {FeedbackComponent} from './feedback/feedback.component';
import {LogsComponent} from './reporting/logs/logs.component';
import {ImportLogComponent} from './reporting/import-log/import-log.component';
import {CseOrderComponent} from './cse-order/cse-order.component';
import {CouponsComponent} from './setup/coupons/coupons.component';
import {OutstandingActionsListComponent} from './home-outbound/outstanding-actions-list/outstanding-actions-list.component';
import {HardwareComponent} from './setup/hardware/hardware.component';
import {HardwareSetComponent} from './setup/hardware-set/hardware-set.component';
import {PostSheetsComponent} from './post-sheets/post-sheets.component';
import {RenewalLinkComponent} from './setup/renewal-link/renewal-link.component';
import {MailingLabelsComponent} from './post-sheets/mailing-labels/mailing-labels.component';
import {PostSheetItemReportComponent} from './post-sheets/post-sheet-item-report/post-sheet-item-report.component';
import {PostSheetInfopackReportComponent} from './post-sheets/post-sheet-infopack-report/post-sheet-infopack-report.component';
import {PostOrderDeactivateGuardFn} from './post-order/post-order-deactivate.guard';
import {HardwareAuditComponent} from './setup/hardware-audit/hardware-audit.component';
import {MaintainAnnouncementComponent} from './setup/maintain-announcement/maintain-announcement.component';
import {CustomerFeedbackComponent} from './customer-feedback/customer-feedback.component';
import {EquipmentManualsComponent} from './setup/equipment-manuals/equipment-manuals.component';
import {AccountServicesComponent} from './setup/account-services/account-services.component';
import {FriendsAndFamilyContentComponent} from './setup/friends-and-family-content/friends-and-family-content.component';
import {GenesysComponent} from './genesys/genesys.component';
import {CseOrderReportComponent} from './reporting/cse-order-report/cse-order-report.component';
import {OrderAsAtComponent} from './reporting/order-as-at/order-as-at.component';

const routes: Routes = [
  {
     path: '',
     component: MyOrdersComponent,
     canActivate: [AuthGuardFn],
     data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'credit-control',
    redirectTo: '/boards/Customer Accounts Management',
    pathMatch: 'full',
  },
  {
    path: 'boards/:boardName',
    component: HomeOutboundComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'actions-listing/:actionType/:shift',
    component: OutstandingActionsListComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'coupons',
    component: CouponsComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'coupons/:id',
    component: CouponsComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'websites',
    component: WebsitesComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Maintain Websites'},
  },
  {
    path: 'websites/:id',
    component: WebsitesComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Maintain Websites'},
  },
  {
    path: 'messages',
    component: MessagesListComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'tags',
    component: TagsComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Maintain Tags'},
  },
  {
    path: 'register',
    component: RegistrationComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'profile/settings',
    component: SettingsComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'permission',
    component: PermissionComponent,
    canActivate: [AuthGuardFn],
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Maintain Products'},
  },
  {
    path: 'order/:id',
    component: PostOrderComponent,
    canActivate: [AuthGuardFn],
    canDeactivate: [PostOrderDeactivateGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'order/:id/feedback/:feedbackId',
    component: PostOrderComponent,
    canActivate: [AuthGuardFn],
    canDeactivate: [PostOrderDeactivateGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Maintain Users'},
  },
  {
    path: 'cses',
    component: CseOrderComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'logs',
    component: LogsComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'View Logs'},
  },
  {
    path: 'importlogs',
    component: ImportLogComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'View Logs'},
  },
  {
    path: 'faulty-equipments',
    component: DisplayFaultyEquipmentsComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'tooltips',
    component: TooltipsComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'roles',
    component: MaintainRolesComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Maintain Roles'},
  },
  {
    path: 'hardware',
    component: HardwareComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Maintain Hardware'},
  },
  {
    path: 'hardware-sets',
    component: HardwareSetComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Maintain Hardware'},
  },
  {
    path: 'hardware-audit',
    component: HardwareAuditComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'View Logs'},
  },
  {
    path: 'account-services',
    component: AccountServicesComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Maintain Hardware'},
  },
  {
    path: 'leads',
    component: LeadsComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'leads/view/:id',
    component: LeadsComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'leads/edit/:id',
    component: LeadsComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'leads/call/:id',
    component: LeadsComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'add-lead',
    component: AddLeadComponent,
    canActivate:[AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'actions',
    component: ActionsComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Maintain Actions'},
  },
  {
    path: 'business-growth-customers',
    component: BusinessGrowthCustomersComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Business Growth'}
  },
  {
    path: 'renewal-links',
    component: RenewalLinkComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Maintain Renewal Links'}
  },
  {
    path: 'post-sheets',
    component: PostSheetsComponent,
    canActivate:[AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
    children: [{
      path: 'mailing-labels',
      outlet: 'print',
      component: MailingLabelsComponent,
    }]
  },
  {
    path: 'post-sheets-item-report',
    component: PostSheetItemReportComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'post-sheets-infopack-report',
    component: PostSheetInfopackReportComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'cancellation-links',
    component: CancellationLinkComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Maintain Cancellation Links'}
  },
  {
    path: 'announcements',
    component: MaintainAnnouncementComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Maintain Announcements'}
  },
  {
    path: 'customer-feedback',
    component: CustomerFeedbackComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'}
  },
  {
    path: 'customer-feedback/:id',
    component: CustomerFeedbackComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'}
  },
  {
    path: 'manuals',
    component: EquipmentManualsComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Maintain Manuals'},
  },
  {
    path: 'friends-and-family-content',
    component: FriendsAndFamilyContentComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Marketing Maintenance'},
  },
  {
    path: 'genesys/:brand/:phoneNo',
    component: GenesysComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'}
  },
  {
    path: 'cse-order-report',
    component: CseOrderReportComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'Core CRM Access'},
  },
  {
    path: 'as-at-order-report',
    component: OrderAsAtComponent,
    canActivate: [AuthGuardFn],
    data: {'permission': 'View Logs'},
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
